import { AgentHierarchy } from "../models/AgentHierarchy";
import { userCsvTemplate } from "../models/UserCsvTemplate";

import {
  StatusIndicator,
  STATUS,
} from "@amzn/stencil-react-components/status-indicator";

export function csvToArray(str: any, delimiter = ","): any {
  const headers = str.slice(0, str.indexOf("\r")).split(delimiter);
  const rows = str.slice(str.indexOf("\r") + 1).split("\r");

  const arr = rows.map(function (row: any) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object: any, header: any, index: any) {
      object[header] = values[index];
      return object;
    }, {});
    return el;
  });
  return arr;
}

export function downloadTemplate() {
  console.log("Downloading template");
  const file = new Blob([userCsvTemplate], { type: "csv" });
  let a = document.createElement("a");
  let url = URL.createObjectURL(file);
  a.href = url;
  a.download = "Connect_New_Access_Template.csv";
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}

export function getAuditTrailUserData(data: any) {
  return (
    <>
      <p>
        Name: {data.IdentityInfo.FirstName}&nbsp;{data.IdentityInfo.LastName}
      </p>
      <p>Username: {data.IdentityInfo.Email}</p>
      <p>Security Profiles: {data.SecurityProfileIds}</p>
      <p>Routing Profile: {data.RoutingProfileId}</p>
      <p>Agent Hierarchy: {data.HierarchyGroupId}</p>
      <p>Auto-accept: false</p>
      <p>ACW: 300</p>
    </>
  );
}

export function getStatusIndicator(status: any) {
  if (status.value === "valid") {
    return (
      <StatusIndicator
        statusIcon={STATUS.POSITIVE}
        statusIconAltText="Valid"
        statusLabel={status.message}
      />
    );
  } else if (status.value === "invalid") {
    return (
      <StatusIndicator
        statusIcon={STATUS.WARNING}
        statusIconAltText="Invalid"
        statusLabel={status.message}
      />
    );
  } else if (status.value === "creating") {
    return (
      <StatusIndicator
        statusIcon={STATUS.WAITING}
        statusIconAltText="Creating user"
        statusLabel={status.message}
      />
    );
  } else if (status.value === "done") {
    return (
      <StatusIndicator
        statusIcon={STATUS.POSITIVE}
        statusIconAltText="Done"
        statusLabel={status.message}
      />
    );
  } else if (status.value === "error") {
    return (
      <StatusIndicator
        statusIcon={STATUS.NEGATIVE}
        statusIconAltText="Error"
        statusLabel={status.message}
      />
    );
  } else {
    return <></>;
  }
}

export function isTableDataValid(data: any) {
  let isTableDataValid = true;
  if (data) {
    data.forEach((item: any) => {
      if (item["status"].value === "invalid") {
        isTableDataValid = false;
      }
    });
  } else {
    isTableDataValid = false;
  }

  return isTableDataValid;
}

export function setHierarchyPath(hierarchy: AgentHierarchy) {
  let path = "";

  if (hierarchy.Level1) {
    path += `${hierarchy.Level1}`;
  }
  if (hierarchy.Level2) {
    path += `/${hierarchy.Level2}`;
  }
  if (hierarchy.Level3) {
    path += `/${hierarchy.Level3}`;
  }
  if (hierarchy.Level4) {
    path += `/${hierarchy.Level4}`;
  }
  if (hierarchy.Level5) {
    path += `/${hierarchy.Level5}`;
  }

  return path;
}

export function validateFileData(data: any, securityProfiles: any) {
  const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  let validatedData = [];

  validatedData = data.map((item: any) => {
    item["status"] = { value: "valid", message: "Valid" };

    if (!item["first_name"])
      item["status"] = { value: "invalid", message: "First Name missing" };
    if (item["first_name"] === "first_name")
      item["status"] = { value: "invalid", message: "First Name missing" };
    if (!item["last_name"])
      item["status"] = { value: "invalid", message: "Last Name missing" };
    if (!item["user_login"])
      item["status"] = { value: "invalid", message: "User Login missing" };
    if (item["user_login"] && !item["user_login"].match(emailPattern))
      item["status"] = {
        value: "invalid",
        message: "User Login not email format",
      };
    if (!item["routing_profile_name"])
      item["status"] = { value: "invalid", message: "Routing Profile missing" };
    if (!item["security_profile_names"])
      item["status"] = {
        value: "invalid",
        message: "Security Profile missing",
      };
    if (!item["softphone_auto_accept"])
      item["status"] = { value: "invalid", message: "Auto-accept missing" };
    if (!item["acw_timeout"])
      item["status"] = { value: "invalid", message: "ACW Timeout missing" };

    if (item["security_profile_names"]) {
      let splitProfiles = item["security_profile_names"].split("|");
      splitProfiles.forEach((profile: string) => {
        if (!securityProfiles.includes(profile)) {
          item["status"] = {
            value: "invalid",
            message: `Not authorized to use ${profile} profile`,
          };
        }
      });
    }
    return item;
  });

  return validatedData;
}
