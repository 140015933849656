import React from 'react';
import { Dispatch } from 'redux';
import { connect, MapDispatchToProps } from 'react-redux';
import { saveUsername as saveUsernameAction } from './components/ForReference/user/UserActions';
import './App.css';

import { IUser } from './components/ForReference/user/UserTypes';
import { IAppState } from './components/ForReference/RootReducer';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { HR2021Theme } from '@amzn/stencil-react-theme-default';
import { Col, View } from '@amzn/stencil-react-components/layout';
import { Avatar } from '@amzn/stencil-react-components/avatar';
import { PageProps } from './models/FederateData';
import AuthService from './services/AuthService';
import CustomHeader from './components/CustomHeader';
import CustomFooter from './components/CustomFooter';
import LandingContent from './components/LandingContent';
import SignedOut from './pages/SignedOut';
import { MainWithSkipLink } from '@amzn/stencil-react-components/a11y';


interface IAppOwnProps {
    username: string;
    userType: 'admin' | 'moderator' | 'user' | 'guest';
}

interface IAppDispatchToProps {
    saveUsername: (user: IUser) => void;
}

const authService: AuthService = new AuthService();

export default class AppUnconnected extends React.Component<PageProps & IAppDispatchToProps & IAppOwnProps> {

    render() {
        if (!authService.isUserAuthenticated() && this.props.federateToken === null)
            return <SignedOut allowance={true} />;

        return <div>
            <StencilProvider theme={{
                ...HR2021Theme,
                constants: {
                    ...HR2021Theme.constants,
                    pageHorizontalPadding: '10vw'
                }
            }}>
                <View height={150}>
                    <CustomHeader federateToken={this.props.federateToken} />
                    <Col margin={{ left: 155 }}>
                        {
                            authService.isTokenPresent() &&
                            <div className='link-underline'>
                              <Avatar
                                imageAltText='badge image'
                                fullName={this.props.federateToken.FAMILY_NAME + ', ' + this.props.federateToken.GIVEN_NAME}
                                username={this.props.federateToken.sub}
                                usernameClickable={true}
                                margin={25}>
                              </Avatar>
                            </div>
                        }
                    </Col>
                </View>
                <MainWithSkipLink>
                    <div>
                        <LandingContent federateToken={this.props.federateToken} />
                        <br /><br />
                    </div>
                </MainWithSkipLink>
                <CustomFooter />
            </StencilProvider>
        </div>;
    }
}

const mapDispatchToProps: MapDispatchToProps<IAppDispatchToProps,
  IAppOwnProps> = (dispatch: Dispatch, ownProps: IAppOwnProps): IAppDispatchToProps => ({
    saveUsername: (user: IUser) => {
        dispatch(saveUsernameAction(user));
    }
});

export const App = connect<{},
  IAppDispatchToProps,
  IAppOwnProps,
  IAppState>(null, mapDispatchToProps)(AppUnconnected);
