export function isAddEnabled(data: any): boolean {
  let returnValueFlag: boolean = false;
  Object.values(data).forEach((value) => {
    if ((value as [string]).includes("ADD")) returnValueFlag = true;
    if (returnValueFlag) return returnValueFlag;
  });
  return returnValueFlag;
}

export function isDeleteEnabled(data: any): boolean {
  let returnValueFlag: boolean = false;
  Object.values(data).forEach((value) => {
    if ((value as [string]).includes("DELETE")) returnValueFlag = true;
    if (returnValueFlag) return returnValueFlag;
  });
  return returnValueFlag;
}

export function isDeleteEnabledForBG(
  data: any,
  businessGroupToBeChecked: string
): boolean {
  for (const businessGroup in data)
    if (
      data.hasOwnProperty(businessGroup) &&
      businessGroup === businessGroupToBeChecked &&
      data[businessGroup].includes("DELETE")
    )
      return true;
  return false;
}

export function getBusinessGroups(data: any) {
  return Object.getOwnPropertyNames(data);
}

export function getBusinessGroupsForAdd(data: any) {
  let businessGroupArray: any[] = [];
  Object.entries(data).forEach((entry: [string, unknown]) => {
    if ((entry[1] as [string]).includes("ADD"))
      businessGroupArray.push(entry[0] as string);
  });

  return businessGroupArray;
}

export function getCountry(businessGroupToBeChecked: string): string {
  let mapOfCountriesAndCountryCode = new Map();

  /**
   * Refer this website to get the country codes:
   * https://github.com/bl00mber/react-phone-input-2/blob/master/src/rawCountries.js
   */

  mapOfCountriesAndCountryCode.set("US", "us");
  mapOfCountriesAndCountryCode.set("UK", "gb");
  mapOfCountriesAndCountryCode.set("CAN", "ca");

  /**
   * Alternate code:
   * businessGroupToBeChecked.split('-').pop();
   */

  const country = businessGroupToBeChecked.split("-")[1] as string;
  return country && mapOfCountriesAndCountryCode.has(country)
    ? mapOfCountriesAndCountryCode.get(country)
    : "us";
}

export function checkValidCase(
  businessGroupToBeChecked: string,
  caseNumber: string
) {
  /**
   * ERC and CRC are numbers only, limit of 8.
   * GWA-UK case numbers start with E and then 9 numbers, so 10 total, like this 'E123456789'.
   * SS-US, EOB-US, GWA-US, and GWA-CAN are numbers only with no limit.
   */

  if (businessGroupToBeChecked === "GWA-UK") {
    if (!/^([DEV])([0-9]{8,10})$/.test(caseNumber)) {
      return {
        cause:
          "GWA-UK case numbers start with D,E, or V followed by 8-10 digits, for example 'D12345678' or 'V1234567890'.",
        flag: true,
      };
    }
  } else if (
    businessGroupToBeChecked === "CRC" ||
    businessGroupToBeChecked === "ERC"
  ) {
    if (!/^([0-9]{8})$/.test(caseNumber)) {
      return {
        cause:
          "For " +
          businessGroupToBeChecked +
          ", the valid case number has 8 digits only.",
        flag: true,
      };
    }
  } else if (!/^([0-9]{1,100})$/.test(caseNumber)) {
    return {
      cause:
        "For " +
        businessGroupToBeChecked +
        ", case number are digits only, less than 100 digits.",
      flag: true,
    };
  }
  return {
    cause: true,
    flag: false,
  };
}

export function isBlank(value: string | undefined): boolean {
  return value === "";
}

export function convertToTimestamp(date: string | undefined): string {
  if (date === "") return "";
  return (new Date(date as string).valueOf() / 1000) as unknown as string;
}
