import { defaultCardRenderer } from '@amzn/stencil-react-components/table';
import { ColumnWithMono } from './SelfServiceTableData';

export interface SMSSelfServiceState {
    fetchedDataForDropdown: any,
    fetchedDataForTableRows: any,
    filteredValue: string,
    isOpen: boolean,
    loading: boolean,
    isPostError: any,
    isPostSuccess: boolean,
    readyToRedirect: boolean,
    selectedPage: number,
    phone: any,
    isValidCaseNumber: boolean
}

export interface STSSTableData {
    BusinessGroup: string,
    Message: string,
    OriginationNumber: string,
    TemplateName: string,
    CaseNumber: string
}

export const getColumnsSTSS = [
    {
        header: 'Template Name',
        accessor: 'TemplateName',
        width: '25%'
    },
    {
        header: 'Message',
        accessor: 'Message',
        cellComponent: ColumnWithMono,
        width: '75%'
    }
];


export const renderCardForRowSTSS = defaultCardRenderer({ columns: getColumnsSTSS, useCellComponents: true });
