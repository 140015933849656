import React from 'react';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { H1, H2, H3, Text } from '@amzn/stencil-react-components/text';
import { PageContainer } from '@amzn/stencil-react-components/page';
import { Col, Row, View } from '@amzn/stencil-react-components/layout';
import { Table, TableSpacing } from '@amzn/stencil-react-components/table';
import { Avatar } from '@amzn/stencil-react-components/avatar';
import { FormWrapper, InputHeader, InputWrapper, Select, TextArea } from '@amzn/stencil-react-components/form';
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal';
import { FederateData, PageProps } from '../models/FederateData';
import { HR2021Theme } from '@amzn/stencil-react-theme-default';
import AuthService from '../services/AuthService';
import CustomFooter from '../components/CustomFooter';
import CustomBackToTop from '../components/CustomBackToTop';
import CustomHeader from '../components/CustomHeader';
import { getCredentials } from '../services/aws.service';
import { AwsClient } from 'aws4fetch';
import { AppConfig } from '../models/AppConfig';
import errorHandling from '../components/ErrorHandler';
import { Spinner } from '@amzn/stencil-react-components/spinner';
import CustomError from '../models/CustomError';
import { IconCross, IconSize } from '@amzn/stencil-react-components/icons';
import SignedOut from './SignedOut';
import Forbidden from './Forbidden';
import { cssPropertiesTable } from '../models/TableCustomCSS';
import { modalInstructionsSTSS, tooltipInstructionsSTSS } from '../models/Instructions';
import { Redirect } from 'react-router-dom';
import 'react-phone-input-2/lib/bootstrap.css';
import { MainWithSkipLink } from '@amzn/stencil-react-components/a11y';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import {
    getColumnsSTSS,
    renderCardForRowSTSS,
    SMSSelfServiceState,
    STSSTableData
} from '../models/SMSSelfServiceState';
import PhoneInput from 'react-phone-input-2';
import { Card } from '@amzn/stencil-react-components/card';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { checkValidCase, getCountry } from '../services/FilterServices';
import { containsLdapElement, LDAP } from '../models/LDAP';
import axios from 'axios';


const appConfig: AppConfig = require('../components/config.json')[`${process.env.REACT_APP_ENVIRONMENT}`];
const authService: AuthService = new AuthService();


export default class STSS extends React.Component<PageProps, SMSSelfServiceState> {
    awsFetchClient: AwsClient | undefined;
    federateToken: FederateData = authService.getDecodedToken();
    totalCount: number = 0;
    allowedCountry: string = '';
    businessGroup: string = '';
    templateName: string = '';

    cssProperties = {
        containerStyle: 'bootstrap'
    } as React.CSSProperties;

    state: SMSSelfServiceState = {
        fetchedDataForDropdown: [],
        fetchedDataForTableRows: [],
        filteredValue: '',
        isOpen: false,
        loading: true,
        isPostError: '',
        isPostSuccess: false,
        readyToRedirect: false,
        selectedPage: 1,
        phone: '',
        isValidCaseNumber: false
    };

    tableData: STSSTableData = {
        BusinessGroup: '',
        Message: '',
        OriginationNumber: '',
        TemplateName: '',
        CaseNumber: ''
    };

    setLoadingValue() {
        this.setState({loading: true});
    }

    componentDidMount() {
        document.title = ' ES Tech SMS Text Service ';
        if (authService.isTokenPresent()) {
            getCredentials().then(async (awsClient) => {
                    this.awsFetchClient = awsClient;
                    try {
                        await this.awsFetchClient.fetch(
                            appConfig.apigUri + 'product?name=ManualSmsAppService',
                            {
                                method: 'GET',
                                headers: {
                                    'token': authService.getToken()
                                }
                            }
                        ).then((promiseResponse) => {
                                return promiseResponse.json();
                            }
                        ).then((fetchedData) => {
                            if (fetchedData.Error !== undefined) {
                                return <Redirect to='/SignedOut'/>;
                            }
                            this.setState({fetchedDataForDropdown: fetchedData.results, loading: false});
                        });

                    } catch (error) {
                        errorHandling(error);
                        this.setState({loading: false});
                    }
                }
            ).catch(async (rejectedPromiseObject) => {
                    errorHandling(rejectedPromiseObject);
                }
            );
        } else {
            this.setState({readyToRedirect: true});
        }
    }

    getTableData(pageNumber: number, businessGroup: string) {
        this.setLoadingValue();

        if (authService.isTokenPresent()) {
            getCredentials().then(async (awsClient) => {
                    this.awsFetchClient = awsClient;
                    try {
                        await this.awsFetchClient.fetch(
                            appConfig.apigUri + 'component?p_name=ManualSmsAppService&c_name=' + businessGroup + '&page=' + pageNumber,
                            {
                                method: 'GET',
                                headers: {
                                    'token': authService.getToken()
                                }
                            }
                        ).then((promiseResponse) => {
                                return promiseResponse.json();
                            }
                        ).then((fetchedData) => {
                            if (fetchedData.Error !== undefined) {
                                if (fetchedData.Error.message === 'Token validation failed: Signature has expired') {
                                    return <Redirect to='/SignedOut'/>;
                                }
                                throw new CustomError(fetchedData.Error);
                            }
                            if (fetchedData.results !== undefined) {
                                this.totalCount = fetchedData.count;
                                fetchedData = fetchedData.results;
                                this.setState({selectedPage: 1});
                            }
                            this.setState({fetchedDataForTableRows: fetchedData, loading: false});
                        });

                    } catch (error) {
                        errorHandling(error);
                        this.setState({loading: false});
                    }
                }
            ).catch(async (rejectedPromiseObject) => {
                    errorHandling(rejectedPromiseObject);
                }
            );
        } else {
            this.setState({readyToRedirect: true});
        }
    }

    sendSMS() {
        this.setLoadingValue();

        const body = {
            'PhoneNumber': this.state.phone,
            'CaseNumber': this.tableData.CaseNumber.trim(),
            'Message': this.tableData.Message,
            'Template': this.tableData.TemplateName,
            'EmployeeId': this.federateToken.EMP_ID,
            'EmployeeName': this.federateToken.GIVEN_NAME + ' ' + this.federateToken.FAMILY_NAME,
            'BusinessGroup': this.tableData.BusinessGroup,
            'OriginationNumber': this.tableData.OriginationNumber
        };

        this.businessGroup = this.tableData.BusinessGroup;
        this.templateName = this.tableData.TemplateName;

        const headers = {
            'x-api-key': appConfig.apigAPIKey
        };

        if (authService.isTokenPresent()) {
            axios.post(appConfig.apigURLSendText, body, {
                headers: headers
            }).then(({data: fetchedData}) => {

                if (fetchedData.MessageResponse === undefined) {
                    throw new CustomError(fetchedData);
                }

                let statusMessage:
                    {
                        DeliveryStatus: string;
                        StatusMessage: string;
                    }
                Object.keys(fetchedData.MessageResponse.Result).forEach((key) => {
                    statusMessage = {
                        DeliveryStatus: fetchedData.MessageResponse.Result[key].DeliveryStatus,
                        StatusMessage: fetchedData.MessageResponse.Result[key].StatusMessage
                    };
                });

                if (statusMessage!!.DeliveryStatus !== 'SUCCESSFUL') {
                    throw new CustomError(statusMessage!!.StatusMessage);
                }

                this.setState({loading: false, isPostSuccess: true, isPostError: ''});
                this.tableData = {
                    BusinessGroup: '',
                    Message: '',
                    OriginationNumber: '',
                    TemplateName: '',
                    CaseNumber: ''
                };
            })
                .catch((error) => {
                    errorHandling(error);
                    this.setState({loading: false, isPostError: error.message as string, isPostSuccess: false});
                    this.tableData = {
                        BusinessGroup: '',
                        Message: '',
                        OriginationNumber: '',
                        TemplateName: '',
                        CaseNumber: ''
                    };
                });
        } else {
            this.setState({readyToRedirect: true});
        }
    }

    handleSubmitSMS = (event: any) => {
        event.preventDefault();
        this.sendSMS();
    };

    close = () => this.setState({isOpen: false});

    checkBlankText = () => {
        if (this.state.phone.trim() === '') {
            alert('The Phone Number can not be empty.');
            return true;
        }

        if (this.state.phone.trim().length < 11) {
            alert('Enter a valid Phone Number.');
            return true;
        }

        if (this.tableData.CaseNumber.trim() === '') {
            alert('The Case Number can not be empty.');
            return true;
        }

        const isValid = checkValidCase(this.tableData.BusinessGroup, this.tableData.CaseNumber.trim());
        if (isValid.cause !== true) {
            alert(isValid.cause);
            return true;
        }
    };

    getModal() {
        return (
            <>
                <div style={cssPropertiesTable}>
                    <Table isStriped={true} getRowAttributes={this.getRowAttributes}
                           aria-labelledby='heading'
                           renderCardForRow={renderCardForRowSTSS}
                           columns={getColumnsSTSS}
                           data={this.state.fetchedDataForTableRows}
                           actionHeader={'Send SMS'}
                           spacing={TableSpacing.Default}
                           shouldAutoCollapseOnExpand={true}
                           shouldScrollHorizontally={true}/>
                </div>
                <Modal isScrollable={false} shouldCloseOnClickOutside={false} isOpen={this.state.isOpen}
                       close={this.close} aria-labelledby='Edit'>
                    <ModalContent
                        aria-label='Edit Message Details'
                        maxWidth={1050}
                        buttons={[
                            <Button
                                variant={ButtonVariant.Primary}
                                onClick={(event) => {
                                    if (!this.checkBlankText()) {
                                        this.close();
                                        this.handleSubmitSMS(event);
                                        this.setState({phone: '', isValidCaseNumber: false});
                                    }
                                }}
                                type='submit'> Submit SMS </Button>,
                            <Button
                                onClick={() => {
                                    this.close();
                                    this.setState({phone: '', isValidCaseNumber: false});
                                    this.tableData = {
                                        BusinessGroup: '',
                                        Message: '',
                                        OriginationNumber: '',
                                        TemplateName: '',
                                        CaseNumber: ''
                                    };
                                }}
                                key='Close'
                                icon={<IconCross aria-hidden={true} size={IconSize.ExtraSmall}/>}
                                isDestructive
                                variant={ButtonVariant.Tertiary}>
                                Close
                            </Button>
                        ]}>
                        <H2 id='Edit' fontSize='T400'> Business Group : {this.tableData.BusinessGroup} </H2>
                        <H3 id='Edit' fontSize='T300'> Text Template : {this.tableData.TemplateName} </H3>
                        {modalInstructionsSTSS()}
                        <br/>
                        <Text fontSize='T200'> Text message to be sent → </Text>
                        <br/>
                        <Card width={635} justifyContent='flex-start' alignItems='center'>
                            <Text fontSize='T200'>
                                {this.tableData.Message}
                            </Text>
                        </Card>
                        <br/>
                        <FormWrapper
                            padding='S500' maxWidth={650} width={650}>
                            <InputHeader
                                labelText='Enter the Phone Number'
                                htmlFor='phone-input'
                                tooltipText='Enter a valid Phone Number according to the Country of Business rules'
                            />
                            <PhoneInput
                                containerStyle={this.cssProperties}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true
                                }}
                                country={this.allowedCountry}
                                onlyCountries={[this.allowedCountry]}
                                disableDropdown={true}
                                countryCodeEditable={false}
                                placeholder='Enter phone number'
                                value={this.state.phone}
                                onChange={(value: any) => this.setState({phone: value})}
                            />
                            <InputWrapper id='text-preview-1'
                                          tooltipText={tooltipInstructionsSTSS(this.tableData.BusinessGroup) as unknown as string}
                                          labelText='Case Number '
                                          error={this.state.isValidCaseNumber}
                                          footerText='Enter a valid Case Number here'>
                                {(textAreaProps) => <TextArea
                                    height={45}
                                    aria-label='Enter a valid Case Number here'
                                    onChange={(event) => {
                                        this.tableData.CaseNumber = event.target.value;
                                        this.setState({isValidCaseNumber: checkValidCase(this.tableData.BusinessGroup, event.target.value.trim()).flag})
                                    }}
                                    {...textAreaProps}
                                />}
                            </InputWrapper>
                        </FormWrapper>
                    </ModalContent>
                </Modal>
            </>
        );
    }

    getRowAttributes = () => ({
            onClick: ({index}: any) => {
                this.setState({isOpen: true});
                this.tableData.BusinessGroup = this.state.fetchedDataForTableRows[index].BusinessGroup;
                this.allowedCountry = getCountry(this.tableData.BusinessGroup);

                this.tableData.Message = this.state.fetchedDataForTableRows[index].Message;
                this.tableData.OriginationNumber = this.state.fetchedDataForTableRows[index].OriginationNumber;
                this.tableData.TemplateName = this.state.fetchedDataForTableRows[index].TemplateName;
            }
        }
    );

    render() {

        if (!authService.isUserAuthenticated() && this.federateToken === null)
            return <SignedOut allowance={true}/>;

        if (this.state.readyToRedirect) {
            return <SignedOut allowance={true}/>;
        }

        if (!containsLdapElement(this.federateToken.LDAPGROUP, LDAP[6].toString())) {
            return <Forbidden federateToken={this.federateToken} allowance={true}/>;
        } else {
            return (
                <StencilProvider theme={{
                    ...HR2021Theme
                }}>
                    <View height={150}>
                        <CustomHeader federateToken={this.federateToken}/>
                        <Col margin={{left: 155}}>
                            {
                                authService.isTokenPresent() &&
                                <div className='link-underline'>
                                    <Avatar
                                        imageAltText='badge image'
                                        fullName={this.federateToken.FAMILY_NAME + ', ' + this.federateToken.GIVEN_NAME}
                                        username={this.federateToken.sub}
                                        usernameClickable={true}
                                        margin={25}>
                                    </Avatar>
                                </div>
                            }
                        </Col>
                    </View>
                    <PageContainer
                        backgroundColor='primary05'
                        alignItems='center'
                        centered={true}
                        paddingBottom='S700'
                        paddingTop='S600'
                        paddingHorizontal='S600'>
                        {
                            this.state.isPostSuccess &&
                            <MessageBanner iconAltText='Successful'
                                           title='Sending Successful'
                                           type={MessageBannerType.Success}
                                           isDismissible
                                           onDismissed={() => {
                                               this.setState({isPostSuccess: false});
                                           }}>
                                The SMS for {this.businessGroup} [ {this.templateName} ] is sent
                                successfully.
                            </MessageBanner>
                        }
                        {
                            this.state.isPostError !== '' &&
                            <MessageBanner iconAltText='Failure'
                                           title='Sending Failed'
                                           type={MessageBannerType.Error}
                                           isDismissible
                                           onDismissed={() => this.setState({isPostError: ''})}>
                                There seems to be an issue - {this.state.isPostError}. Please call the employee and
                                leave a message.
                            </MessageBanner>
                        }
                        <br/>
                        <Col
                            backgroundColor='primary05'
                            alignItems='center'
                            justifyContent='center'>
                            <MainWithSkipLink>
                                <div>
                                    <H1 id='heading' textAlign='center'> ES Technology SMS Text Service </H1>
                                    <br/>
                                    {
                                        this.state.loading &&
                                        <StencilProvider theme={{
                                            ...HR2021Theme
                                        }}>
                                            <br/>
                                            <Col gridGap={35} aria-live='assertive' aria-busy={this.state.loading}>
                                                <Spinner size={70} strokeWidth={9} showText textColor='neutral100'
                                                         aria-live='assertive' aria-busy={this.state.loading}/>
                                            </Col>
                                            <br/>
                                        </StencilProvider>
                                    }
                                    <br/>
                                    <Row gridGap='S500' justifyContent='center' flexWrap='wrap'
                                         aria-describedby='smsType-ud-selector-label'>

                                        <Select placeholder='Choose Business group from Dropdown'
                                                isSearchable={true}
                                                aria-label='Choose Business group from Dropdown'
                                                searchFieldAriaLabel='Choose Business group from Dropdown'
                                                aria-describedby='smsType-cl-selector-label'
                                                width={'60vw'}
                                                options={this.state.fetchedDataForDropdown}
                                                onChange={filteredValue => {
                                                    this.setState({filteredValue: filteredValue});
                                                    this.getTableData(1, filteredValue);
                                                }}/>
                                    </Row>
                                    <br/>
                                    <br/>
                                    <Row gridGap='S500' display='flex' alignItems='flex-end' justifyContent='flex-end'
                                         padding='S300'>
                                        <Text fontWeight='bold' textAlign='right' fontSize='T200'>Total no. of
                                            Rows: {this.totalCount}</Text>
                                        {
                                            this.state.fetchedDataForTableRows.length !== 0 &&
                                            <Pagination
                                                aria-label='pagination'
                                                numberOfPages={Math.ceil(this.totalCount / 10)}
                                                selectedPage={this.state.selectedPage}
                                                onPageSelect={(value) => {
                                                    this.setState({selectedPage: value});
                                                    this.getTableData(value, this.state.filteredValue);
                                                }}
                                            />
                                        }
                                    </Row>
                                    <Col
                                        backgroundColor='primary05'
                                        alignItems='center'
                                        justifyContent='center'>
                                        {this.getModal()}
                                        <br/>
                                    </Col>
                                    <br/>
                                </div>
                            </MainWithSkipLink>
                        </Col>
                        <CustomBackToTop/>
                    </PageContainer>
                    <CustomFooter/>
                </StencilProvider>
            );
        }
    }
}
