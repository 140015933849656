import React from 'react';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { Button, ButtonIconPosition, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button';
import { H1, H2, P, Text } from '@amzn/stencil-react-components/text';
import { PageContainer } from '@amzn/stencil-react-components/page';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { Table, TableSpacing } from '@amzn/stencil-react-components/table';
import { Avatar, AvatarText } from '@amzn/stencil-react-components/avatar';
import { FormWrapper, InputWrapper, Select, TextAreaWithRecommendedLength } from '@amzn/stencil-react-components/form';
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal';
import { FederateData, PageProps } from '../models/FederateData';
import { HR2021Theme } from '@amzn/stencil-react-theme-default';
import AuthService from '../services/AuthService';
import CustomFooter from '../components/CustomFooter';
import CustomBackToTop from '../components/CustomBackToTop';
import CustomHeader, { ButtonWithTooltip, IconWithTooltip } from '../components/CustomHeader';
import { containsLdapElement, containsLdapElementForViewOnly, LDAP } from '../models/LDAP';
import { Spinner } from '@amzn/stencil-react-components/spinner';
import { IconArrowRight, IconCross, IconDownload, IconReset, IconSize } from '@amzn/stencil-react-components/icons';
import SignedOut from './SignedOut';
import Forbidden from './Forbidden';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Popover, PopoverPosition } from '@amzn/stencil-react-components/popover';
import { TabBar, TabPanel, TabsLayout, TabSwitcher } from '@amzn/stencil-react-components/tabs';
import errorHandling from '../components/ErrorHandler';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { IVRTableData, SelfServiceState } from '../models/IVRTextSelfServiceState';
import { getCredentials } from '../services/aws.service';
import { AwsClient } from 'aws4fetch';
import { AppConfig } from '../models/AppConfig';
import { tabs } from '../models/CustomTabs';
import CustomError from '../models/CustomError';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import {
    getColumnsChangelog,
    getColumnsIVR,
    getRowAttributesCL,
    renderCardForRowSS
} from '../models/SelfServiceTableData';
import { cssPropertiesTable } from '../models/TableCustomCSS';
import { modalInstructionsSS } from '../models/Instructions';
import { Redirect } from 'react-router-dom';
import '../App.css';
import { MainWithSkipLink } from '@amzn/stencil-react-components/a11y';
import { PopupDateRangePicker } from '@amzn/stencil-react-components/date-picker';
import { Expander } from '@amzn/stencil-react-components/expander';
import { convertToTimestamp, isBlank } from '../services/FilterServices';
import { TOOLTIP_POSITION, TooltipAlignment } from '@amzn/stencil-react-components/tooltip';


var FileSaver = require('file-saver');
const Diff = require('diff');
const authService: AuthService = new AuthService();
const appConfig: AppConfig = require('../components/config.json')[`${process.env.REACT_APP_ENVIRONMENT}`];

export default class IVRTSS extends React.Component<PageProps, SelfServiceState> {
    awsFetchClient: AwsClient | undefined;
    totalCount1: number = 0;
    totalCount2: number = 0;
    filters1: string[] = [];
    filters2: string[] = [];
    valueOfAT: string[] = [];
    federateToken: FederateData = authService.isTokenPresent() ? authService.getDecodedToken() : window.location.href = '/auth';
    auditFilterURI: string = '';
    promiseAwsFetchClient: Promise<AwsClient> = getCredentials();
    isReadOnly: boolean = containsLdapElementForViewOnly(this.federateToken.LDAPGROUP, LDAP[4].toString());

    state: SelfServiceState = {
        fetchedDataForDropdown: [],
        fetchedDataForTableRows: [],
        fetchedChangelogData: [],
        isOpen: false,
        loading: true,
        isPostError: '',
        isPostErrorSMS: '',
        isPostSuccess: false,
        isPostSuccessSMS: false,
        readyToRedirect: false,
        phone: '',
        tabValue: tabs[0],
        selectedPage1: 1,
        selectedPage2: 1,
        filteredValue: [],
        filteredValueChangelog: [],
        startDate: '',
        endDate: '',
        isExpanded: false
    };

    onResetFilterMainContent() {
        this.setState(prev => ({ ...prev, filteredValue: [] }));
        this.filters1 = [];
    }

    onResetFilterAuditTrails() {
        this.setState(prev => ({ ...prev, filteredValueChangelog: [] }));
        this.filters2 = [];
    }

    tableData: IVRTableData = {
        smsType: '',
        smsBody: '',
        comment: ''
    };

    updatedTableData: IVRTableData = {
        smsType: '',
        smsBody: '',
        comment: ''
    };

    setLoadingValue() {
        this.setState({ loading: true });
    }

    componentDidMount() {
        document.title = ' IVR Text Self-Service ';
        if (authService.isTokenPresent()) {
            this.getTableData(false, false, 1, []);
            this.getTableDataForChangelog(false, false, 1, [], this.state.startDate, this.state.endDate);
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        appConfig.apigUri + 'product?name=SmsFlex',
                        {
                            method: 'GET',
                            headers: {
                                'token': authService.getToken()
                            }
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              return <Redirect to='/SignedOut' />;
                          }
                          this.setState({ fetchedDataForDropdown: fetchedData.results, loading: false });
                      });

                  } catch (error) {
                      errorHandling(error);
                      this.setState({ loading: false });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    getTableData(isLoading: boolean, isFiltered: boolean, pageNumber: number, smsArray: string[]) {
        if (isLoading) {
            this.setLoadingValue();
        }

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        !isFiltered ? appConfig.apigUri + 'component?p_name=SmsFlex&page=' + pageNumber : appConfig.apigUri + 'component?p_name=SmsFlex&c_name=' + smsArray.toString() + '&page=' + pageNumber,
                        {
                            method: 'GET',
                            headers: {
                                'token': authService.getToken()
                            }
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              return <Redirect to='/SignedOut' />;
                          }
                          if (fetchedData.results !== undefined) {
                              this.totalCount1 = fetchedData.count;
                              fetchedData = fetchedData.results;
                          }
                          if (smsArray.length === 1) {
                              this.totalCount1 = 1;
                          }

                          this.setState({ fetchedDataForTableRows: fetchedData, loading: false });
                      });

                  } catch (error) {
                      errorHandling(error);
                      this.setState({ loading: false });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    updateTableData(tableData: IVRTableData) {
        this.setLoadingValue();
        const body = {
            'smsBody': tableData.smsBody,
            'prevSmsBody': this.tableData.smsBody,
            'comment': tableData.comment,
            'user': this.federateToken.sub
        };

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        appConfig.apigUri + 'component?p_name=SmsFlex&c_name=' + this.tableData.smsType.toString(),
                        {
                            method: 'PATCH',
                            headers: {
                                'token': authService.getToken()
                            },
                            body: JSON.stringify(body)
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              throw new CustomError(fetchedData.Error);
                          }
                          if (fetchedData.results !== undefined) {
                              this.totalCount1 = fetchedData.count;
                              fetchedData = fetchedData.results;
                          }
                          this.onResetFilterMainContent();
                          this.setState({
                              fetchedDataForTableRows: fetchedData,
                              loading: false,
                              isPostSuccess: true,
                              isPostError: '',
                              selectedPage1: 1
                          });
                          this.updatedTableData = {
                              smsType: '',
                              smsBody: '',
                              comment: ''
                          };
                      });
                  } catch (error: any) {
                      errorHandling(error);
                      this.setState({
                          loading: false,
                          isPostError: error.message,
                          isPostSuccess: false
                      });
                      this.updatedTableData = {
                          smsType: '',
                          smsBody: '',
                          comment: ''
                      };
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    getTableDataForChangelog(isLoading: boolean, isFiltered: boolean, pageNumber: number, smsTypeArray: string[], startDate: string | undefined, endDate: string | undefined) {
        if (isLoading) {
            this.setLoadingValue();
        }

        startDate = convertToTimestamp(startDate);
        endDate = convertToTimestamp(endDate);

        this.auditFilterURI = isBlank(startDate) ? isBlank(startDate) && !isBlank(endDate) ? '&end_timestamp=' + endDate : '' :
          this.auditFilterURI = isBlank(endDate) ? !isBlank(startDate) && isBlank(endDate) ? '&start_timestamp=' + startDate : '' :
            '&start_timestamp=' + startDate + '&end_timestamp=' + endDate;

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        !isFiltered ? appConfig.apigUriAudit + 'audit_trails?name=SmsFlex&page=' + pageNumber :
                          appConfig.apigUriAudit + 'audit_trails?name=SmsFlex&filter_by=' + smsTypeArray.toString() + '&page=' + pageNumber + this.auditFilterURI,
                        {
                            method: 'GET',
                            headers: {
                                'token': authService.getToken()
                            }
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then(async (fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              throw new CustomError(fetchedData.Error);
                          }

                          if (pageNumber === 1) {
                              this.totalCount2 = fetchedData.count;
                              fetchedData = fetchedData.results;
                          }

                          fetchedData = await fetchedData.filter((element: any) => element.diffText = (Diff.diffChars(element.PreviousState, element.CurrentState)));
                          fetchedData.filter((element: any): void | null => {
                              let str: string = '<pre style="line-height: 1.5em;font-size: 15px">';
                              element.diffText.filter((innerElement: any): void | null => {
                                  const screenReaderAction = innerElement.added ? '<span class="screen-reader"> Current characters </span>' : innerElement.removed ? '<span class="screen-reader"> Previous characters </span>' : '<span class="screen-reader"> Unchanged characters </span>';
                                  const backgroundColor = innerElement.added ? '#86dac9' : innerElement.removed ? '#f8a49e' : 'white';
                                  const action = innerElement.added ? 'ins' : innerElement.removed ? 'del' : 'span';
                                  str += (screenReaderAction + '<' + action + ' style="background-color:' + backgroundColor + ';">' + innerElement.value + '</' + action + '>');
                                  return null;
                              }, '');
                              str += '</pre>';
                              element.diffText = str;
                              return null;
                          }, '');

                          this.setState({ fetchedChangelogData: fetchedData, loading: false });
                      });
                  } catch (error) {
                      errorHandling(error);
                      this.setState({ loading: false });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    getChangelogCSV(isLoading: boolean, smsTypeArray: string[], startDate: string | undefined, endDate: string | undefined) {
        if (isLoading) {
            this.setLoadingValue();
        }

        startDate = convertToTimestamp(startDate);
        endDate = convertToTimestamp(endDate);

        const isFiltered: boolean = smsTypeArray.length === 0 && isBlank(startDate) && isBlank(endDate)

        this.auditFilterURI = isBlank(startDate) ? isBlank(startDate) && !isBlank(endDate) ? '&end_timestamp=' + endDate : '' :
          this.auditFilterURI = isBlank(endDate) ? !isBlank(startDate) && isBlank(endDate) ? '&start_timestamp=' + startDate : '' :
            '&start_timestamp=' + startDate + '&end_timestamp=' + endDate;

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        isFiltered ? appConfig.apigUriAudit + 'audit_trails?name=SmsFlex&page=1&is_csv_requested=true' :
                          appConfig.apigUriAudit + 'audit_trails?name=SmsFlex&page=1&is_csv_requested=true&filter_by=' + smsTypeArray.toString() + this.auditFilterURI,
                        {
                            method: 'GET',
                            headers: {
                                'token': authService.getToken()
                            }
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then(async (fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              throw new CustomError(fetchedData.Error);
                          }
                          this.setState({ loading: false });
                          FileSaver.saveAs(new Blob([fetchedData], { type: 'text/csv;charset=utf-8' }), 'SmsFlex-' + new Date().toISOString().split('T')[0] +
                            '_' + new Date().toLocaleTimeString('en-GB') + '.csv');
                      });
                  } catch (error) {
                      errorHandling(error);
                      this.setState({ loading: false });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    sendSMS(SMSBody: string, PhoneNumber: string) {
        this.setLoadingValue();

        const body = {
            'SMSBody': SMSBody,
            'PhoneNumber': PhoneNumber
        };

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        appConfig.apigUriSMS + 'sendSMS',
                        {
                            method: 'POST',
                            body: JSON.stringify(body)
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData !== 'Message Delivery status : SUCCESSFUL') {
                              throw new CustomError(fetchedData);
                          }
                          this.setState({ loading: false, isPostSuccessSMS: true });
                      });
                  } catch (error: any) {
                      errorHandling(error);
                      this.setState({ loading: false, isPostErrorSMS: error.message });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    handleSubmit = (event: any) => {
        event.preventDefault();
        this.updateTableData(this.updatedTableData);
    };

    handleSubmitSMS = (event: any, SMSBody: string, PhoneNumber: string) => {
        event.preventDefault();
        this.sendSMS(SMSBody, PhoneNumber);
    };

    close = (clearFields: boolean) => {
        if (clearFields)
            this.updatedTableData.comment = '';
        this.setState({ isOpen: false });
    };

    checkBlankText = () => {
        if (this.updatedTableData.smsBody.trim() === '') {
            alert('The SMS Body can not be empty.');
            return true;
        }

        if (this.updatedTableData.smsBody.trim() === this.tableData.smsBody) {
            alert('No Changes were made to the SMS, please make edits.');
            return true;
        }

        if (this.updatedTableData.comment.trim() === '') {
            alert('Comments are mandatory, please leave a comment.');
            return true;
        }
    };

    downloadText = (length: number) => `Click here Export the ${length} rows to a CSV file.`;

    getModal() {
        return (
          <>
              <div style={cssPropertiesTable}>
                  <Table isStriped={true} getRowAttributes={this.getRowAttributes}
                         aria-labelledby='heading'
                         renderCardForRow={renderCardForRowSS}
                         columns={getColumnsIVR}
                         data={this.state.fetchedDataForTableRows}
                         actionHeader={!this.isReadOnly ? 'Edit' : ''}
                         spacing={TableSpacing.Reduced}
                         shouldAutoCollapseOnExpand={true} shouldScrollHorizontally={true} />
              </div>
              <Modal isScrollable={false} shouldCloseOnClickOutside={false} isOpen={this.state.isOpen}
                     close={() => this.close(true)} aria-labelledby='Edit'>
                  <ModalContent
                    aria-label='Edit Message Details'
                    maxWidth={1050}
                    buttons={[
                        <Button
                          variant={ButtonVariant.Primary}
                          onClick={(event) => {
                              if (!this.checkBlankText()) {
                                  this.setState({ isPostSuccessSMS: false, isPostErrorSMS: '', phone: '' });
                                  this.close(false);
                                  this.handleSubmit(event);
                              }
                          }}
                          type='submit'> Submit </Button>,
                        <Button
                          onClick={() => {
                              this.setState({ isPostSuccessSMS: false, isPostErrorSMS: '', phone: '' });
                              this.updatedTableData.comment = '';
                              this.close(true);
                          }}
                          key='Close'
                          isDestructive
                          icon={<IconCross aria-hidden={true} size={IconSize.ExtraSmall} />}
                          variant={ButtonVariant.Tertiary}>
                            Discard
                        </Button>
                    ]}>
                      <H2 id='Edit' fontSize='T400'> Edit SMS Type : {this.tableData.smsType}</H2>
                      {modalInstructionsSS()}
                      <FormWrapper
                        padding='S500' maxWidth={750} width={750}>
                          <InputWrapper id='text-preview-1'
                                        labelText='SMS Body'
                                        footerText='Enter the updated SMS Template here'>
                              {(textAreaProps) => <TextAreaWithRecommendedLength
                                recommendedCharacterCount={160}
                                renderRecommendedCharactersText={({ characterCount }) => `Character count: ${characterCount}`}
                                renderExceededCharactersText={({ characterCount }) => `Character count: ${characterCount} - Possible Number of Messages: ${Math.ceil(characterCount / 153)}`}
                                height={140}
                                resize='vertical'
                                defaultValue={this.tableData.smsBody}
                                onChange={(event) => {
                                    this.updatedTableData.smsBody = event.target.value;
                                }}
                                {...textAreaProps}
                              />}
                          </InputWrapper>
                          <Row padding='S100' gridGap='S400' justifyContent='center'>
                              <IconWithTooltip
                                title='Show tooltip'
                                tooltipText='Click to open the Preview SMS Pop-Up to "Send" and "Test" the above SMS text to your cellular devices.'
                                color='primary60'
                              />
                              <Popover triggerText='Preview SMS'
                                       shouldFocusOnOpen={true}
                                       maxWidth='32vw'
                                       shouldCloseOnFocusLeave={false}
                                       position={PopoverPosition.BottomCenter}>
                                  {({ close }) => (
                                    <Col gridGap='S200' padding='S300' flexWrap='wrap'>
                                        <P>
                                            Enter the phone number below in the area provided
                                            and click 'Send SMS' to send the SMS Text.
                                        </P>
                                        {
                                            this.state.loading &&
                                            <StencilProvider theme={{
                                                ...HR2021Theme
                                            }}>
                                              <Col gridGap={30} aria-live='assertive' aria-busy={this.state.loading}>
                                                <Spinner loadingText='Dispatching SMS...' size={40} strokeWidth={5}
                                                         showText textColor='neutral70' aria-live='assertive'
                                                         aria-busy={this.state.loading} />
                                              </Col>
                                              <br />
                                            </StencilProvider>
                                        }
                                        <FormWrapper padding='S300' maxWidth={450} width={450}>
                                            {
                                                this.state.isPostSuccessSMS &&
                                                <MessageBanner iconAltText='Successful'
                                                               type={MessageBannerType.Success}
                                                               isDismissible
                                                               onDismissed={() => {
                                                                   this.setState({ isPostSuccessSMS: false });
                                                               }}>
                                                  The SMS is sent successfully.
                                                </MessageBanner>
                                            }
                                            {
                                                this.state.isPostErrorSMS !== '' &&
                                                <MessageBanner iconAltText='Failure'
                                                               type={MessageBannerType.Error}
                                                               isDismissible
                                                               onDismissed={() => this.setState({ isPostErrorSMS: '' })}>
                                                  There seems to be an issue - {this.state.isPostErrorSMS}
                                                </MessageBanner>
                                            }
                                            <PhoneInput
                                              inputProps={{
                                                  name: 'phone',
                                                  required: true,
                                                  autoFocus: true
                                              }}
                                              preferredCountries={['us']}
                                              country={'us'}
                                              placeholder='Enter phone number'
                                              value={this.state.phone}
                                              onChange={(value: any) => this.setState({ phone: value })}
                                            />
                                        </FormWrapper>
                                        <Row gridGap='S300' justifyContent='flex-end'>
                                            <Button
                                              size={ButtonSize.Small}
                                              type='submit'
                                              onClick={(event) => {
                                                  this.handleSubmitSMS(event, this.updatedTableData.smsBody, this.state.phone);
                                              }}>
                                                Send SMS
                                            </Button>
                                            <Button icon={<IconCross aria-hidden={true} size={IconSize.ExtraSmall} />}
                                                    variant={ButtonVariant.Tertiary} size={ButtonSize.Small}
                                                    isDestructive onClick={close}>
                                                Close Popup
                                            </Button>
                                        </Row>
                                    </Col>
                                  )}
                              </Popover>
                          </Row>

                          <InputWrapper id='text-preview-2'
                                        labelText='Comments: Why the changes were made?'
                                        footerText='Ex: Changed Class M option 1 SMS to reflect new time-off regulations that went into place on 4/5.'>
                              {(textAreaProps) => <TextAreaWithRecommendedLength
                                resize='vertical'
                                recommendedWordCount={30}
                                defaultValue={this.tableData.comment}
                                onChange={(event) => {
                                    this.updatedTableData.comment = event.target.value;
                                }}
                                {...textAreaProps} />}
                          </InputWrapper>

                      </FormWrapper>
                  </ModalContent>
              </Modal>
          </>
        );
    }

    getRowAttributes = () => ({
          onClick: ({ index }: any) => {
              this.setState({ isOpen: true });
              this.tableData.smsBody = this.state.fetchedDataForTableRows[index].smsBody;
              this.updatedTableData.smsBody = this.tableData.smsBody;

              this.tableData.smsType = this.state.fetchedDataForTableRows[index].smsType;
              this.updatedTableData.smsType = this.tableData.smsType;
          },
          disabled: this.isReadOnly
      }
    );

    render() {

        if (!authService.isUserAuthenticated() && this.federateToken === null)
            return <SignedOut allowance={true} />;

        if (this.state.readyToRedirect) {
            return <SignedOut allowance={true} />;
        }

        if (!containsLdapElement(this.federateToken.LDAPGROUP, LDAP[4].toString())) {
            return <Forbidden federateToken={this.federateToken} allowance={true} />;
        }

        return (
          <StencilProvider theme={{
              ...HR2021Theme
          }}>
              <View height={150}>
                  <CustomHeader federateToken={this.federateToken} />
                  <Col margin={{ left: 155 }}>
                      {
                          authService.isTokenPresent() &&
                          <div className='link-underline'>
                            <Avatar
                              imageAltText='badge image'
                              fullName={this.federateToken.FAMILY_NAME + ', ' + this.federateToken.GIVEN_NAME}
                              username={this.federateToken.sub}
                              usernameClickable={true}
                              margin={25}>
                              <AvatarText ariaLabel="description">
                                You are accessing this page as {this.isReadOnly ? 'a Read-Only' : 'an Admin'} user.
                              </AvatarText>
                            </Avatar>
                          </div>
                      }
                  </Col>
              </View>

              <PageContainer
                backgroundColor='primary05'
                alignItems='center'
                centered={true}
                paddingBottom='S700'
                paddingTop='S600'
                paddingHorizontal='S600'>
                  {
                      this.state.isPostSuccess &&
                      <MessageBanner iconAltText='Successful' type={MessageBannerType.Success}
                                     title='Update successful'
                                     isDismissible onDismissed={() => {
                          this.setState({ isPostSuccess: false });
                      }}>
                        The {this.tableData.smsType} entry is updated successfully.
                      </MessageBanner>
                  }
                  {
                      this.state.isPostError !== '' &&
                      <MessageBanner iconAltText='Failure' type={MessageBannerType.Error}
                                     title='Update failed' isDismissible
                                     onDismissed={() => this.setState({ isPostError: '' })}>
                        There seems to be an issue with the Update - {this.state.isPostError}
                      </MessageBanner>
                  }
                  <br />
                  <MainWithSkipLink>
                      <Col gridGap='S500' padding='S300'>
                          <H1 id='heading' textAlign='center'> IVR Text Self-Service </H1>
                          <Col alignItems='center'>
                              <TabBar
                                aria-label='Tabs here for Main Content and Change log'
                                layout={TabsLayout.Default}
                                onTabSelect={(tabValue) => {
                                    this.setState({ tabValue: tabValue });
                                    if (tabValue === tabs[1]) {
                                        this.onResetFilterAuditTrails();
                                        this.setState({
                                            selectedPage2: 1,
                                            isExpanded: false,
                                            startDate: '',
                                            endDate: ''
                                        });
                                        this.getTableDataForChangelog(true, false, 1, [], this.state.startDate, this.state.endDate);
                                    }
                                }}
                                selectedTab={this.state.tabValue}
                                tabGroup='TabGroup-1'
                                tabs={tabs}
                              />
                          </Col>

                          <TabSwitcher
                            selectedTab={this.state.tabValue}
                            tabGroup='TabGroup-1'>

                              <TabPanel value='Main Content'>
                                  <Col
                                    backgroundColor='primary05'
                                    alignItems='center'
                                    justifyContent='center'>
                                      {
                                          this.state.loading &&
                                          <StencilProvider theme={{
                                              ...HR2021Theme
                                          }}>
                                            <br />
                                            <Col gridGap={35} aria-live='assertive' aria-busy={this.state.loading}>
                                              <Spinner size={70} strokeWidth={9} showText textColor='neutral100'
                                                       aria-live='assertive' aria-busy={this.state.loading} />
                                            </Col>
                                            <br />
                                          </StencilProvider>
                                      }
                                      <br />
                                  </Col>
                                  <Row gridGap='S500' justifyContent='flex-start' flexWrap='wrap'
                                       aria-describedby='smsType-ud-selector-label'>
                                      <Text id='smsType-cl-selector-label'>
                                          Choose SMS Type to filter:
                                      </Text>

                                      <Select placeholder='Choose SMS Type'
                                              isMulti={true}
                                              isSearchable={true}
                                              aria-describedby='smsType-selector-label'
                                              searchFieldAriaLabel='Choose SMS Type from Dropdown'
                                              aria-label='Choose SMS Type from Dropdown'
                                              width={'50vw'}
                                              options={this.state.fetchedDataForDropdown}
                                              value={this.state.filteredValue}
                                              onChange={filteredValue => {
                                                  this.setState({ filteredValue: filteredValue });
                                              }} />
                                      <Row gridGap='S500' justifyContent='flex-start' flexWrap='wrap'>
                                          <Button
                                            variant={ButtonVariant.Tertiary}
                                            aria-describedby='smsType-cl-selector-label'
                                            size={ButtonSize.Small}
                                            onClick={() => {
                                                this.filters1 = this.state.filteredValue;
                                                this.setState({ selectedPage1: 1 });
                                                this.getTableData(true, true, 1, this.filters1);
                                            }}>
                                              Apply Filter
                                          </Button>
                                      </Row>
                                  </Row>
                                  <br />
                                  <br />
                                  <Row gridGap='S500' display='flex' alignItems='flex-end' justifyContent='flex-end'
                                       padding='S300'>
                                      <Text fontWeight='bold' textAlign='right' fontSize='T200'>Total no. of
                                          Rows: {this.totalCount1}</Text>
                                      {
                                          this.state.fetchedDataForTableRows.length !== 0 &&
                                          <Pagination
                                            aria-label='pagination'
                                            numberOfPages={Math.ceil(this.totalCount1 / 10)}
                                            selectedPage={this.state.selectedPage1}
                                            onPageSelect={(value) => {
                                                this.setState({ selectedPage1: value });
                                                this.getTableData(true, true, value, this.filters1);
                                            }}
                                          />
                                      }
                                  </Row>
                                  <br />
                                  <Col
                                    backgroundColor='primary05'
                                    alignItems='center'
                                    justifyContent='center'>
                                      {this.getModal()}
                                      <br />
                                  </Col>
                                  <br />
                              </TabPanel>

                              <TabPanel value='Audit Trails'>
                                  <Expander
                                    isExpanded={this.state.isExpanded}
                                    onToggle={() => this.setState({ isExpanded: !this.state.isExpanded })}
                                    titleText='Click to open Filters'>
                                      <View backgroundColor='neutral0' alignItems='flex-start'
                                            padding='S300' flexWrap='overflow'>
                                          <Row gridGap='S700' justifyContent='flex-start' flexWrap='wrap'
                                               padding='S300'>
                                              <Col gridGap='S300' alignItems='flex-start' padding='S300'>
                                                  <Text id='smsType-cl1-selector-label'>
                                                      Choose SMS Type to filter:
                                                  </Text>

                                                  <Select placeholder='Choose SMS Type for change log'
                                                          isSearchable={true}
                                                          aria-label='Choose SMS Type from Dropdown'
                                                          searchFieldAriaLabel='Choose SMS Type from Dropdown'
                                                          aria-describedby='smsType-cl-selector-label'
                                                          isMulti={true}
                                                          width='55vw'
                                                          options={this.state.fetchedDataForDropdown}
                                                          value={this.state.filteredValueChangelog}
                                                          onChange={value => {
                                                              this.valueOfAT = value;
                                                              this.setState({ filteredValueChangelog: value });
                                                          }} />
                                              </Col>
                                              <Col gridGap='S300' alignItems='flex-start' padding='S300'>
                                                  <Text id='smsType-cl3-selector-label'>
                                                      Choose Date Range to filter:
                                                  </Text>

                                                  <View width='55vw' justifyContent='flex-start'>
                                                      <PopupDateRangePicker
                                                        ariaLabelsForSelectedDate={{
                                                            endDate: 'You have selected an end date',
                                                            startDate: 'You have selected a start date'
                                                        }}
                                                        id='filter-date-picker'
                                                        name='choose-dates'
                                                        shouldFocusOnStartDate={true}
                                                        locale='iso8601'
                                                        startDateLabelText='Start date (YYYY-MM-DD)'
                                                        endDateLabelText='End date (YYYY-MM-DD)'
                                                        startValue={this.state.startDate as unknown as string}
                                                        endValue={this.state.endDate as unknown as string}
                                                        onChange={(startDate, endDate) => {
                                                            if (startDate)
                                                                this.setState({ startDate: startDate + ' 00:00:00' });
                                                            if (endDate)
                                                                this.setState({ endDate: endDate + ' 23:59:59' });
                                                        }}
                                                      />
                                                  </View>
                                              </Col>
                                          </Row>

                                          <Row gridGap='S700' padding='S600' justifyContent='center' flexWrap='wrap'>
                                              <Button
                                                variant={ButtonVariant.Primary}
                                                type='button'
                                                aria-describedby='smsType-cl1-selector-label'
                                                icon={<IconArrowRight title='Apply Filter' aria-hidden={true} />}
                                                iconPosition={ButtonIconPosition.Trailing}
                                                onClick={() => {
                                                    this.filters2 = this.valueOfAT;
                                                    this.filters2 = this.state.filteredValueChangelog;
                                                    this.getTableDataForChangelog(true, true, 1, this.filters2, this.state.startDate, this.state.endDate);
                                                    this.setState({ selectedPage2: 1 });
                                                }}>
                                                  Apply Filter
                                                  &nbsp;
                                              </Button>
                                              <Button
                                                variant={ButtonVariant.Primary}
                                                type='button'
                                                icon={<IconReset title='Reset Filter' aria-hidden={true} />}
                                                iconPosition={ButtonIconPosition.Trailing}
                                                onClick={() => {
                                                    this.onResetFilterAuditTrails();
                                                    this.setState({
                                                        selectedPage2: 1,
                                                        startDate: '',
                                                        endDate: ''
                                                    });
                                                    this.getTableDataForChangelog(true, false, 1, [], this.state.startDate, this.state.endDate);
                                                }}>
                                                  Reset Filters
                                                  &nbsp;
                                              </Button>
                                          </Row>
                                      </View>
                                  </Expander>
                                  <br />
                                  <Col
                                    backgroundColor='primary05'
                                    alignItems='center'
                                    justifyContent='center'>
                                      {
                                          this.state.loading &&
                                          <StencilProvider theme={{
                                              ...HR2021Theme
                                          }}>
                                            <br />
                                            <Col gridGap={35} aria-live='assertive' aria-busy={this.state.loading}>
                                              <Spinner size={70} strokeWidth={9} showText textColor='neutral100'
                                                       aria-live='assertive' aria-busy={this.state.loading} />
                                            </Col>
                                            <br />
                                          </StencilProvider>
                                      }
                                      <br />
                                  </Col>
                                  <Row gridGap='S500' display='flex' alignItems='flex-end' justifyContent='flex-end'
                                       padding='S300'>
                                      <Text margin={{ bottom: 7 }} fontWeight='bold' textAlign='right' fontSize='T200'>Total no. of
                                          Rows: {this.totalCount2}</Text>
                                      {
                                          this.state.fetchedChangelogData.length !== 0 &&
                                          <ButtonWithTooltip
                                            role='tooltip'
                                            aria-describedby='Click here to Export the changelogs to CSV.'
                                            icon={<IconDownload title='Export to CSV' />}
                                            title={this.downloadText(this.totalCount2)}
                                            variant={ButtonVariant.Secondary}
                                            onClick={() => {
                                                this.getChangelogCSV(true, this.filters2, this.state.startDate, this.state.endDate);
                                            }}
                                            tooltipAlignment={TooltipAlignment.Left}
                                            tooltipPosition={TOOLTIP_POSITION.BOTTOM}
                                            size={ButtonSize.Small}
                                            tooltipText={this.downloadText(this.totalCount2)}
                                          >
                                            Download Changelog
                                          </ButtonWithTooltip>
                                      }
                                      <Spacer flex={0.20} />
                                      {
                                          this.state.fetchedChangelogData.length !== 0 &&
                                          <Pagination
                                            aria-label='pagination'
                                            numberOfPages={Math.ceil(this.totalCount2 / 10)}
                                            selectedPage={this.state.selectedPage2}
                                            onPageSelect={(value) => {
                                                this.setState({ selectedPage2: value });
                                                this.getTableDataForChangelog(true, true, value, this.filters2, this.state.startDate, this.state.endDate);
                                            }}
                                          />
                                      }
                                  </Row>
                                  <br />
                                  <div style={cssPropertiesTable}>
                                      <Table
                                        aria-labelledby='smsType-cl1-selector-label'
                                        actionHeader={'Expand'}
                                        getRowAttributes={getRowAttributesCL}
                                        columns={getColumnsChangelog}
                                        data={this.state.fetchedChangelogData}
                                        spacing={TableSpacing.Default}
                                        shouldAutoCollapseOnExpand={true}
                                        shouldScrollHorizontally={true} />
                                  </div>
                                  <br />
                              </TabPanel>
                          </TabSwitcher>
                      </Col>
                  </MainWithSkipLink>
                  <br />
                  <CustomBackToTop />
              </PageContainer>
              <CustomFooter />
          </StencilProvider>
        );
    }
}
