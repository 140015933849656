import { Flex, Hr, Spacer, View } from '@amzn/stencil-react-components/layout';
import { P } from '@amzn/stencil-react-components/text';
import { Link } from '@amzn/stencil-react-components/link';
import { IconExternalLink, IconSize } from '@amzn/stencil-react-components/icons';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import React from 'react';
import { HR2021Theme } from '@amzn/stencil-react-theme-default';
import { BreakpointPropsMap, useBreakpoints } from '@amzn/stencil-react-components/responsive';


interface MappedProps {
    s: boolean;
    flexDirection: string;
}

const propsMap: BreakpointPropsMap<MappedProps> = {
    s: { s: true, flexDirection: 'column' },
    default: { s: true, flexDirection: 'row' }
};

const CustomFooter = () => {
    const { props } = useBreakpoints({ propsMap });
    return (
      <StencilProvider theme={{
          ...HR2021Theme
      }}>
          <View margin={[250, 16, 0]} padding='S600' role='contentinfo'>
              <Hr color='primary70' size='wide'/>
              <br />
              <Flex {...props} gridGap='S200' alignItems='center' padding={{ top: 4, bottom: 3, left: 10, right: 10 }}>
                  <Spacer flex={0.5} />
                  <Link rel='noopener noreferrer' target='_blank' margin={[13, 20, 20]} textAlign='right' fontSize='T100'
                        href='https://w.amazon.com/bin/view/CCCMS_Connect/HRCP_Support_Tool' >
                      Learn more about this tool <IconExternalLink size={IconSize.ExtraSmall} display='inline' target='_blank' title='Opens in a new tab' aria-details='Opens in a new tab'/>
                  </Link>
                  <Spacer flex={0.5} />
                  <P textAlign='center' fontSize='T200' fontWeight='regular'> HR Connect Process Support
                      Tool &nbsp; | &nbsp; {[`${process.env.REACT_APP_VERSION}`][0]} </P>
                  <Spacer flex={0.5} />
                  <Link rel='noopener noreferrer' target='_blank' margin={[13, 20, 20]} textAlign='left' fontSize='T100'
                        href='https://tt.amazon.com/quicklink/Q001217199' >
                      Report an issue with the page <IconExternalLink size={IconSize.ExtraSmall} display='inline' target='_blank' title='Opens in a new tab' aria-details='Opens in a new tab'/>
                  </Link>
                  <Spacer flex={0.5} />
              </Flex>
              <br />
          </View>
      </StencilProvider>
    );
};

export default CustomFooter;
