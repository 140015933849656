import React from 'react';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { H1, H4 } from '@amzn/stencil-react-components/text';
import { PageContainer } from '@amzn/stencil-react-components/page';
import { Col, View } from '@amzn/stencil-react-components/layout';
import { LinkCard } from '@amzn/stencil-react-components/card';
import { Avatar } from '@amzn/stencil-react-components/avatar';
import { PageAllowed, PageProps } from '../models/FederateData';
import { HR2021Theme } from '@amzn/stencil-react-theme-default';
import AuthService from '../services/AuthService';
import CustomHeader from '../components/CustomHeader';
import CustomBackToTop from '../components/CustomBackToTop';
import CustomFooter from '../components/CustomFooter';
import { CustomBanner } from '../components/CustomBanner';
import { Link as RouterLink } from 'react-router-dom';
import Error from './Error';
import SignedOut from './SignedOut';
import '../App.css'

export const RouterLinkCard = LinkCard.withComponent(RouterLink);
const authService: AuthService = new AuthService();

const Forbidden: React.FC<PageProps & PageAllowed> = (pageProps) => {

    if (!authService.isUserAuthenticated() && pageProps.federateToken === null) {
        return <SignedOut allowance={true} />;
    }

    if (!pageProps.allowance) {
        return <Error federateToken={pageProps.federateToken} />;
    }

    RouterLinkCard.defaultProps = {
        ...(LinkCard.defaultProps as any)
    };

    return (
      <StencilProvider theme={{
          ...HR2021Theme
      }}>
          <View height={150}>
              <CustomHeader federateToken={pageProps.federateToken} />
              <Col margin={{ left: 155 }}>
                  {
                      authService.isTokenPresent() &&
                      <div className='link-underline'>
                        <Avatar
                          imageAltText='badge image'
                          fullName={pageProps.federateToken.FAMILY_NAME + ', ' + pageProps.federateToken.GIVEN_NAME}
                          username={pageProps.federateToken.sub}
                          usernameClickable={true}
                          margin={25}>
                        </Avatar>
                      </div>
                  }
              </Col>
          </View>
          <PageContainer
            alignItems='center'
            centered={true}
            paddingHorizontal='S600'>
              <CustomBanner />
              <RouterLinkCard justifyContent='center' alignSelf='center' alignItems='flex-start' to='/'
                              backgroundColor='primary05'>
                  <Col gridGap={32}>
                      <br />
                      <H1 textAlign='center'>You don't have permission to access this page.</H1>
                      <H4 textAlign='center'> Back To Home </H4>
                      <br />
                  </Col>
              </RouterLinkCard>
              <CustomBackToTop />
          </PageContainer>
          <CustomFooter />
      </StencilProvider>
    );
};

export default Forbidden;
