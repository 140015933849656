import { defaultCardRenderer } from "@amzn/stencil-react-components/table";
import {
  AvatarCell,
  ColumnWithMono,
  ColumnWithTime,
} from "./SelfServiceTableData";

export const userManagementColumnsChangeLog = [
  {
    header: "Username",
    accessor: ({ data }: { data: any }) => data.CurrentState.Username,
    cellComponent: ColumnWithMono,
    useMinimalSpacing: false,
  },
  {
    header: "Operation",
    accessor: "OperationType",
    cellComponent: ColumnWithMono,
    useMinimalSpacing: false,
  },
  {
    header: "Created By",
    cellComponent: AvatarCell,
    useMinimalSpacing: false,
  },
  {
    header: "Event Time",
    accessor: "TrailTimestamp",
    cellComponent: ColumnWithTime,
    useMinimalSpacing: false,
  },
  {
    header: "Comment",
    accessor: "TrailComment",
    cellComponent: ColumnWithMono,
    useMinimalSpacing: false,
  },
];

export const renderCardForChangelogUserManagement = defaultCardRenderer({
  columns: userManagementColumnsChangeLog,
  useCellComponents: true,
});
