export function isSSMLValid(str: string) {
    return (str.match('^<speak>') && str.match('</speak>$'));
}

const options = {
    attributeNamePrefix: '@_',
    attrNodeName: 'attr',
    textNodeName: '#text',
    ignoreAttributes: true,
    ignoreNameSpace: false,
    allowBooleanAttributes: false,
    parseNodeValue: true,
    parseAttributeValue: false,
    trimValues: true,
    cdataTagName: '__cdata',
    cdataPositionChar: '\\c',
    parseTrueNumberOnly: false,
    arrayMode: false,
    stopNodes: ['parse-me-as-string']
};

export function isValidTag(str: string) {
    const parser = require('fast-xml-parser');
    let jsonObj;
    try {
        parser.parse(str, options, true);
        jsonObj = parser.validate(str, options);
    } catch (error) {
        jsonObj = error;
    }
    return jsonObj;
}
