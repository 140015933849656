import { defaultCardRenderer } from '@amzn/stencil-react-components/table';
import { AvatarCell, ColumnWithHTML, ColumnWithMono, ColumnWithTime } from './SelfServiceTableData';
import { cssPropertiesIVR } from './SelfServiceCustomCSS';
import { Text } from '@amzn/stencil-react-components/text';
import { Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import React from 'react';

export interface SMTableData {
    MsgId: string;
    Enabled: boolean | undefined | string;
    Language: string;
    Text: string;
    comment: string;
}


export const getColumnsSituationalMessages = [
    {
        header: 'Message Id',
        accessor: 'MsgId',
        width: '25%',
        useMinimalSpacing: true
    },
    {
        header: 'Enabled',
        width: '10%',
        accessor: 'Enabled'
    },
    {
        header: 'Language',
        width: '10%',
        accessor: 'Language'
    },
    {
        header: 'Text',
        accessor: 'Text',
        width: '55%',
        useMinimalSpacing: true
    }
];

export const renderCardForRowSM = defaultCardRenderer({
    columns: getColumnsSituationalMessages,
    useCellComponents: true
});

export const getColumnsSituationalMessagesChangeLog = [
    {
        header: 'Message Group',
        accessor: 'MessageGroup',
        width: '15%',
        cellComponent: ColumnWithMono,
        useMinimalSpacing: false
    },
    {
        header: 'Message ID & Language',
        accessor: 'TablePartitionKey',
        width: '15%',
        cellComponent: ColumnWithMono,
        useMinimalSpacing: false
    },
    {
        header: 'Updated By',
        width: '10%',
        cellComponent: AvatarCell,
        useMinimalSpacing: false
    },
    {
        header: 'Event Time',
        accessor: 'TrailTimestamp',
        width: '15%',
        cellComponent: ColumnWithTime,
        useMinimalSpacing: false
    },
    {
        header: 'Comment',
        accessor: 'TrailComment',
        width: '20%',
        cellComponent: ColumnWithMono,
        useMinimalSpacing: false
    },
    {
        header: 'Message Content - Changes',
        accessor: 'diffText',
        width: '25%',
        cellComponent: ColumnWithHTML,
        useMinimalSpacing: false
    }
];

export const renderCardForChangelogSM = defaultCardRenderer({
    columns: getColumnsSituationalMessagesChangeLog,
    useCellComponents: true
});

const ExpandableContent = ({ data }: any) => (
  <View padding={{ top: 'S300' }}>
      <Row gridGap='S500' padding='S300'>
          <div style={cssPropertiesIVR}>
              <Text fontWeight='bold' fontSize='T200'>Message Content - Before Update</Text>
              <br />
              <Text font='monospace' fontSize='T100'>
                  Text: {JSON.parse(data.PreviousState).Text}
              </Text>
              <br />
              <Text font='monospace' fontSize='T100'>
                  Enabled: {JSON.stringify(JSON.parse(data.PreviousState).Enabled)}
              </Text>
          </div>
          <Spacer flex={1} />
          <div style={cssPropertiesIVR}>
              <Text fontWeight='bold' fontSize='T200'>Message Content - After Update</Text>
              <br />
              <Text font='monospace' fontSize='T100'>
                  Text: {JSON.parse(data.CurrentState).Text}
              </Text>
              <br />
              <Text font='monospace' fontSize='T100'>
                  Enabled: {JSON.stringify(JSON.parse(data.CurrentState).Enabled)}
              </Text>
          </div>
      </Row>

  </View>
);

export const getRowAttributesCLForSM = () => ({
    expandableContent: ExpandableContent,
    expandableContentHasBackgroundColor: true,
    actionLabel: 'Open to see the versions of Message Text'
});
