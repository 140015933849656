import React from 'react';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { H1, H4, Text } from '@amzn/stencil-react-components/text';
import { PageContainer, PageHeader } from '@amzn/stencil-react-components/page';
import { Col, Spacer, View } from '@amzn/stencil-react-components/layout';
import { LinkCard } from '@amzn/stencil-react-components/card';
import { HR2021Theme } from '@amzn/stencil-react-theme-default';
import CustomBackToTop from '../components/CustomBackToTop';
import CustomFooter from '../components/CustomFooter';
import { Redirect } from 'react-router-dom';
import { PageAllowed } from '../models/FederateData';
import { RouterLinkCard } from './Forbidden';
import { IconWithTooltip } from '../components/CustomHeader';


const SignedOut: React.FC<PageAllowed> = (pageProps) => {

    if (!pageProps.allowance) {
        return <Redirect to='/Error' />;
    }

    RouterLinkCard.defaultProps = {
        ...(LinkCard.defaultProps as any)
    };

    return (
      <StencilProvider theme={{
          ...HR2021Theme
      }}>
          <View height={200}>
              <PageHeader hasShadow={true}>
                  <Spacer flex={1} />
                  <Text margin={[13, 20, 20]} color='#ffffff' fontWeight='bold'>HR Connect Process Support
                      Tool</Text>
                  <IconWithTooltip
                    title='Show tooltip'
                    tooltipText='This is a self-service portal for various HR businesses to update IVR messaging and routing.'
                    color='primary05'
                  />
                  <Spacer flex={1} />
              </PageHeader>
          </View>
          <PageContainer
            alignItems='center'
            centered={true}
            paddingBottom='S700'
            paddingTop='S600'
            paddingHorizontal='S600'>
              <RouterLinkCard justifyContent='center' alignSelf='center' alignItems='flex-start' to='/auth'
                              backgroundColor='primary05'>
                  <Col gridGap={32}>
                      <br />
                      <H1 textAlign='center'>Your session has expired.</H1>
                      <H4 textAlign='center'>Click here to Sign in again</H4>
                      <br />
                  </Col>
              </RouterLinkCard>
              <CustomBackToTop />
          </PageContainer>
          <CustomFooter />
      </StencilProvider>
    );
};

export default SignedOut;
