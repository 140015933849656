import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonVariant,
} from "@amzn/stencil-react-components/button";
import { StencilProvider } from "@amzn/stencil-react-components/context";
import { Expander } from "@amzn/stencil-react-components/expander";
import {
  IconArrowRight,
  IconDownload,
  IconReset,
} from "@amzn/stencil-react-components/icons";
import { Col, Row, Spacer, View } from "@amzn/stencil-react-components/layout";
import { Pagination } from "@amzn/stencil-react-components/pagination";
import { PopupDateRangePicker } from "@amzn/stencil-react-components/date-picker";
import { Spinner } from "@amzn/stencil-react-components/spinner";
import { Table, TableSpacing } from "@amzn/stencil-react-components/table";
import { Text } from "@amzn/stencil-react-components/text";
import {
  TOOLTIP_POSITION,
  TooltipAlignment,
} from "@amzn/stencil-react-components/tooltip";
import { HR2021Theme } from "@amzn/stencil-react-theme-default";
import { ButtonWithTooltip } from "../components/CustomHeader";
import errorHandling from "../components/ErrorHandler";
import { AppConfig } from "../models/AppConfig";
import CustomError from "../models/CustomError";
import SignedOut from "../pages/SignedOut";
import {
  userManagementColumnsChangeLog,
  renderCardForChangelogUserManagement,
} from "../models/UserManagementTableData";
import { cssPropertiesTable } from "../models/TableCustomCSS";
import AuthService from "../services/AuthService";
import { getCredentials } from "../services/aws.service";
import { convertToTimestamp, isBlank } from "../services/FilterServices";
import { AwsClient } from "aws4fetch";
var FileSaver = require("file-saver");

const appConfig: AppConfig = require("../components/config.json")[
  `${process.env.REACT_APP_ENVIRONMENT}`
];

interface AuditTrailProps {
  region: string;
}

const downloadText = (length: number) =>
  `Click here Export the ${length} rows to a CSV file.`;

const authService: AuthService = new AuthService();
const promiseAwsFetchClient: Promise<AwsClient> = getCredentials();

const UserManagementAuditTrails: React.FC<AuditTrailProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedPage, setSelectedPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedChangelogData, setFetchedChangelogData] = useState([]);
  const [filteredValueChangelog, setFilteredValueChangelog] = useState([]);
  const [readyToRedirect, setReadyToRedirect] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  //let totalCount: number = 0;
  let filters: string[] = [];

  let auditFilterURI: string = "";
  let awsFetchClient: AwsClient | undefined;

  useEffect(() => {
    getTableDataForChangelog(false, false, 1, [], startDate, endDate);
  }, []);

  const onResetFilter = () => {
    setFilteredValueChangelog([]);
    filters = [];
  };

  const getChangelogCSV = (
    isLoading: boolean,
    messageGroupArray: string[],
    startDate: string | undefined,
    endDate: string | undefined
  ) => {
    if (isLoading) {
      setIsLoading(true);
    }

    startDate = convertToTimestamp(startDate);
    endDate = convertToTimestamp(endDate);

    const isFiltered: boolean =
      messageGroupArray.length === 0 && isBlank(startDate) && isBlank(endDate);

    auditFilterURI = isBlank(startDate)
      ? isBlank(startDate) && !isBlank(endDate)
        ? "&end_timestamp=" + endDate
        : ""
      : (auditFilterURI = isBlank(endDate)
          ? !isBlank(startDate) && isBlank(endDate)
            ? "&start_timestamp=" + startDate
            : ""
          : "&start_timestamp=" + startDate + "&end_timestamp=" + endDate);

    if (authService.isTokenPresent()) {
      promiseAwsFetchClient
        .then(async (awsClient) => {
          awsFetchClient = awsClient;
          try {
            await awsFetchClient
              .fetch(
                isFiltered
                  ? appConfig.apigUriAudit +
                      `audit_trails?name=CreateUser${props.region}&page=1&is_csv_requested=true`
                  : appConfig.apigUriAudit +
                      `audit_trails?name=CreateUser${props.region}&page=1&is_csv_requested=true&filter_by=` +
                      messageGroupArray.toString() +
                      auditFilterURI,
                {
                  method: "GET",
                  headers: {
                    token: authService.getToken(),
                  },
                }
              )
              .then((promiseResponse) => {
                return promiseResponse.json();
              })
              .then(async (fetchedData) => {
                if (fetchedData.Error !== undefined) {
                  throw new CustomError(fetchedData.Error);
                }
                setIsLoading(false);
                FileSaver.saveAs(
                  new Blob([fetchedData], { type: "text/csv;charset=utf-8" }),
                  `UserManagement${props.region}-` +
                    new Date().toISOString().split("T")[0] +
                    "_" +
                    new Date().toLocaleTimeString("en-GB") +
                    ".csv"
                );
              });
          } catch (error) {
            errorHandling(error);
            setIsLoading(false);
          }
        })
        .catch(async (rejectedPromiseObject) => {
          errorHandling(rejectedPromiseObject);
        });
    } else {
      setReadyToRedirect(true);
    }
  };

  const getTableDataForChangelog = (
    isLoading: boolean,
    isFiltered: boolean,
    pageNumber: number,
    messageGroupArray: string[],
    startDate: string | undefined,
    endDate: string | undefined
  ) => {
    if (isLoading) {
      setIsLoading(true);
    }

    startDate = convertToTimestamp(startDate);
    endDate = convertToTimestamp(endDate);

    auditFilterURI = isBlank(startDate)
      ? isBlank(startDate) && !isBlank(endDate)
        ? "&end_timestamp=" + endDate
        : ""
      : (auditFilterURI = isBlank(endDate)
          ? !isBlank(startDate) && isBlank(endDate)
            ? "&start_timestamp=" + startDate
            : ""
          : "&start_timestamp=" + startDate + "&end_timestamp=" + endDate);

    if (authService.isTokenPresent()) {
      promiseAwsFetchClient
        .then(async (awsClient) => {
          awsFetchClient = awsClient;
          try {
            await awsFetchClient
              .fetch(
                !isFiltered
                  ? appConfig.apigUriAudit +
                      `audit_trails?name=CreateUser${props.region}&page=` +
                      pageNumber
                  : appConfig.apigUriAudit +
                      `audit_trails?name=CreateUser${props.region}&filter_by=` +
                      messageGroupArray.toString() +
                      "&page=" +
                      pageNumber +
                      auditFilterURI,
                {
                  method: "GET",
                  headers: {
                    token: authService.getToken(),
                  },
                }
              )
              .then((promiseResponse) => {
                return promiseResponse.json();
              })
              .then(async (fetchedData) => {
                if (fetchedData.Error !== undefined) {
                  throw new CustomError(fetchedData.Error);
                }

                if (pageNumber === 1) {
                  //totalCount = fetchedData.count;
                  setTotalCount(fetchedData.count);
                  fetchedData = fetchedData.results;
                }

                fetchedData = await fetchedData.map((item: any) => {
                  let transformedItem = item;
                  transformedItem["CurrentState"] = JSON.parse(
                    item.CurrentState
                  );

                  return transformedItem;
                });

                setFetchedChangelogData(fetchedData);
                setIsLoading(false);
              });
          } catch (error) {
            errorHandling(error);
            setIsLoading(false);
          }
        })
        .catch(async (rejectedPromiseObject) => {
          errorHandling(rejectedPromiseObject);
        });
    } else {
      setReadyToRedirect(true);
    }
  };

  const getSecurityProfiles = (securityProfiles: any) => {
    let securityProfileList: string = "";

    if (securityProfiles.length > 1) {
      for (let i = 0; i < securityProfiles.length; i++) {
        if (i === 0) {
          securityProfileList = securityProfiles[i];
        } else {
          securityProfileList += ", " + securityProfiles[i];
        }
      }
    } else {
      return securityProfiles[0];
    }

    return securityProfileList;
  };

  const ExpandableContent = ({ data, index }: { data: any; index: any }) => (
    <>
      <View backgroundColor="primary10" padding="S200">
        <ul>
          <li>
            <Text fontSize="T100">
              Name: {data.CurrentState.IdentityInfo.FirstName}&nbsp;
              {data.CurrentState.IdentityInfo.LastName}
            </Text>
          </li>
          <li>
            <Text fontSize="T100">
              Username: {data.CurrentState.IdentityInfo.Email}
            </Text>
          </li>
          <li>
            <Text fontSize="T100">
              Security Profiles:{" "}
              {data.CurrentState.SecurityProfileIds}
            </Text>
          </li>
          <li>
            <Text fontSize="T100">
              Routing Profile: {data.CurrentState.RoutingProfileId}
            </Text>
          </li>
          <li>
            <Text fontSize="T100">
              Agent Hierarchy: {data.CurrentState.HierarchyGroupId}
            </Text>
          </li>
          <li>
            <Text fontSize="T100">Auto-accept: false</Text>
          </li>
          <li>
            <Text fontSize="T100">ACW: 300</Text>
          </li>
        </ul>
      </View>
    </>
  );

  const getRowAttributes = ({ data }: any) => ({
    actionLabel: "Details",
    disabled: !data.CurrentState,
    expandableContent: ExpandableContent,
  });

  if (readyToRedirect) {
    return <SignedOut allowance={true} />;
  }

  return (
    <>
      <Expander
        isExpanded={isExpanded}
        onToggle={() => setIsExpanded(!isExpanded)}
        titleText="Click to open Filters"
      >
        <View
          backgroundColor="neutral0"
          alignItems="flex-start"
          padding="S200"
          flexWrap="overflow"
        >
          <Row
            gridGap="S700"
            justifyContent="flex-start"
            flexWrap="wrap"
            padding="S200"
            width="100%"
          >
            <Col
              gridGap="S300"
              alignItems="flex-start"
              padding="S200"
              width="100%"
            >
              <Row gridGap="S300" width="100%">
                <Text id="smsType-cl3-selector-label">
                  Choose Date Range to filter:
                </Text>
                <Spacer flex={2} />
                <Button
                  variant={ButtonVariant.Primary}
                  type="button"
                  aria-describedby="smsType-cl1-selector-label"
                  icon={<IconArrowRight title="Apply Filter" />}
                  iconPosition={ButtonIconPosition.Trailing}
                  onClick={() => {
                    filters = filteredValueChangelog;
                    getTableDataForChangelog(
                      true,
                      true,
                      1,
                      filters,
                      startDate,
                      endDate
                    );
                    setSelectedPage(1);
                  }}
                >
                  Apply Filter &nbsp;
                </Button>
                <Button
                  variant={ButtonVariant.Primary}
                  type="button"
                  icon={<IconReset title="Reset Filter" />}
                  iconPosition={ButtonIconPosition.Trailing}
                  onClick={() => {
                    onResetFilter();
                    setSelectedPage(1);
                    setStartDate("");
                    setEndDate("");
                    getTableDataForChangelog(
                      true,
                      false,
                      1,
                      [],
                      startDate,
                      endDate
                    );
                  }}
                >
                  Reset Filters &nbsp;
                </Button>
              </Row>

              <View>
                <PopupDateRangePicker
                  ariaLabelsForSelectedDate={{
                    endDate: "You have selected an end date",
                    startDate: "You have selected a start date",
                  }}
                  id="filter-date-picker"
                  name="choose-dates"
                  shouldFocusOnStartDate={true}
                  locale="iso8601"
                  startDateLabelText="Start date (YYYY-MM-DD)"
                  endDateLabelText="End date (YYYY-MM-DD)"
                  startValue={startDate as unknown as string}
                  endValue={endDate as unknown as string}
                  onChange={(startDate, endDate) => {
                    if (startDate) setStartDate(startDate + " 00:00:00");
                    else setStartDate("");
                    if (endDate) setEndDate(endDate + " 23:59:59");
                    else setEndDate("");
                  }}
                />
              </View>
            </Col>
          </Row>

          <Row
            gridGap="S700"
            padding="S200"
            justifyContent="center"
            flexWrap="wrap"
          ></Row>
        </View>
      </Expander>
      <br />
      <Col
        backgroundColor="primary05"
        alignItems="center"
        justifyContent="center"
      >
        {isLoading && (
          <StencilProvider
            theme={{
              ...HR2021Theme,
            }}
          >
            <br />
            <Col gridGap={35} aria-live="assertive" aria-busy={isLoading}>
              <Spinner
                size={70}
                strokeWidth={9}
                showText
                textColor="neutral100"
                aria-live="assertive"
                aria-busy={isLoading}
              />
            </Col>
            <br />
          </StencilProvider>
        )}
        <br />
      </Col>
      <Row
        gridGap="S500"
        display="flex"
        alignItems="flex-end"
        justifyContent="flex-end"
        padding="S300"
      >
        <Text
          margin={{ bottom: 7 }}
          fontWeight="bold"
          textAlign="right"
          fontSize="T200"
        >
          Total no. of Rows: {totalCount}
        </Text>
        {fetchedChangelogData.length !== 0 && (
          <ButtonWithTooltip
            role="tooltip"
            aria-describedby="Click here to Export the changelogs to CSV."
            icon={<IconDownload title="Export to CSV" />}
            title={downloadText(totalCount)}
            variant={ButtonVariant.Secondary}
            onClick={() => {
              getChangelogCSV(true, filters, startDate, endDate);
            }}
            tooltipAlignment={TooltipAlignment.Left}
            tooltipPosition={TOOLTIP_POSITION.BOTTOM}
            size={ButtonSize.Small}
            tooltipText={downloadText(totalCount)}
          >
            Download Changelog
          </ButtonWithTooltip>
        )}
        <Spacer flex={0.2} />
        {fetchedChangelogData.length !== 0 && (
          <Pagination
            aria-label="pagination"
            numberOfPages={Math.ceil(totalCount / 10)}
            selectedPage={selectedPage}
            onPageSelect={(value) => {
              setSelectedPage(value);
              getTableDataForChangelog(
                true,
                true,
                value,
                filters,
                startDate,
                endDate
              );
            }}
          />
        )}
      </Row>
      <br />
      <div style={cssPropertiesTable}>
        <Table
          aria-labelledby="smsType-cl1-selector-label"
          actionHeader={"Details"}
          renderCardForRow={renderCardForChangelogUserManagement}
          getRowAttributes={getRowAttributes}
          columns={userManagementColumnsChangeLog}
          data={fetchedChangelogData}
          spacing={TableSpacing.Reduced}
          shouldAutoCollapseOnExpand={true}
          shouldScrollHorizontally={true}
          isStriped={true}
        />
      </div>
      <br />
    </>
  );
};

export default UserManagementAuditTrails;
