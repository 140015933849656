import { StencilProvider } from '@amzn/stencil-react-components/context';
import React from 'react';
import { HR2021Theme } from '@amzn/stencil-react-theme-default';
import { MessageBanner, MessageBannerLink, MessageBannerType } from '@amzn/stencil-react-components/message-banner';


export const CustomBanner = () => {
    return (
      <StencilProvider theme={{
          ...HR2021Theme
      }}>
          <MessageBanner type={MessageBannerType.Error} title='Permission Denied'
                         aria-live='assertive'>
              If you think this isn't right :{' '}
              <MessageBannerLink target='_blank' href='https://tt.amazon.com/quicklink/Q001223081'> contact
                  us.</MessageBannerLink>{' '}
          </MessageBanner>
          <br /><br />
      </StencilProvider>
    );
};


export const CustomErrorBanner = () => {
    return (
      <StencilProvider theme={{
          ...HR2021Theme
      }}>
          <MessageBanner type={MessageBannerType.Warning} title='Caution: '
                         aria-live='assertive' iconAltText='Delete Disclaimer'>
              Once you click Delete Entry button, this record will be deleted forever. Comments are mandatory for Deletion.
          </MessageBanner>
          <br />
      </StencilProvider>
    );
};

export default CustomErrorBanner;

export const CustomWarningBanner = () => {
    return (
      <StencilProvider theme={{
          ...HR2021Theme
      }}>
          <MessageBanner type={MessageBannerType.Warning} title='Disclaimer: '
                         aria-live='assertive' iconAltText='Add Disclaimer'>
              The Text template and the Message to be entered should be legally approved. Comments is mandatory for Addition.
          </MessageBanner>
          <br />
      </StencilProvider>
    );
};
