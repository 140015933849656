class CustomError extends Error {
    constructor(public message: string) {
        super(message);
        this.name = "VALIDATION_ERROR";
        this.message = message
        this.stack = (new Error()).stack;
    }
}

export default CustomError
