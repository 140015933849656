import { Text } from '@amzn/stencil-react-components/text';
import { cssProperties, cssPropertiesIVR } from './SelfServiceCustomCSS';
import { PersonalDetailsAvatar } from '@amzn/stencil-react-components/avatar';
import { Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { defaultCardRenderer } from '@amzn/stencil-react-components/table';
import React from 'react';

export const getColumnsManual = [
    {
        header: 'Business Group',
        accessor: 'BusinessGroup',
        width: '10%'
    },
    {
        header: 'Template Name',
        accessor: 'TemplateName',
        width: '20%'
    },
    {
        header: 'Message',
        accessor: 'Message',
        width: '70%'
    }
];

export const renderCardForRowManual = defaultCardRenderer({ columns: getColumnsManual, useCellComponents: true });

export const ColumnWithMono = ({ data }: any) => (
  <Text font='monospace' fontSize='T100'>
      {data}
  </Text>
);

export const getColumnsIVR = [
    {
        header: 'SMS Type',
        accessor: 'smsType',
        cellComponent: ColumnWithMono,
        width: '10%'
    },
    {
        header: 'SMS Body',
        accessor: 'smsBody',
        cellComponent: ColumnWithMono,
        width: '90%'
    }
];

export const ColumnWithTime = ({ data }: any) => (
  <Text font='monospace' fontSize='T100'>
      {new Date(data * 1000).toDateString() + ' ' + new Date(data * 1000).toLocaleTimeString('en-US')}
  </Text>
);

export const ColumnWithHTML = ({ data }: any) => (
  <div
    dangerouslySetInnerHTML={{
        __html: `
    <style>
        .screen-reader {
        position: absolute !important;
        height: 1px; 
        width: 1px;
        overflow: auto;
        clip: rect(1px, 1px, 1px, 1px);
      }
    </style>
   ` + data
    }}
    style={cssProperties}>
  </div>
);

export const AvatarCell = ({ data }: any) => (
  <PersonalDetailsAvatar
    size={'m'}
    padding={{ top: '20px', bottom: '20px', right: '10px', left: '5px' }}
    username={data.Alias}
  />
);

export const getColumnsChangelog = [
    {
        header: 'SMS Type', accessor: 'TablePartitionKey',
        width: '10%',
        cellComponent: ColumnWithMono,
        useMinimalSpacing: true
    },
    {
        header: 'Updated By',
        width: '15%',
        cellComponent: AvatarCell,
        useMinimalSpacing: true
    },
    {
        header: 'Event Time',
        accessor: 'TrailTimestamp',
        width: '25%',
        cellComponent: ColumnWithTime,
        useMinimalSpacing: true
    },
    {
        header: 'Comment',
        accessor: 'TrailComment',
        cellComponent: ColumnWithMono,
        width: '25%',
        useMinimalSpacing: true
    },
    {
        header: 'SMS Body - Changes',
        accessor: 'diffText',
        width: '25%',
        cellComponent: ColumnWithHTML,
        useMinimalSpacing: true
    }
];

export const getColumnsChangelogMSS = [
    {
        header: 'Business Group',
        accessor: 'BusinessGroup',
        width: '5%',
        cellComponent: ColumnWithMono,
        useMinimalSpacing: true
    },
    {
        header: 'Template Name',
        accessor: 'TablePartitionKey',
        width: '10%',
        cellComponent: ColumnWithMono,
        useMinimalSpacing: true
    },
    {
        header: 'Action',
        accessor: 'OperationType',
        width: '5%',
        cellComponent: ColumnWithMono,
        useMinimalSpacing: true
    },
    {
        header: 'Updated By',
        width: '15%',
        cellComponent: AvatarCell,
        useMinimalSpacing: true
    },
    {
        header: 'Event Time',
        accessor: 'TrailTimestamp',
        width: '20%',
        cellComponent: ColumnWithTime,
        useMinimalSpacing: true
    },
    {
        header: 'Comment',
        accessor: 'TrailComment',
        width: '20%',
        cellComponent: ColumnWithMono,
        useMinimalSpacing: true
    },
    {
        header: 'Message - Changes',
        accessor: 'diffText',
        width: '25%',
        cellComponent: ColumnWithHTML,
        useMinimalSpacing: true
    }
];

export const ExpandableContent = ({ data }: any) => (
  <View padding={{ top: 'S300' }}>
      <Row gridGap='S500' padding='S300'>
          <div style={cssPropertiesIVR}>
              <Text fontWeight='bold' fontSize='T200'>Message Body - Before Update</Text>
              <Text font='monospace' fontSize='T100'>
                  {data.PreviousState}
              </Text>
          </div>
          <Spacer flex={1} />
          <div style={cssPropertiesIVR}>
              <Text fontWeight='bold' fontSize='T200'>Message Body - After Update</Text>
              <Text font='monospace' fontSize='T100'>
                  {data.CurrentState}
              </Text>
          </div>
      </Row>

  </View>
);

export const getRowAttributesCL = () => ({
    expandableContent: ExpandableContent,
    expandableContentHasBackgroundColor: true,
    actionLabel: 'Open to see the versions of Text Bodies'
});

export const renderCardForRowSS = defaultCardRenderer({ columns: getColumnsIVR, useCellComponents: true });
