import { Col, Row, Spacer } from "@amzn/stencil-react-components/layout";
import {
  IconHome,
  IconQuestionCircle,
  IconRefresh,
  SVGComponentProps,
} from "@amzn/stencil-react-components/icons";
import { StencilProvider } from "@amzn/stencil-react-components/context";
import React from "react";
import { HR2021Theme } from "@amzn/stencil-react-theme-default";
import {
  TOOLTIP_POSITION,
  TooltipAlignment,
  withTooltip,
} from "@amzn/stencil-react-components/tooltip";
import { PageProps } from "../models/FederateData";
import { containsLdapElement, LDAP } from "../models/LDAP";
import AuthService from "../services/AuthService";
import { Redirect } from "react-router-dom";
import {
  BreakpointPropsMap,
  useBreakpoints,
} from "@amzn/stencil-react-components/responsive";
import {
  PageHeader,
  PageHeaderDropdown,
  PageHeaderDropdownLink,
  PageHeaderLink,
} from "@amzn/stencil-react-components/page";

import {
  Button,
  ButtonProps,
  ButtonVariant,
} from "@amzn/stencil-react-components/button";
import AuthRedirect from "./AuthRedirect";
import styled from "styled-components";

interface MappedProps {
  s: boolean;
  m: boolean;
}

const propsMap: BreakpointPropsMap<MappedProps> = {
  s: { s: true, m: false },
  m: { s: false, m: true },
  default: { s: false, m: false },
};

const CustomUl = styled("ul")({
  listStyleType: "none",
  margin: 0,
  padding: 0,
  display: "flex",
});

const CustomLi = styled("li")({
  display: "flex",
});

const authService: AuthService = new AuthService();
export const IconWithTooltip =
  withTooltip<SVGComponentProps>()(IconQuestionCircle);
export const ButtonWithTooltip = withTooltip<ButtonProps>({
  useTriggerWrapper: false,
})(Button);

const getUserManagementItems = (LDAP_GROUP: Array<string>) => {
  let items = [];

  if (containsLdapElement(LDAP_GROUP, LDAP[7].toString())) {
    items.push(
      <PageHeaderDropdownLink color="primary70" href="/UserManagementAMER">
        User Management AMER
      </PageHeaderDropdownLink>
    );
  }
  if (containsLdapElement(LDAP_GROUP, LDAP[8].toString())) {
    items.push(
      <PageHeaderDropdownLink color="primary70" href="/UserManagementAPAC">
        User Management APAC
      </PageHeaderDropdownLink>
    );
  }
  if (containsLdapElement(LDAP_GROUP, LDAP[9].toString())) {
    items.push(
      <PageHeaderDropdownLink color="primary70" href="/UserManagementEMEA">
        User Management EMEA
      </PageHeaderDropdownLink>
    );
  }

  return items;
};

const getSituationalMessagesItems = (LDAP_GROUP: Array<string>) => {
  let items = [];

  if (containsLdapElement(LDAP_GROUP, LDAP[0].toString())) {
    items.push(
      <PageHeaderDropdownLink color="primary70" href="/SituationalMessagesAMER">
        Situational Messages AMER
      </PageHeaderDropdownLink>
    );
  }
  if (containsLdapElement(LDAP_GROUP, LDAP[1].toString())) {
    items.push(
      <PageHeaderDropdownLink color="primary70" href="/SituationalMessagesEMEA">
        Situational Messages EMEA
      </PageHeaderDropdownLink>
    );
  }
  if (containsLdapElement(LDAP_GROUP, LDAP[2].toString())) {
    items.push(
      <PageHeaderDropdownLink color="primary70" href="/SituationalMessagesAPAC">
        Situational Messages APAC
      </PageHeaderDropdownLink>
    );
  }

  return items;
};

const CustomHeader: React.FC<PageProps> = (pageProps) => {
  const { props } = useBreakpoints({ propsMap });

  if (authService.isUserAuthenticated()) return <Redirect to="/auth" />;

  let LDAP_GROUP: Array<string> = [];

  if (authService.isTokenPresent() && pageProps.federateToken !== null) {
    LDAP_GROUP = pageProps.federateToken.LDAPGROUP;
  }

  function refresh() {
    authService.clearStorage();
    window.location.href = "/auth";
    return <AuthRedirect />;
  }

  return (
    <StencilProvider
      theme={{
        ...HR2021Theme,
      }}
    >
      <PageHeader
        fixed={false}
        hasShadow={true}
        fullWidth={true}
        aria-label="main"
      >
        <Row
          gridGap={!props.s ? "S200" : "S100"}
          backgroundColor="neutral90"
          justifyContent="space-between"
          width="100%"
        >
          <PageHeaderLink hasHover={false} paddingHorizontal={-10}>
            HR Connect Process Support Tool
          </PageHeaderLink>
          <Spacer flex={1} />
          {!props.s && !props.m ? (
            <>
              <Row role="navigation" aria-label="main">
                <nav style={{ display: "flex" }}>
                  <CustomUl>
                    <CustomLi>
                      {(containsLdapElement(LDAP_GROUP, LDAP[7].toString()) ||
                        containsLdapElement(LDAP_GROUP, LDAP[8].toString()) ||
                        containsLdapElement(
                          LDAP_GROUP,
                          LDAP[9].toString()
                        )) && (
                        <PageHeaderDropdown
                          items={getUserManagementItems(LDAP_GROUP)}
                        >
                          User Management
                        </PageHeaderDropdown>
                      )}
                    </CustomLi>
                    <CustomLi>
                      {(containsLdapElement(LDAP_GROUP, LDAP[0].toString()) ||
                        containsLdapElement(LDAP_GROUP, LDAP[1].toString()) ||
                        containsLdapElement(
                          LDAP_GROUP,
                          LDAP[2].toString()
                        )) && (
                        <PageHeaderDropdown
                          items={getSituationalMessagesItems(LDAP_GROUP)}
                        >
                          Situational Messages
                        </PageHeaderDropdown>
                      )}
                    </CustomLi>
                    <CustomLi>
                      {containsLdapElement(LDAP_GROUP, LDAP[3].toString()) && (
                        <PageHeaderLink href="/VariableStaffingAMER">
                          Variable Staffing
                        </PageHeaderLink>
                      )}
                    </CustomLi>
                    <CustomLi>
                      {containsLdapElement(LDAP_GROUP, LDAP[4].toString()) && (
                        <PageHeaderLink href="/IVRTextSelfService">
                          IVR Text Self-Service
                        </PageHeaderLink>
                      )}
                    </CustomLi>
                    <CustomLi>
                      {containsLdapElement(LDAP_GROUP, LDAP[5].toString()) && (
                        <PageHeaderLink href="/ManualTextSelfService">
                          Manual Text Self-Service
                        </PageHeaderLink>
                      )}
                    </CustomLi>
                    <CustomLi>
                      {containsLdapElement(LDAP_GROUP, LDAP[6].toString()) && (
                        <PageHeaderLink href="/SMSTextSelfService">
                          ES Tech SMS Text Service
                        </PageHeaderLink>
                      )}
                    </CustomLi>
                  </CustomUl>
                </nav>
              </Row>
              <Spacer flex={1} />

              <Row gridGap="S300" padding="S200">
                <Col backgroundColor="black">
                  <ButtonWithTooltip
                    role="tooltip"
                    aria-describedby="Click here to navigate to Home."
                    icon={<IconHome color="neutral05" title="Home" />}
                    title="Home"
                    variant={ButtonVariant.Tertiary}
                    onClick={() => (window.location.href = "/")}
                    tooltipAlignment={TooltipAlignment.Right}
                    tooltipPosition={TOOLTIP_POSITION.BOTTOM}
                    tooltipText="Click here to navigate to Home."
                  />
                </Col>
                <Col>
                  <ButtonWithTooltip
                    role="tooltip"
                    aria-describedby="Click here when your LDAP permissions are modified."
                    icon={
                      <IconRefresh
                        color="neutral05"
                        title="Refresh will extend your current session"
                      />
                    }
                    title="Refresh will extend your current session"
                    variant={ButtonVariant.Tertiary}
                    onClick={() => {
                      authService.clearStorage();
                      refresh();
                    }}
                    tooltipAlignment={TooltipAlignment.Left}
                    tooltipPosition={TOOLTIP_POSITION.BOTTOM}
                    tooltipText="Click here when your LDAP permissions are modified."
                  />
                </Col>
                <IconWithTooltip
                  title="Show tooltip"
                  tooltipText="This is a self-service portal for various HR businesses to update IVR messaging and routing."
                  color="primary05"
                />
              </Row>
            </>
          ) : (
            <>
              <div role="navigation" aria-label="main">
                <PageHeaderDropdown
                  hasHover={true}
                  title="Menu"
                  paddingHorizontal={0}
                  items={[
                    containsLdapElement(LDAP_GROUP, LDAP[7].toString()) && (
                      <PageHeaderDropdownLink href="/UserManagementAMER">
                        User Management Americas
                      </PageHeaderDropdownLink>
                    ),

                    containsLdapElement(LDAP_GROUP, LDAP[8].toString()) && (
                      <PageHeaderDropdownLink href="/UserManagementAPAC">
                        User Management Asia Pacific
                      </PageHeaderDropdownLink>
                    ),

                    containsLdapElement(LDAP_GROUP, LDAP[9].toString()) && (
                      <PageHeaderDropdownLink href="/UserManagementEMEA">
                        User Management Europe Middle East
                      </PageHeaderDropdownLink>
                    ),

                    containsLdapElement(LDAP_GROUP, LDAP[0].toString()) && (
                      <PageHeaderDropdownLink href="/SituationalMessagesAMER">
                        Situational Messages Americas
                      </PageHeaderDropdownLink>
                    ),

                    containsLdapElement(LDAP_GROUP, LDAP[1].toString()) && (
                      <PageHeaderDropdownLink href="/SituationalMessagesEMEA">
                        Situational Messages EMEA
                      </PageHeaderDropdownLink>
                    ),

                    containsLdapElement(LDAP_GROUP, LDAP[2].toString()) && (
                      <PageHeaderDropdownLink href="/SituationalMessagesAPAC">
                        Situational Messages APAC
                      </PageHeaderDropdownLink>
                    ),

                    containsLdapElement(LDAP_GROUP, LDAP[3].toString()) && (
                      <PageHeaderDropdownLink href="/VariableStaffingAMER">
                        Variable Staffing
                      </PageHeaderDropdownLink>
                    ),

                    containsLdapElement(LDAP_GROUP, LDAP[4].toString()) && (
                      <PageHeaderDropdownLink href="/IVRTextSelfService">
                        IVR Text Self-Service
                      </PageHeaderDropdownLink>
                    ),

                    containsLdapElement(LDAP_GROUP, LDAP[5].toString()) && (
                      <PageHeaderDropdownLink href="/ManualTextSelfService">
                        Manual Text Self-Service
                      </PageHeaderDropdownLink>
                    ),

                    containsLdapElement(LDAP_GROUP, LDAP[6].toString()) && (
                      <PageHeaderDropdownLink href="/SMSTextSelfService">
                        ES Tech SMS Text Service
                      </PageHeaderDropdownLink>
                    ),
                  ]}
                />
              </div>
              <Spacer flex={0.25} />
              <Row gridGap="S200" padding="S200">
                <Col backgroundColor="black">
                  <ButtonWithTooltip
                    role="tooltip"
                    aria-describedby="Click here to navigate to Home."
                    icon={<IconHome color="neutral05" title="Home" />}
                    title="Home"
                    variant={ButtonVariant.Tertiary}
                    onClick={() => (window.location.href = "/")}
                    tooltipAlignment={TooltipAlignment.Right}
                    tooltipPosition={TOOLTIP_POSITION.BOTTOM}
                    tooltipText="Click here to navigate to Home."
                  />
                </Col>
                <Col>
                  <ButtonWithTooltip
                    role="tooltip"
                    aria-describedby="Click here when your LDAP permissions are modified."
                    icon={
                      <IconRefresh
                        color="neutral05"
                        title="Refresh will extend your current session"
                      />
                    }
                    title="Refresh will extend your current session"
                    variant={ButtonVariant.Tertiary}
                    onClick={() => {
                      authService.clearStorage();
                      refresh();
                    }}
                    tooltipAlignment={TooltipAlignment.Left}
                    tooltipPosition={TOOLTIP_POSITION.BOTTOM}
                    tooltipText="Click here when your LDAP permissions are modified."
                  />
                </Col>
              </Row>
            </>
          )}
        </Row>
      </PageHeader>
    </StencilProvider>
  );
};

export default CustomHeader;
