export enum LDAP {
    SM_AMER,
    SM_EMEA,
    SM_APAC,
    VS,
    IVR_SMS_SELF_SERVICE,
    MANUAL_TEXT,
    MANUAL_SMS,
    UM_AMER_ADMIN,
    UM_APAC_ADMIN,
    UM_EMEA_ADMIN
}

export function containsLdapElement(federateLDAPArray: string[], ldapElement: string): boolean {
    for (const ldapElements of federateLDAPArray)
        if (ldapElements.startsWith(ldapElement, 0))
            return true;
    return false;
}

export function containsLdapElementForViewOnly(federateLDAPArray: string[], ldapElement: string): boolean {
    let result = false;
    for (const ldapElements of federateLDAPArray)
        if (ldapElements.startsWith(ldapElement, 0))
            if (ldapElements.includes((ldapElement + '_VIEW')))
                result = true;

    for (const ldapElements of federateLDAPArray)
        if (ldapElements.startsWith(ldapElement, 0))
            if (ldapElements.includes((ldapElement + '_ADMIN')))
                result = false;

    return result;
}

export function suggestionStatement(ldapArray: string[],
                                    isReadOnly: boolean, index: number): string {
    const businessGroups: string[] = [];
    const ldapElement = LDAP[index];

    for (const ldapElements of ldapArray)
        if (ldapElements.startsWith(ldapElement, 0))
            businessGroups.push(ldapElements.slice(ldapElement.length + 1, ldapElements.length));

    if (businessGroups.includes('VIEW') || businessGroups.includes('ADMIN'))
        return 'You are accessing this page as ' + (isReadOnly ? 'a Read-Only' : 'an Admin') + ' user.';
    else
        return 'You have access to ' + businessGroups + ' business group' + (businessGroups.length > 1 ? 's.' : '.');
}
