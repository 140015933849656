import React from "react";
import {AppConfig} from "../models/AppConfig";


const appConfig: AppConfig = require('./config.json')[
    `${process.env.REACT_APP_ENVIRONMENT}`
    ];


export default class AuthRedirect extends React.Component {
    /**
     * Redirects browser to Midway for authentication
     */
    componentDidMount() {
        window.location.replace(`${appConfig.authUri}`);
    }

    render() {
        return <div/>;
    }
}
