import { STS } from "aws-sdk";
import { AwsClient } from "aws4fetch";
import { FederateData } from "../models/FederateData";
import jwt_decode from "jwt-decode";
import AuthService from "./AuthService";

const authService: AuthService = new AuthService();

export function getCredentials(): Promise<AwsClient> {
  return new Promise((resolve, reject) => {
    const idToken = authService.getToken();
    const decodedToken: FederateData = jwt_decode(idToken);
    let awsFetchClient: AwsClient | undefined;
    const params = {
      RoleArn: `arn:aws:iam::152353010928:role/hrcps-role-to-assume-with-web-identity-${process.env.REACT_APP_ENVIRONMENT}`,
      WebIdentityToken: idToken,
      RoleSessionName: decodedToken.EMAIL,
    };
    new STS().assumeRoleWithWebIdentity(
      params,
      async (error: any | null | undefined, data: any | null | undefined) => {
        if (error) {
          // Failed response
          console.group("Error assuming role with web identity:");
          console.error(error);
          console.groupEnd();
          if (error.message === "Request aborted") reject(error);
          else window.location.href = "/SignedOut";
        } else {
          // Successful response
          const credentials = {
            accessKeyId: data.Credentials.AccessKeyId,
            secretAccessKey: data.Credentials.SecretAccessKey,
            sessionToken: data.Credentials.SessionToken,
          };
          awsFetchClient = new AwsClient({
            accessKeyId: credentials.accessKeyId,
            secretAccessKey: credentials.secretAccessKey,
            sessionToken: credentials.sessionToken,
            region: "us-east-1",
            retries: 2,
            initRetryMs: 40,
          });
          resolve(awsFetchClient as AwsClient);
        }
      }
    );
  });
}
