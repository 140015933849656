import { Redirect } from 'react-router-dom';

export default function errorHandling(error: any) {
    console.group('Could not get api data: ');
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.info('Error Response => \n');
        console.error('\tData: ', error.response.data);
        console.error('\tStatus: ', error.response.status);
        console.error('\tHeaders: ', error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        console.info('Error Request => \n');
        console.error(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.info('Error Message => \n');
        console.error('Error', error.message);
        if (error.message === 'Token validation failed: Signature has expired') {
            return <Redirect to='/SignedOut' />;
        }
    }
    console.error(error.config);
    console.groupEnd();
}
