import React from 'react';

export const cssProperties = {
    width: '400px',
    overflowWrap: 'break-word',
    msOverflowWrap: 'break-word',
    overflowX: 'auto',
    msOverflowX: 'auto'
} as React.CSSProperties;

export const cssPropertiesVS = {
    width: '500px',
    height: '65px',
    overflowWrap: 'break-word',
    msOverflowWrap: 'break-word',
    overflowX: 'auto',
    msOverflowX: 'auto'
} as React.CSSProperties;

export const cssPropertiesIVR = {
    width: '600px',
    overflow: 'auto',
    msOverflow: 'auto'
} as React.CSSProperties;
