import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './components/ForReference';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import SMAMER from './pages/SituationalMessagesAMER';
import SMEMEA from './pages/SituationalMessagesEMEA';
import SMAPAC from './pages/SituationalMessagesAPAC';
import VSAMER from './pages/VariableStaffing';
import Error from './pages/Error';
import Forbidden from './pages/Forbidden';
import AuthRedirect from './components/AuthRedirect';
import jwt_decode from 'jwt-decode';
import AuthService from './services/AuthService';
import SignedOut from './pages/SignedOut';
import { FederateData } from './models/FederateData';
import IVRTSS from './pages/IVRTextSelfService';
import MTSS from './pages/ManualTextSelfService';
import STSS from './pages/SMSTextSelfService';
import UMAMER from './pages/UserManagementAMER'
import UMEMEA from './pages/UserManagementEMEA';
import UMAPAC from './pages/UserManagementAPAC';


const queryString = require('query-string');
const authService: AuthService = new AuthService();

/**
 * Get Authorization
 * Extract Authorization token from URL
 */
export default function getAuth(path: string) {
    const idToken = authService.getToken();

    if (idToken === null || idToken === undefined || idToken === 'undefined') {
        const location = window.location;

        if (location.search !== '') {
            const queryParams = queryString.parse(location.search);
            if (queryParams.id_token) {
                authService.setAuthenticated(true);
                authService.setToken(queryParams.id_token);
            } else {
                return <Redirect to={{ pathname: '/auth' }} />;
            }
        } else {
            return <Redirect to={{ pathname: '/auth' }} />;
        }
    } else {
        //Check exp date of token
        const decodedToken: FederateData = jwt_decode(idToken);
        const currentTime = Date.now() * 0.001;

        if (currentTime > decodedToken.exp) {
            authService.setAuthenticated(false);
            authService.clearToken();
            return <Redirect to={{ pathname: '/auth' }} />;
        }
        authService.setAuthenticated(true);
    }

    if (authService.isUserAuthenticated()) {
        return <Redirect to={path} />;
    } else {
        return <Redirect to={{ pathname: '/auth' }} />;
    }
}

/**
 * Use Federate to populate data here
 */
ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter>
          <Switch>
              <Route exact path='/auth' render={() => <AuthRedirect />} />

              <Route exact path='/UserManagementAMER'>
                  <UMAMER federateToken={authService.getDecodedToken()}/>
              </Route>

              <Route exact path='/UserManagementAPAC'>
                  <UMAPAC federateToken={authService.getDecodedToken()}/>
              </Route>

              <Route exact path='/UserManagementEMEA'>
                  <UMEMEA federateToken={authService.getDecodedToken()}/>
              </Route>

              <Route exact path='/SituationalMessagesAMER'>
                  <SMAMER federateToken={authService.getDecodedToken()} />
              </Route>

              <Route exact path='/SituationalMessagesEMEA'>
                  <SMEMEA federateToken={authService.getDecodedToken()} />
              </Route>

              <Route exact path='/SituationalMessagesAPAC'>
                  <SMAPAC federateToken={authService.getDecodedToken()} />
              </Route>

              <Route exact path='/VariableStaffingAMER'>
                  <VSAMER federateToken={authService.getDecodedToken()} />
              </Route>

              <Route exact path='/IVRTextSelfService'>
                  <IVRTSS federateToken={authService.getDecodedToken()} />
              </Route>

              <Route exact path='/ManualTextSelfService'>
                  <MTSS federateToken={authService.getDecodedToken()} />
              </Route>

              <Route exact path='/SMSTextSelfService'>
                  <STSS federateToken={authService.getDecodedToken()}/>
              </Route>

              <Route exact path='/Forbidden'>
                  <Forbidden federateToken={authService.getDecodedToken()} allowance={false} />
              </Route>



              <Route exact path='/SignedOut'>
                  <SignedOut allowance={false} />
              </Route>

              <Route exact path='/'>
                  {getAuth('/')}
                  <App federateToken={authService.getDecodedToken()} userType='admin' username='any_name' />
              </Route>

              <Route path='/'>
                  <Error federateToken={authService.getDecodedToken()} />
              </Route>

          </Switch>
      </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// eslint-disable-next-line no-lone-blocks
{
    /*
      <Route
      exact path='/userlist'
      render={(props) => <UserList {...props} />
      </Route>
    */
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
serviceWorker.unregister();
