import jwtDecode from 'jwt-decode';

export default class AuthService {
    authenticated: boolean;
    token: string;

    constructor() {
        this.authenticated = false;
        this.token = '';
    }

    isUserAuthenticated(): boolean {
        return this.authenticated;
    }

    isTokenPresent(): boolean{
        return !!(localStorage.getItem('idToken'));
    }

    setAuthenticated(status: boolean): void {
        this.authenticated = status;
    }

    setToken(token: string): void {
        //console.log('[HR Connect Support Tool Auth] Setting token');
        localStorage.setItem('idToken', token);
    }

    getToken(): any {
        const token = localStorage.getItem('idToken');
        if (token)
            return token;
        else
            return null;
    }

    getDecodedToken(): any {
        const token = localStorage.getItem('idToken');
        if (token)
            return jwtDecode(token);
        else
            return null;
    }

    clearToken(): void {
        //console.log('[HR Connect Support Tool Auth] Clearing token');
        localStorage.clear();
    }

    clearStorage(): void {
        localStorage.clear();
    }
}
