import { Col, Row } from "@amzn/stencil-react-components/layout";
import { StencilProvider } from "@amzn/stencil-react-components/context";
import React from "react";
import { HR2021Theme } from "@amzn/stencil-react-theme-default";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { H1, Text } from "@amzn/stencil-react-components/text";
import CustomBackToTop from "./CustomBackToTop";
import { RouterLinkCard } from "../pages/Forbidden";
import { LinkCard } from "@amzn/stencil-react-components/card";
import { containsLdapElement, LDAP } from "../models/LDAP";
import AuthService from "../services/AuthService";
import { PageProps } from "../models/FederateData";

const authService: AuthService = new AuthService();
const LandingContent: React.FC<PageProps> = (pageProps) => {
  RouterLinkCard.defaultProps = {
    ...(LinkCard.defaultProps as any),
  };

  let LDAP_GROUP: Array<string> = [];

  if (authService.isTokenPresent() && pageProps.federateToken !== null) {
    LDAP_GROUP = pageProps.federateToken.LDAPGROUP;
  }

  return (
    <StencilProvider
      theme={{
        ...HR2021Theme,
      }}
    >
      <PageContainer
        alignItems="center"
        flexWrap="nowrap"
        centered={true}
        paddingBottom="S700"
        paddingTop="S600"
        paddingHorizontal="S600"
      >
        <Col padding="S500" alignItems="center" justifyContent="center">
          <br />
          <H1 textAlign={"center"}>
            Welcome to the HR Connect Process Support Tool.
          </H1>
          <br />
          <br />
          <PageContainer
            flexWrap="wrap"
            alignItems="center"
            centered={true}
            paddingBottom="S700"
            paddingTop="S600"
            paddingHorizontal="S600"
          >
            <Row
              padding="S300"
              gridGap={75}
              alignItems="center"
              justifyContent="space-evenly"
              display="flex"
              flexWrap="wrap"
            >
              <ul style={{ listStyleType: "none" }}>
                {containsLdapElement(LDAP_GROUP, LDAP[7].toString()) && (
                  <li
                    style={{
                      margin: 0,
                      float: "left",
                      padding: ".2em 2em",
                    }}
                  >
                    <RouterLinkCard
                      height={200}
                      width={300}
                      flexDirection="column"
                      justifyContent="center"
                      alignSelf="center"
                      to="/UserManagementAMER"
                      backgroundColor="primary10"
                    >
                      <Text fontSize="T400" textAlign="center">
                        {" "}
                        User Management Americas
                      </Text>
                      <br />
                    </RouterLinkCard>
                  </li>
                )}
                {containsLdapElement(LDAP_GROUP, LDAP[8].toString()) && (
                  <li
                    style={{
                      margin: 0,
                      float: "left",
                      padding: ".2em 2em",
                    }}
                  >
                    <RouterLinkCard
                      height={200}
                      width={300}
                      flexDirection="column"
                      justifyContent="center"
                      alignSelf="center"
                      to="/UserManagementAPAC"
                      backgroundColor="primary10"
                    >
                      <Text fontSize="T400" textAlign="center">
                        {" "}
                        User Management Asia Pacific{" "}
                      </Text>
                      <br />
                    </RouterLinkCard>
                  </li>
                )}
                {containsLdapElement(LDAP_GROUP, LDAP[9].toString()) && (
                  <li
                    style={{
                      margin: 0,
                      float: "left",
                      padding: ".2em 2em",
                    }}
                  >
                    <RouterLinkCard
                      height={200}
                      width={300}
                      flexDirection="column"
                      justifyContent="center"
                      alignSelf="center"
                      to="/UserManagementEMEA"
                      backgroundColor="primary10"
                    >
                      <Text fontSize="T400" textAlign="center">
                        {" "}
                        User Management Europe Middle East{" "}
                      </Text>
                      <br />
                    </RouterLinkCard>
                  </li>
                )}
                {containsLdapElement(LDAP_GROUP, LDAP[0].toString()) && (
                  <li
                    style={{
                      margin: 0,
                      float: "left",
                      padding: ".2em 2em",
                    }}
                  >
                    <RouterLinkCard
                      height={200}
                      width={300}
                      flexDirection="column"
                      justifyContent="center"
                      alignSelf="center"
                      to="/SituationalMessagesAMER"
                      backgroundColor="primary10"
                    >
                      <Text fontSize="T400" textAlign="center">
                        {" "}
                        Situational Messages Americas{" "}
                      </Text>
                      <br />
                    </RouterLinkCard>
                  </li>
                )}
                {containsLdapElement(LDAP_GROUP, LDAP[1].toString()) && (
                  <li
                    style={{
                      margin: 0,
                      float: "left",
                      padding: ".2em 2em",
                    }}
                  >
                    <RouterLinkCard
                      height={200}
                      width={300}
                      flexDirection="column"
                      justifyContent="center"
                      alignSelf="center"
                      to="/SituationalMessagesEMEA"
                      backgroundColor="primary10"
                    >
                      <Text fontSize="T400" textAlign="center">
                        {" "}
                        Situational Messages Europe Middle East Africa{" "}
                      </Text>
                      <br />
                    </RouterLinkCard>
                  </li>
                )}
                {containsLdapElement(LDAP_GROUP, LDAP[2].toString()) && (
                  <li
                    style={{
                      margin: 0,
                      float: "left",
                      padding: ".2em 2em",
                    }}
                  >
                    <RouterLinkCard
                      height={200}
                      width={300}
                      flexDirection="column"
                      justifyContent="center"
                      alignSelf="center"
                      to="/SituationalMessagesAPAC"
                      backgroundColor="primary10"
                    >
                      <Text fontSize="T400" textAlign="center">
                        {" "}
                        Situational Messages Asia Pacific{" "}
                      </Text>
                      <br />
                    </RouterLinkCard>
                  </li>
                )}
                {containsLdapElement(LDAP_GROUP, LDAP[3].toString()) && (
                  <li
                    style={{
                      margin: 0,
                      float: "left",
                      padding: ".2em 2em",
                    }}
                  >
                    <RouterLinkCard
                      height={200}
                      width={300}
                      flexDirection="column"
                      justifyContent="center"
                      alignSelf="center"
                      to="/VariableStaffingAMER"
                      backgroundColor="primary10"
                    >
                      <Text fontSize="T400" textAlign="center">
                        {" "}
                        Variable Staffing Americas{" "}
                      </Text>
                      <br />
                    </RouterLinkCard>
                  </li>
                )}
                {containsLdapElement(LDAP_GROUP, LDAP[4].toString()) && (
                  <li
                    style={{
                      margin: 0,
                      float: "left",
                      padding: ".2em 2em",
                    }}
                  >
                    <RouterLinkCard
                      height={200}
                      width={300}
                      flexDirection="column"
                      justifyContent="center"
                      alignSelf="center"
                      to="/IVRTextSelfService"
                      backgroundColor="primary10"
                    >
                      <Text fontSize="T400" textAlign="center">
                        {" "}
                        Interactive Voice Response Text Self-Service Americas{" "}
                      </Text>
                      <br />
                    </RouterLinkCard>
                  </li>
                )}
                {containsLdapElement(LDAP_GROUP, LDAP[5].toString()) && (
                  <li
                    style={{
                      margin: 0,
                      float: "left",
                      padding: ".2em 2em",
                    }}
                  >
                    <RouterLinkCard
                      height={200}
                      width={300}
                      flexDirection="column"
                      justifyContent="center"
                      alignSelf="center"
                      to="/ManualTextSelfService"
                      backgroundColor="primary10"
                    >
                      <Text fontSize="T400" textAlign="center">
                        {" "}
                        Manual Text Self-Service{" "}
                      </Text>
                      <br />
                    </RouterLinkCard>
                  </li>
                )}
                {containsLdapElement(LDAP_GROUP, LDAP[6].toString()) && (
                  <li
                    style={{
                      margin: 0,
                      float: "left",
                      padding: ".2em 2em",
                    }}
                  >
                    <RouterLinkCard
                      height={200}
                      width={300}
                      flexDirection="column"
                      justifyContent="center"
                      alignSelf="center"
                      to="/SMSTextSelfService"
                      backgroundColor="primary10"
                    >
                      <Text fontSize="T400" textAlign="center">
                        {" "}
                        Employee Services Technology SMS Text Service{" "}
                      </Text>
                      <br />
                    </RouterLinkCard>
                  </li>
                )}
              </ul>
            </Row>
          </PageContainer>
        </Col>
        <CustomBackToTop />
      </PageContainer>
    </StencilProvider>
  );
};

export default LandingContent;
