import React from 'react';

import { StencilProvider } from '@amzn/stencil-react-components/context';
import { Button, ButtonIconPosition, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button';
import { H1, H2, Label, Text } from '@amzn/stencil-react-components/text';
import { PageContainer } from '@amzn/stencil-react-components/page';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { Table, TableSpacing } from '@amzn/stencil-react-components/table';
import { Avatar, AvatarText } from '@amzn/stencil-react-components/avatar';
import {
    FormWrapper,
    InputWrapper,
    Select,
    TextArea,
    TextAreaWithRecommendedLength
} from '@amzn/stencil-react-components/form';
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal';
import { FederateData, PageProps } from '../models/FederateData';
import {
    getColumnsSituationalMessages,
    getColumnsSituationalMessagesChangeLog,
    getRowAttributesCLForSM,
    renderCardForChangelogSM,
    renderCardForRowSM,
    SMTableData
} from '../models/SMTableData';
import { HR2021Theme } from '@amzn/stencil-react-theme-default';
import AuthService from '../services/AuthService';
import { SituationalMessagesState } from '../models/SituationalMessagesState';
import CustomHeader, { ButtonWithTooltip } from '../components/CustomHeader';
import CustomBackToTop from '../components/CustomBackToTop';
import CustomFooter from '../components/CustomFooter';
import { containsLdapElement, containsLdapElementForViewOnly, LDAP, suggestionStatement } from '../models/LDAP';
import { AppConfig } from '../models/AppConfig';
import { getCredentials } from '../services/aws.service';
import { AwsClient } from 'aws4fetch';
import errorHandling from '../components/ErrorHandler';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { Spinner } from '@amzn/stencil-react-components/spinner';
import { isSSMLValid, isValidTag } from '../services/TagValidator';
import CustomError from '../models/CustomError';
import { IconArrowRight, IconCross, IconDownload, IconReset, IconSize } from '@amzn/stencil-react-components/icons';
import SignedOut from './SignedOut';
import Forbidden from './Forbidden';
import { cssPropertiesTable } from '../models/TableCustomCSS';
import { modalInstructionsSM } from '../models/Instructions';
import { Redirect } from 'react-router-dom';
import '../App.css';
import { MainWithSkipLink } from '@amzn/stencil-react-components/a11y';
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only';
import { tabs } from '../models/CustomTabs';
import { TabBar, TabPanel, TabsLayout, TabSwitcher } from '@amzn/stencil-react-components/tabs';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import { Expander } from '@amzn/stencil-react-components/expander';
import { PopupDateRangePicker } from '@amzn/stencil-react-components/date-picker';
import { convertToTimestamp, isBlank } from '../services/FilterServices';
import { TOOLTIP_POSITION, TooltipAlignment } from '@amzn/stencil-react-components/tooltip';


var FileSaver = require('file-saver');
const Diff = require('diff');
const appConfig: AppConfig = require('../components/config.json')[`${process.env.REACT_APP_ENVIRONMENT}`];
const authService: AuthService = new AuthService();

export default class SMEMEA extends React.Component<PageProps, SituationalMessagesState> {
    showNumberOfRows: boolean = false;
    awsFetchClient: AwsClient | undefined;
    msgGroup: string = '';
    msgIdSelected: string = '';
    federateToken: FederateData = authService.isTokenPresent() ? authService.getDecodedToken() : window.location.href = '/auth';
    promiseAwsFetchClient: Promise<AwsClient> = getCredentials();
    totalCount: number = 0;
    auditFilterURI: string = '';
    filters: string[] = [];
    valueOfAT: string[] = [];
    isReadOnly: boolean = containsLdapElementForViewOnly(this.federateToken.LDAPGROUP, LDAP[1].toString());
    allMsgGroups: string[] = [];

    state: SituationalMessagesState = {
        fetchedDataForDropdown: [],
        fetchedDataForTableRows: [],
        isOpen: false,
        loading: true,
        isPostError: '',
        isPostSuccess: false,
        readyToRedirect: false,
        fetchedChangelogData: [],
        tabValue: tabs[0],
        selectedPage: 1,
        filteredValueChangelog: [],
        startDate: '',
        endDate: '',
        isExpanded: false
    };

    onResetFilter() {
        this.setState(prev => ({ ...prev, filteredValueChangelog: [] }));
        this.filters = [];
    }

    tableData: SMTableData = {
        Enabled: '',
        Language: '',
        MsgId: '',
        Text: '',
        comment: ''
    };

    updatedTableData: SMTableData = {
        Enabled: '',
        Language: '',
        MsgId: '',
        Text: '',
        comment: ''
    };

    setLoadingValue() {
        this.setState({ loading: true });
    }

    componentDidMount() {
        document.title = ' HRS Messaging EMEA ';
        this.getTableDataForChangelog(false, false, 1, [], this.state.startDate, this.state.endDate);
        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        appConfig.apigUri + 'product?name=SituationalMessagesEMEA',
                        {
                            method: 'GET',
                            headers: {
                                'token': authService.getToken()
                            }
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              return <Redirect to='/SignedOut' />;
                          }
                          this.setState({ fetchedDataForDropdown: fetchedData.results, loading: false });
                          this.allMsgGroups = fetchedData.allMsgGroups;
                      });

                  } catch (error) {
                      errorHandling(error);
                      this.setState({ loading: false });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    getTableData(value: String) {
        this.setLoadingValue();

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        appConfig.apigUri + 'component?p_name=SituationalMessagesEMEA&c_name=' + value.toString(),
                        {
                            method: 'GET',
                            headers: {
                                'token': authService.getToken()
                            }
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              return <Redirect to='/SignedOut' />;
                          }
                          this.setState({ fetchedDataForTableRows: fetchedData, loading: false });
                      });

                  } catch (error) {
                      errorHandling(error);
                      this.setState({ loading: false });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    updateTableData(tableData: SMTableData) {
        this.setLoadingValue();
        const body = {
            'Enabled': (tableData.Enabled === 'True'),
            'prevEnabled': (this.tableData.Enabled === 'True'),
            'Language': tableData.Language,
            'MsgId': tableData.MsgId,
            'Text': tableData.Text,
            'prevText': this.tableData.Text,
            'comment': tableData.comment,
            'user': this.federateToken.sub
        };

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        appConfig.apigUri + 'component?p_name=SituationalMessagesEMEA&c_name=' + this.msgGroup,
                        {
                            method: 'PATCH',
                            headers: {
                                'token': authService.getToken()
                            },
                            body: JSON.stringify(body)
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              throw new CustomError(fetchedData.Error);
                          }
                          this.msgIdSelected = tableData.MsgId;
                          this.setState({
                              fetchedDataForTableRows: fetchedData,
                              loading: false,
                              isPostSuccess: true,
                              isPostError: ''
                          });
                          this.updatedTableData = {
                              Enabled: '',
                              Language: '',
                              MsgId: '',
                              Text: '',
                              comment: ''
                          };
                      });

                  } catch (error: any) {
                      errorHandling(error);
                      this.setState({ loading: false, isPostError: error.message, isPostSuccess: false });
                      this.updatedTableData = {
                          Enabled: '',
                          Language: '',
                          MsgId: '',
                          Text: '',
                          comment: ''
                      };
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    getTableDataForChangelog(isLoading: boolean, isFiltered: boolean, pageNumber: number, messageGroupArray: string[], startDate: string | undefined, endDate: string | undefined) {
        if (isLoading) {
            this.setLoadingValue();
        }

        startDate = convertToTimestamp(startDate);
        endDate = convertToTimestamp(endDate);

        this.auditFilterURI = isBlank(startDate) ? isBlank(startDate) && !isBlank(endDate) ? '&end_timestamp=' + endDate : '' :
          this.auditFilterURI = isBlank(endDate) ? !isBlank(startDate) && isBlank(endDate) ? '&start_timestamp=' + startDate : '' :
            '&start_timestamp=' + startDate + '&end_timestamp=' + endDate;

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        !isFiltered ? appConfig.apigUriAudit + 'audit_trails?name=SituationalMessagesEMEA&page=' + pageNumber :
                          appConfig.apigUriAudit + 'audit_trails?name=SituationalMessagesEMEA&filter_by=' + messageGroupArray.toString() + '&page=' + pageNumber + this.auditFilterURI,
                        {
                            method: 'GET',
                            headers: {
                                'token': authService.getToken()
                            }
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then(async (fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              throw new CustomError(fetchedData.Error);
                          }

                          if (pageNumber === 1) {
                              this.totalCount = fetchedData.count;
                              fetchedData = fetchedData.results;
                          }

                          fetchedData = await fetchedData.filter((element: any) => element.diffText = (Diff.diffChars(element.PreviousState, element.CurrentState)));
                          fetchedData.filter((element: any): void | null => {
                              let str: string = '<pre style="line-height: 1.5em;font-size: 15px">';
                              element.diffText.filter((innerElement: any): void | null => {
                                  const screenReaderAction = innerElement.added ? '<span class="screen-reader"> Current characters </span>' : innerElement.removed ? '<span class="screen-reader"> Previous characters </span>' : '<span class="screen-reader"> Unchanged characters </span>';
                                  const backgroundColor = innerElement.added ? '#86dac9' : innerElement.removed ? '#f8a49e' : 'white';
                                  const action = innerElement.added ? 'ins' : innerElement.removed ? 'del' : 'span';
                                  str += (screenReaderAction + '<' + action + ' style="background-color:' + backgroundColor + ';">' + innerElement.value + '</' + action + '>');
                                  return null;
                              }, '');
                              str += '</pre>';
                              element.diffText = str;
                              return null;
                          }, '');

                          this.setState({ fetchedChangelogData: fetchedData, loading: false });
                      });
                  } catch (error) {
                      errorHandling(error);
                      this.setState({ loading: false });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    getChangelogCSV(isLoading: boolean, messageGroupArray: string[], startDate: string | undefined, endDate: string | undefined) {
        if (isLoading) {
            this.setLoadingValue();
        }

        startDate = convertToTimestamp(startDate);
        endDate = convertToTimestamp(endDate);

        const isFiltered: boolean = messageGroupArray.length === 0 && isBlank(startDate) && isBlank(endDate);

        this.auditFilterURI = isBlank(startDate) ? isBlank(startDate) && !isBlank(endDate) ? '&end_timestamp=' + endDate : '' :
          this.auditFilterURI = isBlank(endDate) ? !isBlank(startDate) && isBlank(endDate) ? '&start_timestamp=' + startDate : '' :
            '&start_timestamp=' + startDate + '&end_timestamp=' + endDate;

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        isFiltered ? appConfig.apigUriAudit + 'audit_trails?name=SituationalMessagesEMEA&page=1&is_csv_requested=true' :
                          appConfig.apigUriAudit + 'audit_trails?name=SituationalMessagesEMEA&page=1&is_csv_requested=true&filter_by=' + messageGroupArray.toString() + this.auditFilterURI,
                        {
                            method: 'GET',
                            headers: {
                                'token': authService.getToken()
                            }
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then(async (fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              throw new CustomError(fetchedData.Error);
                          }
                          this.setState({ loading: false });
                          FileSaver.saveAs(new Blob([fetchedData], { type: 'text/csv;charset=utf-8' }), 'SituationalMessagesEMEA-' + new Date().toISOString().split('T')[0] +
                            '_' + new Date().toLocaleTimeString('en-GB') + '.csv');

                      });
                  } catch (error) {
                      errorHandling(error);
                      this.setState({ loading: false });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    handleSubmit = (event: any) => {
        event.preventDefault();
        this.updateTableData(this.updatedTableData);
    };

    close = () => this.setState({ isOpen: false });

    checkBlankText = () => {
        if (this.updatedTableData.Text === '') {
            alert('The Text value can not be empty.');
            return true;
        }

        if (this.updatedTableData.Text.trim() === this.tableData.Text && this.updatedTableData.Enabled === this.tableData.Enabled) {
            alert('No Changes were made, please make edits.');
            return true;
        }

        if (!isSSMLValid(this.updatedTableData.Text.trim())) {
            alert('Invalid text, text should be enclosed within <speak>...</speak> tags.');
            return true;
        }

        const isValid = isValidTag(this.updatedTableData.Text);
        if (isValid !== true) {
            alert(isValid.toString());
            return true;
        }

        if (this.updatedTableData.comment.trim() === '') {
            alert('Comments are mandatory, please leave a comment.');
            return true;
        }
    };

    downloadText = (length: number) => `Click here Export the ${length} rows to a CSV file.`;

    getModal() {
        return (
          <>
              <div style={cssPropertiesTable}>
                  <Table isStriped={true}
                         getRowAttributes={this.getRowAttributes}
                         aria-labelledby='heading'
                         renderCardForRow={renderCardForRowSM}
                         columns={getColumnsSituationalMessages}
                         data={this.state.fetchedDataForTableRows}
                         actionHeader={!this.isReadOnly ? 'Edit' : ''}
                         spacing={TableSpacing.Default}
                         shouldAutoCollapseOnExpand={true} shouldScrollHorizontally={true} />
              </div>
              <Modal isScrollable={false} shouldCloseOnClickOutside={false} isOpen={this.state.isOpen}
                     close={this.close} aria-labelledby='Edit'>
                  <ModalContent
                    aria-label='Edit Message Details'
                    maxWidth={1050}
                    buttons={[
                        <Button
                          variant={ButtonVariant.Primary}
                          onClick={(event) => {
                              if (!this.checkBlankText()) {
                                  this.close();
                                  this.handleSubmit(event);
                              }
                          }}
                          type='submit'> Submit </Button>,
                        <Button
                          onClick={() => {
                              this.close();
                              this.updatedTableData = {
                                  Enabled: '',
                                  Language: '',
                                  MsgId: '',
                                  Text: '',
                                  comment: ''
                              };
                          }}
                          key='Close'
                          icon={<IconCross aria-hidden={true} size={IconSize.ExtraSmall} />}
                          isDestructive
                          variant={ButtonVariant.Tertiary}>
                            Discard
                        </Button>
                    ]}>
                      <H2 id='Edit' fontSize='T400'> Edit Message ID : {this.tableData.MsgId} </H2>
                      {modalInstructionsSM()}
                      <FormWrapper
                        padding='S300' maxWidth={650} width={650}>
                          <InputWrapper id='text-preview-1'
                                        labelText='Text'
                                        footerText='Enter the updated text here'>
                              {(textAreaProps) => <TextArea
                                resize='vertical'
                                defaultValue={this.tableData.Text}
                                onChange={(event) => {
                                    this.updatedTableData.Text = event.target.value.trim();
                                }}
                                {...textAreaProps}
                              />}
                          </InputWrapper>
                          <InputWrapper id='Enabled' labelText='Enabled'
                                        footerText='Select whether enabled or not'>
                              {(selectProps) => (<Select defaultValue={this.tableData.Enabled}
                                                         aria-label='Choose whether enabled or not'
                                                         searchFieldAriaLabel='Choose whether enabled or not'
                                                         options={['True', 'False']}
                                                         onChange={value => this.updatedTableData.Enabled = value}
                                                         {...selectProps} />)}
                          </InputWrapper>
                          <InputWrapper id='text-preview-2'
                                        labelText='Comments: Why the changes were made?'
                                        footerText='Ex: Changed Class M option 1 SMS to reflect new time-off regulations that went into place on 4/5.'>
                              {(textAreaProps) => <TextAreaWithRecommendedLength
                                resize='vertical'
                                recommendedWordCount={30}
                                defaultValue={this.tableData.comment}
                                onChange={(event) => {
                                    this.updatedTableData.comment = event.target.value.trim();
                                }}
                                {...textAreaProps} />}
                          </InputWrapper>
                      </FormWrapper>
                  </ModalContent>
              </Modal>
          </>
        );
    }

    getRowAttributes = () => ({
          onClick: ({ index }: any) => {
              this.setState({ isOpen: true });
              this.tableData.MsgId = this.state.fetchedDataForTableRows[index].MsgId;
              this.updatedTableData.MsgId = this.tableData.MsgId;

              this.tableData.Enabled = this.state.fetchedDataForTableRows[index].Enabled;
              this.updatedTableData.Enabled = this.tableData.Enabled;

              this.tableData.Language = this.state.fetchedDataForTableRows[index].Language;
              this.updatedTableData.Language = this.tableData.Language;

              this.tableData.Text = this.state.fetchedDataForTableRows[index].Text;
              this.updatedTableData.Text = this.tableData.Text;
          },
          disabled: this.isReadOnly
      }
    );

    render() {

        if (!authService.isUserAuthenticated() && this.federateToken === null)
            return <SignedOut allowance={true} />;

        if (this.state.readyToRedirect) {
            return <SignedOut allowance={true} />;
        }

        if (!containsLdapElement(this.federateToken.LDAPGROUP, LDAP[1].toString())) {
            return <Forbidden federateToken={this.federateToken} allowance={true} />;
        }

        return (
          <StencilProvider theme={{
              ...HR2021Theme
          }}>
              <View height={150}>
                  <CustomHeader federateToken={this.federateToken} />
                  <Col margin={{ left: 155 }}>
                      {
                          authService.isTokenPresent() &&
                          <div className='link-underline'>
                            <Avatar
                              imageAltText='badge image'
                              fullName={this.federateToken.FAMILY_NAME + ', ' + this.federateToken.GIVEN_NAME}
                              username={this.federateToken.sub}
                              usernameClickable={true}
                              margin={25}>
                              <AvatarText ariaLabel='description'>
                                  {suggestionStatement(this.federateToken.LDAPGROUP, this.isReadOnly, 1)}
                              </AvatarText>
                            </Avatar>
                          </div>
                      }
                  </Col>
              </View>

              <PageContainer
                backgroundColor='primary05'
                alignItems='center'
                centered={true}
                paddingBottom='S700'
                paddingTop='S600'
                paddingHorizontal='S600'>
                  {
                      this.state.isPostSuccess &&
                      <MessageBanner iconAltText='Successful' type={MessageBannerType.Success}
                                     title='Update successful'
                                     isDismissible onDismissed={() => {
                          this.setState({ isPostSuccess: false });
                          this.msgIdSelected = '';
                      }}>
                        The {this.msgIdSelected} entry is updated successfully.
                      </MessageBanner>
                  }
                  {
                      this.state.isPostError !== '' &&
                      <MessageBanner iconAltText='Failure' type={MessageBannerType.Error}
                                     title='Update failed' isDismissible
                                     onDismissed={() => this.setState({ isPostError: '' })}>
                        There seems to be an issue with the update - {this.state.isPostError}
                      </MessageBanner>
                  }
                  <br />

                  <MainWithSkipLink>
                      <Col gridGap='S500' padding='S300'>
                          <H1 id='heading' textAlign='center'> HRS Messaging EMEA </H1>
                          <Col alignItems='center'>
                              <TabBar
                                aria-label='Tabs here for Main Content and Audit trails'
                                layout={TabsLayout.Default}
                                onTabSelect={(tabValue) => {
                                    this.setState({ tabValue: tabValue });
                                    if (tabValue === tabs[1]) {
                                        this.onResetFilter();
                                        this.setState({
                                            selectedPage: 1,
                                            isExpanded: false,
                                            startDate: '',
                                            endDate: ''
                                        });
                                        this.getTableDataForChangelog(true, false, 1, [], this.state.startDate, this.state.endDate);
                                    }
                                }}
                                selectedTab={this.state.tabValue}
                                tabGroup='TabGroup-1'
                                tabs={tabs}
                              />
                          </Col>
                          <TabSwitcher
                            selectedTab={this.state.tabValue}
                            tabGroup='TabGroup-1'>

                              <TabPanel value='Main Content'>
                                  <br />
                                  {
                                      this.state.loading &&
                                      <StencilProvider theme={{
                                          ...HR2021Theme
                                      }}>
                                        <br />
                                        <Col gridGap={35} aria-live='assertive' aria-busy={this.state.loading}>
                                          <Spinner size={70} strokeWidth={9} showText textColor='neutral100'
                                                   aria-live='assertive' aria-busy={this.state.loading} />
                                        </Col>
                                        <br />
                                      </StencilProvider>
                                  }
                                  <br />
                                  <Col
                                    backgroundColor='primary05'
                                    alignItems='center'
                                    justifyContent='center'>
                                      <ScreenReaderOnly>
                                          <Label htmlFor='Choose-Group' id='Choose-Group-label'>
                                              Choose Group from Dropdown
                                          </Label>
                                      </ScreenReaderOnly>
                                      <Select
                                        aria-label='Choose Group from Dropdown'
                                        searchFieldAriaLabel='Choose Group from Dropdown'
                                        id='Choose-Group'
                                        labelId='Choose-Group-label'
                                        placeholder='Choose Group'
                                        width={'40vw'}
                                        isSearchable={true}
                                        options={this.state.fetchedDataForDropdown}
                                        onChange={value => {
                                            this.getTableData(value);
                                            this.showNumberOfRows = true;
                                            this.msgGroup = value;
                                        }}
                                      />
                                      <br />
                                      <br />
                                      {
                                          this.showNumberOfRows &&
                                          <Text fontSize='T200'>Fetched no. of
                                            Rows: {this.state.fetchedDataForTableRows.length}</Text>
                                      }
                                      <br />
                                      {this.getModal()}
                                      <br />
                                  </Col>
                              </TabPanel>

                              <TabPanel value='Audit Trails'>
                                  <Expander
                                    isExpanded={this.state.isExpanded}
                                    onToggle={() => this.setState({ isExpanded: !this.state.isExpanded })}
                                    titleText='Click to open Filters'>
                                      <View backgroundColor='neutral0' alignItems='flex-start'
                                            padding='S300' flexWrap='overflow'>
                                          <Row gridGap='S700' justifyContent='flex-start' flexWrap='wrap'
                                               padding='S300'>
                                              <Col gridGap='S300' alignItems='flex-start' padding='S300'>
                                                  <Text id='smsType-cl1-selector-label'>
                                                      Choose Message Group to filter:
                                                  </Text>

                                                  <Select placeholder='Choose Message Group for change log'
                                                          isSearchable={true}
                                                          aria-label='Choose Message Group from Dropdown'
                                                          searchFieldAriaLabel='Choose Message Group from Dropdown'
                                                          aria-describedby='smsType-cl-selector-label'
                                                          isMulti={true}
                                                          width='55vw'
                                                          options={this.allMsgGroups}
                                                          value={this.state.filteredValueChangelog}
                                                          onChange={value => {
                                                              this.setState({ filteredValueChangelog: value });
                                                          }} />
                                              </Col>
                                              <Col gridGap='S300' alignItems='flex-start' padding='S300'>
                                                  <Text id='smsType-cl3-selector-label'>
                                                      Choose Date Range to filter:
                                                  </Text>

                                                  <View width='55vw' justifyContent='flex-start'>
                                                      <PopupDateRangePicker
                                                        ariaLabelsForSelectedDate={{
                                                            endDate: 'You have selected an end date',
                                                            startDate: 'You have selected a start date'
                                                        }}
                                                        id='filter-date-picker'
                                                        name='choose-dates'
                                                        shouldFocusOnStartDate={true}
                                                        locale='iso8601'
                                                        startDateLabelText='Start date (YYYY-MM-DD)'
                                                        endDateLabelText='End date (YYYY-MM-DD)'
                                                        startValue={this.state.startDate as unknown as string}
                                                        endValue={this.state.endDate as unknown as string}
                                                        onChange={(startDate, endDate) => {
                                                            if (startDate)
                                                                this.setState({ startDate: startDate + ' 00:00:00' });
                                                            else
                                                                this.setState({ startDate: '' });
                                                            if (endDate)
                                                                this.setState({ endDate: endDate + ' 23:59:59' });
                                                            else
                                                                this.setState({ endDate: '' });
                                                        }}
                                                      />
                                                  </View>
                                              </Col>
                                          </Row>

                                          <Row gridGap='S700' padding='S600' justifyContent='center'
                                               flexWrap='wrap'>
                                              <Button
                                                variant={ButtonVariant.Primary}
                                                type='button'
                                                aria-describedby='smsType-cl1-selector-label'
                                                icon={<IconArrowRight title='Apply Filter' aria-hidden={true} />}
                                                iconPosition={ButtonIconPosition.Trailing}
                                                onClick={() => {
                                                    this.filters = this.state.filteredValueChangelog;
                                                    this.getTableDataForChangelog(true, true, 1, this.filters, this.state.startDate, this.state.endDate);
                                                    this.setState({ selectedPage: 1 });
                                                }}>
                                                  Apply Filter
                                                  &nbsp;
                                              </Button>
                                              <Button
                                                variant={ButtonVariant.Primary}
                                                type='button'
                                                icon={<IconReset title='Reset Filter' aria-hidden={true} />}
                                                iconPosition={ButtonIconPosition.Trailing}
                                                onClick={() => {
                                                    this.onResetFilter();
                                                    this.setState({
                                                        selectedPage: 1,
                                                        startDate: '',
                                                        endDate: ''
                                                    });
                                                    this.getTableDataForChangelog(true, false, 1, [], this.state.startDate, this.state.endDate);
                                                }}>
                                                  Reset Filters
                                                  &nbsp;
                                              </Button>
                                          </Row>
                                      </View>
                                  </Expander>
                                  <br />
                                  <Col
                                    backgroundColor='primary05'
                                    alignItems='center'
                                    justifyContent='center'>
                                      {
                                          this.state.loading &&
                                          <StencilProvider theme={{
                                              ...HR2021Theme
                                          }}>
                                            <br />
                                            <Col gridGap={35} aria-live='assertive' aria-busy={this.state.loading}>
                                              <Spinner size={70} strokeWidth={9} showText textColor='neutral100'
                                                       aria-live='assertive' aria-busy={this.state.loading} />
                                            </Col>
                                            <br />
                                          </StencilProvider>
                                      }
                                      <br />
                                  </Col>
                                  <Row gridGap='S500' display='flex' alignItems='flex-end' justifyContent='flex-end'
                                       padding='S300'>
                                      <Text margin={{ bottom: 7 }} fontWeight='bold' textAlign='right' fontSize='T200'>Total
                                          no. of
                                          Rows: {this.totalCount}</Text>
                                      {
                                          this.state.fetchedChangelogData.length !== 0 &&
                                          <ButtonWithTooltip
                                            role='tooltip'
                                            aria-describedby='Click here to Export the changelogs to CSV.'
                                            icon={<IconDownload title='Export to CSV' />}
                                            title={this.downloadText(this.totalCount)}
                                            variant={ButtonVariant.Secondary}
                                            onClick={() => {
                                                this.getChangelogCSV(true, this.filters, this.state.startDate, this.state.endDate);
                                            }}
                                            tooltipAlignment={TooltipAlignment.Left}
                                            tooltipPosition={TOOLTIP_POSITION.BOTTOM}
                                            size={ButtonSize.Small}
                                            tooltipText={this.downloadText(this.totalCount)}
                                          >
                                            Download Changelog
                                          </ButtonWithTooltip>
                                      }
                                      <Spacer flex={0.20} />
                                      {
                                          this.state.fetchedChangelogData.length !== 0 &&
                                          <Pagination
                                            aria-label='pagination'
                                            numberOfPages={Math.ceil(this.totalCount / 10)}
                                            selectedPage={this.state.selectedPage}
                                            onPageSelect={(value) => {
                                                this.setState({ selectedPage: value });
                                                this.getTableDataForChangelog(true, true, value, this.filters, this.state.startDate, this.state.endDate);
                                            }}
                                          />
                                      }
                                  </Row>
                                  <br />
                                  <div style={cssPropertiesTable}>
                                      <Table
                                        aria-labelledby='smsType-cl1-selector-label'
                                        actionHeader={'Expand'}
                                        getRowAttributes={getRowAttributesCLForSM}
                                        columns={getColumnsSituationalMessagesChangeLog}
                                        renderCardForRow={renderCardForChangelogSM}
                                        data={this.state.fetchedChangelogData}
                                        spacing={TableSpacing.Default}
                                        shouldAutoCollapseOnExpand={true}
                                        shouldScrollHorizontally={true} />
                                  </div>
                                  <br />
                              </TabPanel>
                          </TabSwitcher>

                      </Col>
                  </MainWithSkipLink>
                  <br />
                  <CustomBackToTop />
              </PageContainer>
              <CustomFooter />
          </StencilProvider>
        );
    }
}
