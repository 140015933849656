import React from 'react';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { H1, H4, Text } from '@amzn/stencil-react-components/text';
import { PageContainer } from '@amzn/stencil-react-components/page';
import { Col, View } from '@amzn/stencil-react-components/layout';
import { LinkCard } from '@amzn/stencil-react-components/card';
import { Avatar } from '@amzn/stencil-react-components/avatar';
import { PageProps } from '../models/FederateData';
import { HR2021Theme } from '@amzn/stencil-react-theme-default';
import AuthService from '../services/AuthService';
import CustomHeader from '../components/CustomHeader';
import CustomFooter from '../components/CustomFooter';
import SignedOut from './SignedOut';
import { RouterLinkCard } from './Forbidden';

import '../App.css'

const authService: AuthService = new AuthService();
const Error: React.FC<PageProps> = (pageProps) => {

    if (!authService.isUserAuthenticated() && pageProps.federateToken === null) {
        return <SignedOut allowance={true} />;
    }

    RouterLinkCard.defaultProps = {
        ...(LinkCard.defaultProps as any)
    };

    return (
      <StencilProvider theme={{
          ...HR2021Theme
      }}>
          <View height={150}>
              <CustomHeader federateToken={pageProps.federateToken} />
              <Col margin={{ left: 155 }}>
                  {
                      authService.isTokenPresent() &&
                      <div className='link-underline'>
                        <Avatar
                          imageAltText='badge image'
                          fullName={pageProps.federateToken.FAMILY_NAME + ', ' + pageProps.federateToken.GIVEN_NAME}
                          username={pageProps.federateToken.sub}
                          usernameClickable={true}
                          margin={25}>
                        </Avatar>
                      </div>
                  }
              </Col>
          </View>
          <PageContainer
            alignItems='center'
            centered={true}
            paddingHorizontal='S600'>
              <RouterLinkCard justifyContent='center' alignSelf='center' alignItems='flex-start' to='/'
                              backgroundColor='primary05'>
                  <Col gridGap={32}>
                      <H1 textAlign='center'>This page doesn't exist.</H1>
                      <Text fontSize='T500' textAlign='center'> Sorry, there is nothing to see here...</Text>
                      <br />
                      <H4 textAlign='center'> Back To Home </H4>
                      <br />
                  </Col>
              </RouterLinkCard>
          </PageContainer>
          <CustomFooter />
      </StencilProvider>
    );
};

export default Error;
