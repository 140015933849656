import { defaultCardRenderer } from '@amzn/stencil-react-components/table';
import { AvatarCell, ColumnWithMono, ColumnWithTime } from './SelfServiceTableData';
import { cssPropertiesIVR, cssPropertiesVS } from './SelfServiceCustomCSS';
import { Text } from '@amzn/stencil-react-components/text';
import { Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import React from 'react';


export interface VSTableData {
    ConfigurationId: string;
    Frontline: string | number;
    Vendor1: string | number;
    Vendor2: string | number;
    comment: string;
}

export const getColumnsVariableStaffing = [
    {
        header: 'Configuration',
        accessor: 'ConfigurationId',
        width: '40%',
        useMinimalSpacing: true
    },
    {
        header: 'Frontline',
        accessor: 'Frontline',
        width: '20%'
    },
    {
        header: 'Vendor1',
        accessor: 'Vendor1',
        width: '20%'
    },
    {
        header: 'Vendor2',
        accessor: 'Vendor2',
        width: '20%'
    }
];

export const renderCardForRowVS = defaultCardRenderer({ columns: getColumnsVariableStaffing, useCellComponents: true });

const ColumnWithHTML = ({ data }: any) => (
  <div
    dangerouslySetInnerHTML={{
        __html: `
    <style>
        .screen-reader {
        position: absolute !important;
        height: 1px; 
        width: 1px;
        overflow: auto;
        clip: rect(1px, 1px, 1px, 1px);
      }
    </style>
   ` + data
    }}
    style={cssPropertiesVS}>
  </div>
);

export const getColumnsVariableStaffingChangeLog = [
    {
        header: 'Updated By',
        width: '10%',
        cellComponent: AvatarCell,
        useMinimalSpacing: true
    },
    {
        header: 'Event Time',
        accessor: 'TrailTimestamp',
        width: '25%',
        cellComponent: ColumnWithTime,
        useMinimalSpacing: true
    },
    {
        header: 'Comment',
        accessor: 'TrailComment',
        width: '25%',
        cellComponent: ColumnWithMono,
        useMinimalSpacing: true
    },
    {
        header: 'Staffing distribution - Changes',
        accessor: 'diffText',
        width: '40%',
        cellComponent: ColumnWithHTML,
        useMinimalSpacing: true
    }
];

export const renderCardForChanglelogVS = defaultCardRenderer({ columns: getColumnsVariableStaffingChangeLog, useCellComponents: true });

const ExpandableContent = ({ data }: any) => (
  <View padding={{ top: 'S300' }}>
      <Row gridGap='S500' padding='S300'>
          <div style={cssPropertiesIVR}>
              <Text fontWeight='bold' fontSize='T200'>Staffing distribution - Before Update</Text>
              <br />
              <Text font='monospace' fontSize='T100'>
                  Frontline: {JSON.parse(data.PreviousState).Frontline} %
              </Text>
              <br />
              <Text font='monospace' fontSize='T100'>
                  Vendor1: {JSON.stringify(JSON.parse(data.PreviousState).Vendor1)} %
              </Text>
              <br />
              <Text font='monospace' fontSize='T100'>
                  Vendor2: {JSON.stringify(JSON.parse(data.PreviousState).Vendor2)} %
              </Text>
          </div>
          <Spacer flex={1} />
          <div style={cssPropertiesIVR}>
              <Text fontWeight='bold' fontSize='T200'>Staffing distribution - After Update</Text>
              <br />
              <Text font='monospace' fontSize='T100'>
                  Frontline: {JSON.parse(data.CurrentState).Frontline} %
              </Text>
              <br />
              <Text font='monospace' fontSize='T100'>
                  Vendor1: {JSON.stringify(JSON.parse(data.CurrentState).Vendor1)} %
              </Text>
              <br />
              <Text font='monospace' fontSize='T100'>
                  Vendor2: {JSON.stringify(JSON.parse(data.CurrentState).Vendor2)} %
              </Text>
          </div>
      </Row>

  </View>
);

export const getRowAttributesCLForVS = () => ({
    expandableContent: ExpandableContent,
    expandableContentHasBackgroundColor: true,
    actionLabel: 'Open to see the versions of Staffing distribution'
});

