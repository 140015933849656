import {StencilProvider} from "@amzn/stencil-react-components/context";
import React from "react";
import {HR2021Theme} from "@amzn/stencil-react-theme-default";
import {BackToTopButton} from "@amzn/stencil-react-components/back-to-top-button";

const CustomBackToTop = () => {
    return (
        <StencilProvider theme={{
            ...HR2021Theme
        }}>
            <div>
                <BackToTopButton bottom="48px" right="0.5vw" scrollOffset={125} altText="Back to top"/>
            </div>
        </StencilProvider>
    )
}

export default CustomBackToTop


