import React from "react";
import { StencilProvider } from "@amzn/stencil-react-components/context";
import { HR2021Theme } from "@amzn/stencil-react-theme-default";
import { Expander } from "@amzn/stencil-react-components/expander";
import {
  IconCheckCircleFill,
  IconCloseCircleFill,
} from "@amzn/stencil-react-components/icons";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Table } from "@amzn/stencil-react-components/table";
import { Text } from "@amzn/stencil-react-components/text";

const userManagementInfoColumns: any = [
  { header: "Field Name", accessor: "name" },
  {
    header: "Required",
    cellComponent: ({ data }: { data: any }) =>
      getRequirementStatus(data.required),
  },
  { header: "Details", accessor: "details" },
];

const userManagementInfoData: any = [
  {
    name: "First Name",
    required: "Required",
    details: "First name",
  },
  {
    name: "Last Name",
    required: "Required",
    details: "Last name",
  },
  {
    name: "User Login",
    required: "Required",
    details: "User login must be in email format: alias@amazon.com",
  },
  {
    name: "Routing Profile",
    required: "Required",
    details: "Enter only one valid Routing Profile",
  },
  {
    name: "Security Profile",
    required: "Required",
    details:
      "Separate multiple profiles with '|'.  Example: Agent|QualityAnalyst",
  },
  {
    name: "Softphone Auto Accept",
    required: "Required",
    details:
      "Enter one of the following options: 'yes' to emable auto-accept, 'no' to disable auto-accept",
  },
  {
    name: "ACW Timeout",
    required: "Required",
    details: "Enter desired After Call Work timeout.  Must be 0 or greater.",
  },
  {
    name: "Agent Hierarchy",
    required: "Not required",
    details:
      "Enter complete hierarchy with all desired levels.  Example: ERC/en/US/SJO/Frontline",
  },
];

function getRequirementStatus(required: string) {
  if (required === "Required") {
    return (
      <>
        <Row gridGap={8} alignItems="center">
          <IconCheckCircleFill color="red70" title="Required" />
          <Text fontSize="T100">Required</Text>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row gridGap={8} alignItems="center">
          <IconCloseCircleFill color="green70" title="Not Required" />
          <Text fontSize="T100">Not Required</Text>
        </Row>
      </>
    );
  }
}

const UserManagementInfo: React.FC<{}> = () => {
  return (
    <StencilProvider
      theme={{
        ...HR2021Theme,
      }}
    >
      <Row
        display="flex"
        gridGap={"S100"}
        alignItems="flex-start"
        padding={10}
        minWidth={640}
      >
        <Expander titleText="Template upload instructions">
          <Col gridGap={24} backgroundColor="neutral0" margin={8}>
            <ul>
              <li>
                <Text fontSize="T200">
                  Please download the template from this site. Other templates
                  will not work.
                </Text>
              </li>
              <li>
                <Text fontSize="T200">
                  Complete the template and then click 'Upload template'. A
                  table will be generated with your uploaded data showing the
                  status per user. The status will show 'Valid' or 'Invalid.' If
                  'Invalid,' it will display a general error message. Fix any
                  template issues and re-upload. 'Valid' indicates that all the
                  required fields are present but does not validate all the
                  information is correct in the system. The system will returns
                  any errors that occur during the actual user creation process.
                </Text>
              </li>
              <li>
                <Text fontSize="T200">
                  Once the template is ready, just click 'Create Users.' The
                  table will show the creation status for each user and any
                  error messages will be displayed below the table.
                </Text>
              </li>
              <li>
                <Text fontSize="T200">
                  If there were any errors creating a particular user, try
                  creating the user individually on the 'Create and setup a new
                  user' tab. If issues persist, create a SIM request.
                </Text>
              </li>
            </ul>
          </Col>

          <Col gridGap={16}>
            <Text id="table-label">Template Fields</Text>
            <Table
              aria-labelledby="table-label"
              isStriped={true}
              columns={userManagementInfoColumns}
              data={userManagementInfoData}
            />
          </Col>
        </Expander>
      </Row>
    </StencilProvider>
  );
};

export default UserManagementInfo;
