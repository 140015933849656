import React from "react";
import { StencilProvider } from "@amzn/stencil-react-components/context";
import { H1 } from "@amzn/stencil-react-components/text";
import { PageContainer } from "@amzn/stencil-react-components/page";
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "@amzn/stencil-react-components/button";
import { Col, Row, View } from "@amzn/stencil-react-components/layout";
import { Avatar } from "@amzn/stencil-react-components/avatar";
import {
  IconBin,
  IconDownload,
  IconUpload,
} from "@amzn/stencil-react-components/icons";
import {
  Input,
  InputHeader,
  InputWrapper,
} from "@amzn/stencil-react-components/form";
import { Table, TableSpacing } from "@amzn/stencil-react-components/table";
import { Checkbox } from "@amzn/stencil-react-components/form";
import {
  MessageBanner,
  MessageBannerType,
} from "@amzn/stencil-react-components/message-banner";
import { Select } from "@amzn/stencil-react-components/form";
import { Expander } from "@amzn/stencil-react-components/expander";
import { FederateData, PageProps } from "../models/FederateData";
import {
  TabBar,
  TabPanel,
  TabsLayout,
  TabSwitcher,
} from "@amzn/stencil-react-components/tabs";
import { HR2021Theme } from "@amzn/stencil-react-theme-default";
import AuthService from "../services/AuthService";
import CustomHeader from "../components/CustomHeader";
import CustomFooter from "../components/CustomFooter";
import SignedOut from "./SignedOut";
import {
  containsLdapElement,
  containsLdapElementForViewOnly,
  LDAP,
} from "../models/LDAP";
import { getCredentials } from "../services/aws.service";
import { AwsClient } from "aws4fetch";
import { AppConfig } from "../models/AppConfig";
import { AgentHierarchy } from "../models/AgentHierarchy";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import Forbidden from "./Forbidden";
import "../App.css";
import errorHandling from "../components/ErrorHandler";
import UserManagementInfo from "../components/UserManagementInfo";
import LoadingSpinner from "../components/LoadingSpinner";
import {
  csvToArray,
  downloadTemplate,
  getStatusIndicator,
  isTableDataValid,
  setHierarchyPath,
  validateFileData,
} from "../utils/UserManagementUtils";
import UserManagementAuditTrails from "../components/UserManagementAuditTrails";

const appConfig: AppConfig = require("../components/config.json")[
  `${process.env.REACT_APP_ENVIRONMENT}`
];
const authService: AuthService = new AuthService();
const tabs = [
  "Create and set up a new user.",
  "Upload my users from a template (csv).",
  "Audit Trails",
];

export default class UMEMEA extends React.Component<PageProps, {}> {
  fileData: any;
  federateToken: FederateData = authService.isTokenPresent()
    ? authService.getDecodedToken()
    : (window.location.href = "/auth");
  isReadOnly: boolean = containsLdapElementForViewOnly(
    this.federateToken.LDAPGROUP,
    LDAP[9].toString()
  );
  awsFetchClient: AwsClient | undefined;
  promiseAwsFetchClient: Promise<AwsClient> = getCredentials();
  firstName: any;
  lastName: any;
  email: any;

  constructor(props: PageProps) {
    super(props);
    this.fileData = [];
    this.handleFileInputChange = this.handleFileInputChange.bind(this);
    this.handleAgentHierarchyChange =
      this.handleAgentHierarchyChange.bind(this);
    this.handleAgentHiearchyExpander =
      this.handleAgentHiearchyExpander.bind(this);
    this.handleAgentHierarchyClear = this.handleAgentHierarchyClear.bind(this);
    this.firstName = React.createRef();
    this.lastName = React.createRef();
    this.email = React.createRef();
  }

  state: any = {
    tabValue: tabs[0],
    securityValue: [],
    routingValue: [],
    securityProfilesList: [],
    routingProfilesList: [],
    afterContactWorkTimeLimit: 0,
    loading: true,
    auto_accept: false,
    file_loaded: false,
    tabledata: [],
    broadcast: [],
    isPostSuccess: false,
    isResponse: false,
    agentHierarchyLevel1Data: [],
    agentHierarchyLevel2Data: [],
    agentHierarchyLevel3Data: [],
    agentHierarchyLevel4Data: [],
    agentHierarchyLevel5Data: [],
    currentHierarchyPath: "",
    currentHierarchyId: "",
    isHierarchyExpanded: false,
    isCreatingUser: false,
    isTemplateDataValid: false,
    isFormValid: false,
  };

  fileReader = new FileReader();
  currentHierarchy: AgentHierarchy = {};

  handleFileInputChange(e: any) {
    e.preventDefault();
    this.fileReader.onload = (e) => {
      const text = e.target!.result! as string;
      this.fileData = csvToArray(text!.trim());
      this.fileData = validateFileData(
        this.fileData,
        this.state.securityProfilesList
      );
      let isDataValid = isTableDataValid(this.fileData);
      this.setState(
        {
          file_loaded: true,
          tabledata: this.fileData,
          isTemplateDataValid: isDataValid,
        },
        () => console.log(JSON.stringify(this.state.tabledata))
      );
    };
    this.fileReader.readAsText(e.target.files[0]);
  }

  clearForm() {
    let form = document.getElementById("create-user-form") as HTMLFormElement;

    this.currentHierarchy = {};
    this.setState({
      securityValue: [],
      routingValue: [],
      afterContactWorkTimeLimit: 0,
      agentHierarchyLevel1Data: [],
      agentHierarchyLevel2Data: [],
      agentHierarchyLevel3Data: [],
      agentHierarchyLevel4Data: [],
      agentHierarchyLevel5Data: [],
      currentHierarchyId: "",
      currentHierarchyPath: "",
    });
    if (form) {
      form.reset();
      this.setState({ isHierarchyExpanded: false, isFormValid: false });
    }
  }

  isFormValid() {
    if (this.firstName.current || this.lastName.current || this.email.current) {
      if (
        this.firstName.current.value &&
        this.lastName.current.value &&
        this.email.current.value &&
        this.state.securityValue &&
        this.state.routingValue &&
        this.state.afterContactWorkTimeLimit >= 0
      ) {
        this.setState({ isFormValid: true });
      }
    } else {
      this.setState({ isFormValid: false });
    }
  }

  async create_users(userData: any) {
    const c_name = this.federateToken.sub;
    for (let i = 0; i < userData.length; i++) {
      let user = userData[i];

      let securityProfiles = [];
      let splitProfiles = user["security_profile_names"].split("|");
      securityProfiles.push(...splitProfiles);

      let body: any = {
        first_name: user["first_name"].trim(),
        last_name: user["last_name"].trim(),
        email: user["user_login"].trim(),
        security_profiles: securityProfiles,
        routing_profile: user["routing_profile_name"],
        after_contact_work_time_limit: user["acw_timeout"],
        auto_accept: user["softphone_auto_accept"] === "yes" ? true : false,
        file_loaded: false,
        tabledata: null,
      };

      if (user["agent_hierarchy"]) {
        body["agent_hierarchy"] = user["agent_hierarchy"];
      }

      if (authService.isTokenPresent()) {
        this.setState({ isCreatingUser: true });
        userData[i]["status"] = {
          value: "creating",
          message: "Creating user",
        };
        this.setState({ tabledata: userData });
        this.promiseAwsFetchClient
          .then(async (awsClient) => {
            this.awsFetchClient = awsClient;
            try {
              await this.awsFetchClient
                .fetch(
                  appConfig.apigUri +
                    "accessManagement/createUser?p_name=CreateUserEMEA&c_name=" +
                    c_name,
                  {
                    method: "POST",
                    headers: {
                      token: authService.getToken(),
                    },
                    body: JSON.stringify(body),
                  }
                )
                .then((promiseResponse) => {
                  return promiseResponse.json();
                })
                .then((fetchedData) => {
                  this.setState({
                    broadcast: fetchedData,
                    isResponse: true,
                  });
                  if (fetchedData[fetchedData.length - 1] === true) {
                    userData[i]["status"] = {
                      value: "done",
                      message: fetchedData[0],
                    };
                    this.setState({ tabledata: userData });
                  } else {
                    userData[i]["status"] = {
                      value: "error",
                      message: fetchedData[0],
                    };
                    this.setState({ tabledata: userData });
                  }
                });
            } catch (error) {
              errorHandling(error);
            }
          })
          .catch(async (rejectedPromiseObject) => {
            errorHandling(rejectedPromiseObject);
          });
      } else {
        this.setState({ readyToRedirect: true });
      }

      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    this.setState({ isCreatingUser: false, isTemplateDataValid: false });
  }

  create_user() {
    const c_name = this.federateToken.sub;
    this.setState({ isResponse: false, isPostSuccess: false, broadcast: [] });
    let body: any = {
      first_name: this.firstName.current.value.trim(),
      last_name: this.lastName.current.value.trim(),
      email: this.email.current.value.trim().toLowerCase(),
      security_profiles: this.state.securityValue,
      routing_profile: this.state.routingValue,
      after_contact_work_time_limit: this.state.afterContactWorkTimeLimit,
      auto_accept: this.state.auto_accept,
      file_loaded: false,
      tabledata: this.state.tabledata,
    };

    if (this.state.currentHierarchyId) {
      body["hierarchy_id"] = this.state.currentHierarchyId;
    }

    if (this.state.currentHierarchyPath) {
      body["agent_hierarchy"] = this.state.currentHierarchyPath;
    }

    if (authService.isTokenPresent()) {
      this.setState({ isCreatingUser: true });
      this.promiseAwsFetchClient
        .then(async (awsClient) => {
          this.awsFetchClient = awsClient;
          try {
            await this.awsFetchClient
              .fetch(
                appConfig.apigUri +
                  "accessManagement/createUser?p_name=CreateUserEMEA&c_name=" +
                  c_name,
                {
                  method: "POST",
                  headers: {
                    token: authService.getToken(),
                  },
                  body: JSON.stringify(body),
                }
              )
              .then((promiseResponse) => {
                this.setState({ isCreatingUser: false });
                return promiseResponse.json();
              })
              .then((fetchedData) => {
                this.setState(
                  { broadcast: fetchedData, isResponse: true },
                  () => {
                    setTimeout(() => {
                      this.setState(() => ({
                        broadcast: [],
                        isResponse: false,
                      }));
                    }, 20000);
                  }
                );
                if (fetchedData[fetchedData.length - 1] === true) {
                  this.setState({ isPostSuccess: true });
                  this.clearForm();
                } else {
                  this.setState({ isPostSuccess: false });
                }
              });
          } catch (error) {
            errorHandling(error);
          }
        })
        .catch(async (rejectedPromiseObject) => {
          errorHandling(rejectedPromiseObject);
        });
    } else {
      this.setState({ readyToRedirect: true });
    }
  }

  getAgentHierarchy(level: number, path: string) {
    const body = {
      path: path,
    };

    //console.log(`Fetching Hierarchy for level ${level} : Path ${path}`);
    this.promiseAwsFetchClient
      .then(async (awsClient) => {
        this.awsFetchClient = awsClient;
        try {
          await this.awsFetchClient
            .fetch(
              appConfig.apigUri +
                "accessManagement/agentHierarchy?p_name=AgentHierarchyEMEA&c_name=EMEA",
              {
                method: "POST",
                headers: {
                  token: authService.getToken(),
                },
                body: JSON.stringify(body),
              }
            )
            .then((promisedResponse) => {
              return promisedResponse.json();
            })
            .then((fetchedData) => {
              if (level === 1) {
                this.setState({ agentHierarchyLevel1Data: fetchedData.sort() });
              }

              if (level === 2) {
                this.setState({ agentHierarchyLevel2Data: fetchedData.sort() });
              }

              if (level === 3) {
                this.setState({ agentHierarchyLevel3Data: fetchedData.sort() });
              }

              if (level === 4) {
                this.setState({ agentHierarchyLevel4Data: fetchedData.sort() });
              }

              if (level === 5) {
                this.setState({ agentHierarchyLevel5Data: fetchedData.sort() });
              }
            });
        } catch (error) {
          errorHandling(error);
        }
      })
      .catch(async (rejectedPromiseObject) => {
        errorHandling(rejectedPromiseObject);
      });
  }

  handleAgentHierarchyChange(option: any, level: number) {
    if (level === 1) {
      this.currentHierarchy.Level1 = option;
    }
    if (level === 2) {
      this.currentHierarchy.Level2 = option;
    }
    if (level === 3) {
      this.currentHierarchy.Level3 = option;
    }
    if (level === 4) {
      this.currentHierarchy.Level4 = option;
    }
    if (level === 5) {
      this.currentHierarchy.Level5 = option;
    }

    let path = setHierarchyPath(this.currentHierarchy);
    let currentId = this.getHierarchyValue(this.currentHierarchy);
    if (level < 5) {
      this.getAgentHierarchy(level + 1, path);
    }

    this.setState({
      currentHierarchyId: currentId,
      currentHierarchyPath: path,
    });

    this.isFormValid();
  }

  handleAgentHiearchyExpander(value: any) {
    if (this.state.agentHierarchyLevel1Data.length === 0) {
      this.getAgentHierarchy(1, "Level1");
    }
    if (value === false || value === true) {
      this.setState({
        isHierarchyExpanded: value,
      });
    }
  }

  handleAgentHierarchyClear(event: any) {
    this.currentHierarchy = {};
    this.setState({
      agentHierarchyLevel1Data: [],
      agentHierarchyLevel2Data: [],
      agentHierarchyLevel3Data: [],
      agentHierarchyLevel4Data: [],
      agentHierarchyLevel5Data: [],
      currentHierarchyId: "",
      currentHierarchyPath: "",
      isHierarchyExpanded: false,
    });
  }

  getHierarchyValue(hierarchy: AgentHierarchy) {
    let hierarchyValue = "";
    let hierarchyList = [];
    let groupName = "";

    if (hierarchy.Level1) {
      hierarchyList = this.state.agentHierarchyLevel1Data;
      groupName = hierarchy.Level1;
    }
    if (hierarchy.Level2) {
      hierarchyList = this.state.agentHierarchyLevel2Data;
      groupName = hierarchy.Level2;
    }
    if (hierarchy.Level3) {
      hierarchyList = this.state.agentHierarchyLevel3Data;
      groupName = hierarchy.Level3;
    }
    if (hierarchy.Level4) {
      hierarchyList = this.state.agentHierarchyLevel4Data;
      groupName = hierarchy.Level4;
    }
    if (hierarchy.Level5) {
      hierarchyList = this.state.agentHierarchyLevel5Data;
      groupName = hierarchy.Level5;
    }

    let group = hierarchyList.find((item: any) => item.GroupName === groupName);
    if (group) {
      hierarchyValue = group.Id;
    }

    return hierarchyValue;
  }

  agentHierarchyRenderOption(option: any) {
    return <Row gridGap="S200">{option.name}</Row>;
  }

  componentDidMount() {
    const c_name = this.federateToken.EMAIL;
    console.log("Fetching Security Profiles");
    if (authService.isTokenPresent()) {
      this.promiseAwsFetchClient
        .then(async (awsClient) => {
          this.awsFetchClient = awsClient;
          try {
            await this.awsFetchClient
              .fetch(
                appConfig.apigUri +
                  "accessManagement/securityProfiles?p_name=SecurityProfilesEMEA&c_name=" +
                  c_name,
                {
                  method: "GET",
                  headers: {
                    token: authService.getToken(),
                  },
                }
              )
              .then((promiseResponse) => {
                this.setState({ loading: false });
                return promiseResponse.json();
              })
              .then((fetchedData) => {
                this.setState({ securityProfilesList: fetchedData });
              });
          } catch (error) {
            errorHandling(error);
          }
        })
        .catch(async (rejectedPromiseObject) => {
          errorHandling(rejectedPromiseObject);
        });
    } else {
      this.setState({ readyToRedirect: true });
    }
    console.log("Fetching Routing Profiles");
    if (authService.isTokenPresent()) {
      this.promiseAwsFetchClient
        .then(async (awsClient) => {
          this.awsFetchClient = awsClient;
          try {
            await this.awsFetchClient
              .fetch(
                appConfig.apigUri +
                  "accessManagement/routingProfiles?p_name=RoutingProfilesEMEA&c_name=" +
                  c_name,
                {
                  method: "GET",
                  headers: {
                    token: authService.getToken(),
                  },
                }
              )
              .then((promiseResponse) => {
                return promiseResponse.json();
              })
              .then((fetchedData) => {
                this.setState({
                  routingProfilesList: fetchedData.sort(),
                });
              });
          } catch (error) {
            errorHandling(error);
          }
        })
        .catch(async (rejectedPromiseObject) => {
          errorHandling(rejectedPromiseObject);
        });
    } else {
      this.setState({ readyToRedirect: true });
    }
  }

  getHierarchyOptions(data: any) {
    let options = data.map((item: any) => {
      return item.GroupName;
    });

    return options;
  }

  getHierarchySelect(level: string, options: any) {
    return (
      <View key={`ViewHierarchyLevel-${level}`}>
        <InputHeader
          htmlFor={`hierarchy-group-${level}`}
          labelText={`Hierarchy Level ${level}`}
        />
        <Select
          key={`HierarchyLevel-${level}`}
          id={`hierarchy-group-${level}`}
          isMulti={false}
          isSearchable={true}
          labelId={`hierarchy-group-${level}`}
          onChange={(value) => {
            this.handleAgentHierarchyChange(value, parseInt(level));
          }}
          options={this.getHierarchyOptions(options)}
          placeholder={`Select Level ${level}`}
        />
      </View>
    );
  }

  getHierarchyInputList() {
    const selectList = [];

    if (this.state.agentHierarchyLevel1Data.length > 0) {
      selectList.push(
        this.getHierarchySelect("1", this.state.agentHierarchyLevel1Data)
      );
    }

    if (this.currentHierarchy.Level1) {
      selectList.push(
        this.getHierarchySelect("2", this.state.agentHierarchyLevel2Data)
      );
    }
    if (this.currentHierarchy.Level2) {
      selectList.push(
        this.getHierarchySelect("3", this.state.agentHierarchyLevel3Data)
      );
    }
    if (this.currentHierarchy.Level3) {
      selectList.push(
        this.getHierarchySelect("4", this.state.agentHierarchyLevel4Data)
      );
    }
    if (this.currentHierarchy.Level4) {
      selectList.push(
        this.getHierarchySelect("5", this.state.agentHierarchyLevel5Data)
      );
    }

    return selectList;
  }

  getAfterCallWorkInput() {
    if (this.state.afterContactWorkTimeLimit >= 0) {
      return (
        <>
          <InputWrapper id="acw-input-wrapper" labelText="After Call Work">
            {() => (
              <Input
                id="AfterContactWorkTimeLimit"
                type="number"
                onChange={(event) => {
                  this.setState({
                    afterContactWorkTimeLimit: event.target.value,
                  });
                }}
                onBlur={() => {
                  this.isFormValid();
                }}
                defaultValue={0}
                min={0}
              />
            )}
          </InputWrapper>
        </>
      );
    } else {
      return (
        <>
          <InputWrapper
            id="acw-input-wrapper"
            labelText="After Call Work"
            footerText="Required, must be 0 or greater"
            error
          >
            {() => (
              <Input
                id="AfterContactWorkTimeLimit"
                type="number"
                onChange={(event) => {
                  this.setState({
                    afterContactWorkTimeLimit: event.target.value,
                  });
                }}
                onBlur={() => {
                  this.isFormValid();
                }}
                defaultValue={0}
                min={0}
              />
            )}
          </InputWrapper>
        </>
      );
    }
  }

  getCreateUserButton() {
    if (this.state.isCreatingUser) {
      return (
        <>
          <Row gridGap="S400">
            <Button disabled>
              <Spinner
                size={SpinnerSize.Small}
                strokeWidth={9}
                showText
                loadingText="Creating user"
                textColor="neutral100"
                aria-live="assertive"
                aria-busy={this.state.isCreatingUser}
              />
            </Button>
            <Button
              disabled
              isDestructive
              variant={ButtonVariant.Tertiary}
              onClick={() => {
                this.clearForm();
              }}
            >
              Clear Form
            </Button>
          </Row>
        </>
      );
    } else if (this.state.isFormValid) {
      return (
        <>
          <Row gridGap="S400">
            <Button
              variant={ButtonVariant.Primary}
              onClick={() => {
                this.create_user();
              }}
            >
              Create User
            </Button>
            <Button
              isDestructive
              variant={ButtonVariant.Tertiary}
              onClick={() => {
                this.clearForm();
              }}
            >
              Clear Form
            </Button>
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row gridGap="S400">
            <Button disabled>Create User</Button>
            <Button
              isDestructive
              variant={ButtonVariant.Tertiary}
              onClick={() => {
                this.clearForm();
              }}
            >
              Clear Form
            </Button>
          </Row>
        </>
      );
    }
  }

  getCreateUsersButton() {
    if (this.state.isCreatingUser) {
      return (
        <Button disabled>
          <Spinner
            size={SpinnerSize.Small}
            strokeWidth={9}
            showText
            loadingText="Creating users"
            textColor="neutral100"
            aria-live="assertive"
            aria-busy={this.state.isCreatingUser}
          />
        </Button>
      );
    } else if (this.state.isTemplateDataValid) {
      return (
        <>
          <Button
            onClick={() => {
              this.create_users(this.state.tabledata);
            }}
          >
            Create Users
          </Button>
        </>
      );
    } else {
      return <Button disabled>Create Users</Button>;
    }
  }

  getPageHeader() {
    return (
      <>
        <View height={150}>
          <CustomHeader federateToken={this.federateToken} />
          <Col margin={{ left: 155 }}>
            {authService.isTokenPresent() && (
              <div className="link-underline">
                <Avatar
                  imageAltText="badge image"
                  fullName={
                    this.federateToken.FAMILY_NAME +
                    ", " +
                    this.federateToken.GIVEN_NAME
                  }
                  username={this.federateToken.sub}
                  usernameClickable={true}
                  margin={25}
                ></Avatar>
              </div>
            )}
          </Col>
        </View>
      </>
    );
  }

  getCreateUserForm() {
    return (
      <>
        <form id="create-user-form">
          <Row gridGap="S400" justifyContent="space-between">
            <Col>
              <InputWrapper id="firstName-wrapper" labelText="First Name">
                {() => (
                  <Input
                    id="first-name"
                    type="text"
                    onBlur={() => {
                      this.isFormValid();
                    }}
                    ref={this.firstName}
                    placeholder="First name"
                  />
                )}
              </InputWrapper>
            </Col>
            <Col>
              <InputWrapper id="lastName-wrapper" labelText="Last Name">
                {() => (
                  <Input
                    id="last-name"
                    type="text"
                    onBlur={() => {
                      this.isFormValid();
                    }}
                    ref={this.lastName}
                    placeholder="Last name"
                  />
                )}
              </InputWrapper>
            </Col>
            <Col>
              <InputWrapper id="email-wrapper" labelText="Email Address">
                {() => (
                  <Input
                    id="email"
                    type="text"
                    onBlur={() => {
                      this.isFormValid();
                    }}
                    ref={this.email}
                    placeholder="alias@amazon.com"
                  />
                )}
              </InputWrapper>
            </Col>
          </Row>
          <Row gridGap="S400" margin={{ top: 16 }}>
            <Col flex={1}>
              <InputWrapper
                id="security-profiles-wrapper"
                labelText="Select Security Profiles"
              >
                {() => (
                  <Select
                    id="security-profiles"
                    isMulti={true}
                    isSearchable={true}
                    labelId="security-profiles"
                    onChange={(securityValue) => {
                      this.setState({ securityValue: securityValue });
                      this.isFormValid();
                    }}
                    options={this.state.securityProfilesList}
                    value={this.state.securityValue}
                  />
                )}
              </InputWrapper>
            </Col>
          </Row>
          <Row gridGap="S400" margin={{ top: 16 }}>
            <Col flex={1}>
              <InputWrapper
                id="routing-profiles-wrapper"
                labelText="Select Routing Profile"
              >
                {() => (
                  <Select
                    id="routing-profiles"
                    isMulti={false}
                    isSearchable={true}
                    labelId="routing-profiles"
                    onChange={(routingValue) => {
                      this.setState({ routingValue: routingValue });
                      this.isFormValid();
                    }}
                    options={this.state.routingProfilesList}
                    value={this.state.routingValue}
                  />
                )}
              </InputWrapper>
            </Col>
          </Row>
          <Row
            gridGap="S400"
            margin={{ top: 16 }}
            justifyContent="space-between"
          >
            <Col gridGap="S200">{this.getAfterCallWorkInput()}</Col>
            <Col gridGap="S200">
              <InputWrapper id="AutoAccept-Wrapper" labelText="Auto Accept">
                {() => (
                  <Checkbox
                    id="auto-accept"
                    onChange={(event) => {
                      this.setState({
                        auto_accept: event.target.value,
                      });
                      this.isFormValid();
                    }}
                    onBlur={() => {
                      this.isFormValid();
                    }}
                  />
                )}
              </InputWrapper>
            </Col>
            <Col gridGap="S200">
              <InputWrapper
                id="AgentHierarchyPath-Wrapper"
                labelText="Agent Hierarchy Path"
              >
                {() => (
                  <Row>
                    <Input
                      id="AgentHierarchyPath"
                      type="text"
                      readOnly
                      value={this.state.currentHierarchyPath}
                      size={36}
                    />
                    <Button
                      icon={<IconBin title="Delete item" />}
                      isDestructive
                      size={ButtonSize.Small}
                      variant={ButtonVariant.Tertiary}
                      onClick={this.handleAgentHierarchyClear}
                    />
                  </Row>
                )}
              </InputWrapper>
            </Col>
          </Row>
          <View margin={{ top: 16 }}>
            <Expander
              isExpanded={this.state.isHierarchyExpanded}
              titleText="Agent Hiearchy (Optional)"
              onToggle={this.handleAgentHiearchyExpander}
            >
              <Col gridGap="S400" flexWrap="wrap">
                {this.getHierarchyInputList()}
              </Col>
            </Expander>
          </View>
          <Row gridGap="S400" margin={{ top: 32 }}>
            {this.getCreateUserButton()}
            {this.state.isResponse && this.state.isPostSuccess && (
              <MessageBanner
                iconAltText="Successful"
                type={MessageBannerType.Success}
                title="Update successful"
                isDismissible
              >
                {this.state.broadcast}
              </MessageBanner>
            )}
            {this.state.isResponse && !this.state.isPostSuccess && (
              <MessageBanner
                iconAltText="Error"
                type={MessageBannerType.Error}
                title="Update Error"
                isDismissible
              >
                {this.state.broadcast}
              </MessageBanner>
            )}
          </Row>
        </form>
      </>
    );
  }

  getTemplateForm() {
    return (
      <>
        <UserManagementInfo></UserManagementInfo>
        <Row gridGap={"S100"} justifyContent="flex-end" width="100%">
          <View display="flex" justifyContent="center" alignItems="center">
            <label htmlFor="file-upload" className="custom-file-upload">
              <IconUpload title="Upload Template" />
              Upload template
            </label>

            <input
              type="file"
              id="file-upload"
              name="file-upload"
              onChange={this.handleFileInputChange}
              aria-label="Choose file to upload"
              className={"file-upload"}
            ></input>
          </View>
          <Button
            icon={<IconDownload title="download" />}
            variant={ButtonVariant.Tertiary}
            onClick={() => downloadTemplate()}
          >
            Download Template
          </Button>
        </Row>
        <br />
        {this.state.file_loaded && (
          <StencilProvider
            theme={{
              ...HR2021Theme,
            }}
          >
            <br />
            <Table
              columns={[
                {
                  accessor: "first_name",
                  header: "First Name",
                },
                {
                  accessor: "last_name",
                  header: "Last Name",
                },
                {
                  accessor: "user_login",
                  header: "User Login",
                },
                {
                  accessor: "routing_profile_name",
                  header: "Routing Profile",
                },
                {
                  accessor: "security_profile_names",
                  header: "Security Profile",
                },
                {
                  accessor: "softphone_auto_accept",
                  header: "Auto Accept",
                },
                {
                  accessor: "acw_timeout",
                  header: "ACW Timeout",
                },
                {
                  accessor: "agent_hierarchy",
                  header: "Agent Hiearachy",
                },
                {
                  header: "Status",
                  cellComponent: ({ data }) => getStatusIndicator(data.status),
                },
              ]}
              spacing={TableSpacing.Reduced}
              isStriped={true}
              data={this.fileData}
            />
            <br />
          </StencilProvider>
        )}
        <br />
        {this.state.file_loaded && this.getCreateUsersButton()}
      </>
    );
  }

  render() {
    if (!authService.isUserAuthenticated() && this.federateToken === null) {
      return <SignedOut allowance={true} />;
    }

    if (this.state.readyToRedirect) {
      return <SignedOut allowance={true} />;
    }

    if (
      !containsLdapElement(this.federateToken.LDAPGROUP, LDAP[9].toString())
    ) {
      return <Forbidden federateToken={this.federateToken} allowance={true} />;
    }

    return (
      <StencilProvider
        theme={{
          ...HR2021Theme,
        }}
      >
        {this.getPageHeader()}
        <PageContainer
          alignItems="center"
          centered={true}
          backgroundColor="primary05"
          paddingHorizontal="S600"
        >
          <H1 id="heading" textAlign="center">
            Connect User Management Europe Middle East
          </H1>
          {this.state.loading && (
            <LoadingSpinner loadingState={this.state.loading} />
          )}
          {!this.state.loading && (
            <Col gridGap={32} alignItems="center" margin={{ top: 16 }}>
              <TabBar
                layout={TabsLayout.Default}
                onTabSelect={(tabValue) => {
                  this.setState({
                    tabValue: tabValue,
                    broadcast: [],
                    isResponse: false,
                  });
                }}
                selectedTab={this.state.tabValue}
                shouldActivateManually={false}
                tabGroup="TabGroup-2"
                tabs={tabs}
              />
              <TabSwitcher
                selectedTab={this.state.tabValue}
                tabGroup="TabGroup-3"
              >
                <TabPanel value="Create and set up a new user.">
                  {this.getCreateUserForm()}
                </TabPanel>
                <TabPanel value="Upload my users from a template (csv).">
                  {this.getTemplateForm()}
                </TabPanel>
                <TabPanel value="Audit Trails">
                  <UserManagementAuditTrails region="EMEA" />
                </TabPanel>
              </TabSwitcher>
            </Col>
          )}
        </PageContainer>
        <CustomFooter />
      </StencilProvider>
    );
  }
}
