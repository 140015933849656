import React from 'react';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { H1, H2, P, Text } from '@amzn/stencil-react-components/text';
import { PageContainer } from '@amzn/stencil-react-components/page';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { Table, TableSpacing } from '@amzn/stencil-react-components/table';
import { Avatar } from '@amzn/stencil-react-components/avatar';
import {
    FormWrapper,
    InputWrapper,
    Select,
    TextArea,
    TextAreaWithRecommendedLength
} from '@amzn/stencil-react-components/form';
import { FederateData, PageProps } from '../models/FederateData';
import { HR2021Theme } from '@amzn/stencil-react-theme-default';
import AuthService from '../services/AuthService';
import CustomHeader, { ButtonWithTooltip, IconWithTooltip } from '../components/CustomHeader';
import { Spinner } from '@amzn/stencil-react-components/spinner';
import SignedOut from './SignedOut';
import Forbidden from './Forbidden';
import 'react-phone-input-2/lib/style.css';
import { TabBar, TabPanel, TabsLayout, TabSwitcher } from '@amzn/stencil-react-components/tabs';
import errorHandling from '../components/ErrorHandler';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { getCredentials } from '../services/aws.service';
import { AwsClient } from 'aws4fetch';
import { AppConfig } from '../models/AppConfig';
import CustomError from '../models/CustomError';
import { cssPropertiesTable } from '../models/TableCustomCSS';
import '../App.css';
import { MainWithSkipLink } from '@amzn/stencil-react-components/a11y';
import PhoneInput from 'react-phone-input-2';
import { Button, ButtonIconPosition, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import {
    getColumnsChangelogMSS,
    getColumnsManual,
    getRowAttributesCL,
    renderCardForRowManual
} from '../models/SelfServiceTableData';
import CustomBackToTop from '../components/CustomBackToTop';
import CustomFooter from '../components/CustomFooter';
import {
    IconArrowRight,
    IconBin,
    IconChevronRight,
    IconCross,
    IconDownload,
    IconExternalLink,
    IconReset,
    IconSize
} from '@amzn/stencil-react-components/icons';
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal';
import { tabs } from '../models/CustomTabs';
import { modalInstructionsMS } from '../models/Instructions';
import { Popover, PopoverPosition } from '@amzn/stencil-react-components/popover';
import { CustomErrorBanner, CustomWarningBanner } from '../components/CustomBanner';
import { PopupDateRangePicker } from '@amzn/stencil-react-components/date-picker';
import { Link } from '@amzn/stencil-react-components/link';
import { Redirect } from 'react-router-dom';
import { ManualSelfServiceState, ManualTableData } from '../models/ManualSelfServiceState';
import {
    getBusinessGroups,
    getBusinessGroupsForAdd,
    isAddEnabled,
    isDeleteEnabled,
    isDeleteEnabledForBG
} from '../services/FilterServices';
import { Expander } from '@amzn/stencil-react-components/expander';
import { containsLdapElement, LDAP } from '../models/LDAP';
import { convertToTimestamp, isBlank } from '../services/FilterServices';
import { TooltipAlignment, TOOLTIP_POSITION } from '@amzn/stencil-react-components/tooltip';


var FileSaver = require('file-saver');
const Diff = require('diff');
const authService: AuthService = new AuthService();
const appConfig: AppConfig = require('../components/config.json')[`${process.env.REACT_APP_ENVIRONMENT}`];


export default class MTSS extends React.Component<PageProps, ManualSelfServiceState> {
    isAddVisible: boolean = false;
    businessGroupList: string[] = [];
    businessGroupListForAdd: string[] = [];
    businessGroupListForAuditTrails: string[] = [];
    isDeleteVisible: boolean = false;
    isDeleteEnabledForBG: boolean = false;
    businessGroup: string = '';
    templateName: string = '';
    awsFetchClient: AwsClient | undefined;
    totalCount1: number = 0;
    totalCount2: number = 0;
    filters1: string[] = [];
    filters2: string[] = [];
    valueOfAT: string[] = [];
    federateToken: FederateData = authService.isTokenPresent() ? authService.getDecodedToken() : window.location.href = '/auth';
    auditFilterURI: string = '';
    promiseAwsFetchClient: Promise<AwsClient> = getCredentials();

    state: ManualSelfServiceState = {
        fetchedDataForDropdown: [],
        fetchedDataForDropdownText: [],
        fetchedDataForTable: [],
        fetchedChangelogData: [],
        isOpen: false,
        isOpenAdd: false,
        loading: true,
        isPostError: '',
        isPostSuccess: false,
        isPostErrorSMS: '',
        isPostSuccessSMS: false,
        readyToRedirect: false,
        phone: '',
        tabValue: tabs[0],
        selectedPage1: 1,
        selectedPage2: 1,
        filteredValue: [],
        filteredValueChangelog: [],
        startDate: '',
        endDate: '',
        isExpanded: false
    };

    onResetFilterMainContent() {
        this.setState(prev => ({ ...prev, filteredValue: [] }));
        this.filters1 = [];


    }

    onResetFilterAuditTrails() {
        this.setState(prev => ({ ...prev, filteredValueChangelog: [] }));
        this.filters2 = [];
    }

    tableData: ManualTableData = {
        BusinessGroup: '',
        TemplateName: '',
        Message: '',
        comment: ''
    };

    updatedTableData: ManualTableData = {
        BusinessGroup: '',
        TemplateName: '',
        Message: '',
        comment: ''
    };

    setLoadingValue() {
        this.setState({ loading: true });
    }

    componentDidMount() {
        document.title = ' Manual Text Self-Service ';
        if (authService.isTokenPresent()) {
            this.getTableData(false, false, 1, []);
            this.getTableDataForChangelog(false, false, 1, [], this.state.startDate, this.state.endDate);
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        appConfig.apigUri + 'product?name=ManualTextService',
                        {
                            method: 'GET',
                            headers: {
                                'token': authService.getToken()
                            }
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              return <Redirect to='/SignedOut' />;
                          }
                          this.businessGroupListForAuditTrails = fetchedData.businessGroups;
                          fetchedData = fetchedData.results;
                          this.isAddVisible = isAddEnabled(fetchedData);
                          this.isDeleteVisible = isDeleteEnabled(fetchedData);
                          this.businessGroupList = getBusinessGroups(fetchedData);
                          this.businessGroupListForAdd = getBusinessGroupsForAdd(fetchedData);
                          this.setState({ fetchedDataForDropdown: fetchedData });
                      });

                  } catch (error) {
                      errorHandling(error);
                      this.setState({ loading: false });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    getTableData(isLoading: boolean, isFiltered: boolean, pageNumber: number, businessArray: string[]) {
        if (isLoading) {
            this.setLoadingValue();
        }

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        !isFiltered ? appConfig.apigUri + 'component?p_name=ManualTextService&page=' + pageNumber : appConfig.apigUri + 'component?p_name=ManualTextService&c_name=' + businessArray.toString() + '&page=' + pageNumber,
                        {
                            method: 'GET',
                            headers: {
                                'token': authService.getToken()
                            }
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              return <Redirect to='/SignedOut' />;
                          }
                          if (pageNumber === 1) {
                              this.totalCount1 = fetchedData.count;
                              fetchedData = fetchedData.results;
                              this.setState({ selectedPage1: 1 });
                          }

                          this.setState({ fetchedDataForTable: fetchedData, loading: false });
                      });

                  } catch (error) {
                      errorHandling(error);
                      this.setState({ loading: false });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    updateTableData(tableData: ManualTableData) {
        this.setLoadingValue();

        const body = {
            'templateName': this.tableData.TemplateName,
            'prevMessage': this.tableData.Message,
            'message': tableData.Message,
            'comment': tableData.comment,
            'user': this.federateToken.sub
        };
        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        appConfig.apigUri + 'component?p_name=ManualTextService&c_name=' + this.businessGroup.toString(),
                        {
                            method: 'PATCH',
                            headers: {
                                'token': authService.getToken()
                            },
                            body: JSON.stringify(body)
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              throw new CustomError(fetchedData.Error);
                          }
                          if (fetchedData.results !== undefined) {
                              this.totalCount1 = fetchedData.count;
                              fetchedData = fetchedData.results;
                          }
                          this.onResetFilterMainContent();
                          this.setState({
                              fetchedDataForTable: fetchedData,
                              loading: false,
                              isPostSuccess: true,
                              isPostError: '',
                              selectedPage1: 1
                          });
                          this.updatedTableData = {
                              BusinessGroup: '',
                              TemplateName: '',
                              Message: '',
                              comment: ''
                          };
                      });
                  } catch (error: any) {
                      errorHandling(error);
                      this.setState({
                          loading: false,
                          isPostError: error.message,
                          isPostSuccess: false
                      });
                      this.updatedTableData = {
                          BusinessGroup: '',
                          TemplateName: '',
                          Message: '',
                          comment: ''
                      };
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    deleteTableData(tableData: ManualTableData) {
        this.setLoadingValue();

        const body = {
            'templateName': this.tableData.TemplateName,
            'prevMessage': this.tableData.Message,
            'comment': tableData.comment,
            'user': this.federateToken.sub
        };

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        appConfig.apigUri + 'component?p_name=ManualTextService&c_name=' + this.businessGroup.toString(),
                        {
                            method: 'DELETE',
                            headers: {
                                'token': authService.getToken()
                            },
                            body: JSON.stringify(body)
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              throw new CustomError(fetchedData.Error);
                          }
                          if (fetchedData.results !== undefined) {
                              this.totalCount1 = fetchedData.count;
                              fetchedData = fetchedData.results;
                          }
                          this.onResetFilterMainContent();
                          this.setState({
                              fetchedDataForTable: fetchedData,
                              loading: false,
                              isPostSuccess: true,
                              isPostError: '',
                              selectedPage1: 1
                          });
                          this.updatedTableData = {
                              BusinessGroup: '',
                              TemplateName: '',
                              Message: '',
                              comment: ''
                          };
                      });
                  } catch (error: any) {
                      errorHandling(error);
                      this.setState({
                          loading: false,
                          isPostError: error.message,
                          isPostSuccess: false
                      });
                      this.updatedTableData = {
                          BusinessGroup: '',
                          TemplateName: '',
                          Message: '',
                          comment: ''
                      };
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    addTableData(tableData: ManualTableData) {
        this.setLoadingValue();

        const body = {
            'templateName': tableData.TemplateName,
            'message': tableData.Message,
            'comment': tableData.comment,
            'user': this.federateToken.sub
        };

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        appConfig.apigUri + 'component?p_name=ManualTextService&c_name=' + this.businessGroup.toString(),
                        {
                            method: 'POST',
                            headers: {
                                'token': authService.getToken()
                            },
                            body: JSON.stringify(body)
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              throw new CustomError(fetchedData.Error);
                          }
                          if (fetchedData.results !== undefined) {
                              this.totalCount1 = fetchedData.count;
                              fetchedData = fetchedData.results;
                          }
                          this.onResetFilterMainContent();
                          this.setState({
                              fetchedDataForTable: fetchedData,
                              loading: false,
                              isPostSuccess: true,
                              isPostError: '',
                              selectedPage1: 1
                          });
                          this.updatedTableData = {
                              BusinessGroup: '',
                              TemplateName: '',
                              Message: '',
                              comment: ''
                          };
                      });
                  } catch (error: any) {
                      errorHandling(error);
                      this.setState({
                          loading: false,
                          isPostError: error.message,
                          isPostSuccess: false
                      });
                      this.updatedTableData = {
                          BusinessGroup: '',
                          TemplateName: '',
                          Message: '',
                          comment: ''
                      };
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    getTableDataForChangelog(isLoading: boolean, isFiltered: boolean, pageNumber: number, businessArray: string[], startDate: string | undefined, endDate: string | undefined) {
        if (isLoading) {
            this.setLoadingValue();
        }

        startDate = convertToTimestamp(startDate);
        endDate = convertToTimestamp(endDate);

        this.auditFilterURI = isBlank(startDate) ? isBlank(startDate) && !isBlank(endDate) ? '&end_timestamp=' + endDate : '' :
          this.auditFilterURI = isBlank(endDate) ? !isBlank(startDate) && isBlank(endDate) ? '&start_timestamp=' + startDate : '' :
            '&start_timestamp=' + startDate + '&end_timestamp=' + endDate;

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        !isFiltered ? appConfig.apigUriAudit + 'audit_trails?name=ManualTextService&page=' + pageNumber :
                          appConfig.apigUriAudit + 'audit_trails?name=ManualTextService&filter_by=' + businessArray.toString() + '&page=' + pageNumber + this.auditFilterURI,
                        {
                            method: 'GET',
                            headers: {
                                'token': authService.getToken()
                            }
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then(async (fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              throw new CustomError(fetchedData.Error);
                          }

                          if (pageNumber === 1) {
                              this.totalCount2 = fetchedData.count;
                              fetchedData = fetchedData.results;
                          }

                          fetchedData = await fetchedData.filter((element: any) => element.diffText = (Diff.diffChars(element.PreviousState === undefined ? '' : element.PreviousState,
                            element.CurrentState === undefined ? '' : element.CurrentState)));
                          fetchedData.filter((element: any): void | null => {
                              let str: string = '<pre style="line-height: 1.5em;font-size: 15px">';
                              element.diffText.filter((innerElement: any): void | null => {
                                  const screenReaderAction = innerElement.added ? '<span class="screen-reader"> Current characters </span>' : innerElement.removed ? '<span class="screen-reader"> Previous characters </span>' : '<span class="screen-reader"> Unchanged characters </span>';
                                  const backgroundColor = innerElement.added ? '#86dac9' : innerElement.removed ? '#f8a49e' : 'white';
                                  const action = innerElement.added ? 'ins' : innerElement.removed ? 'del' : 'span';
                                  str += (screenReaderAction + '<' + action + ' style="background-color:' + backgroundColor + ';">' + innerElement.value + '</' + action + '>');
                                  return null;
                              }, '');
                              str += '</pre>';
                              element.diffText = str;
                              return null;
                          }, '');
                          this.setState({ fetchedChangelogData: fetchedData, loading: false });
                      });
                  } catch (error) {
                      errorHandling(error);
                      this.setState({ loading: false });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    getChangelogCSV(isLoading: boolean, businessArray: string[], startDate: string | undefined, endDate: string | undefined) {
        if (isLoading) {
            this.setLoadingValue();
        }

        startDate = convertToTimestamp(startDate);
        endDate = convertToTimestamp(endDate);

        const isFiltered: boolean = businessArray.length === 0 && isBlank(startDate) && isBlank(endDate)

        this.auditFilterURI = isBlank(startDate) ? isBlank(startDate) && !isBlank(endDate) ? '&end_timestamp=' + endDate : '' :
          this.auditFilterURI = isBlank(endDate) ? !isBlank(startDate) && isBlank(endDate) ? '&start_timestamp=' + startDate : '' :
            '&start_timestamp=' + startDate + '&end_timestamp=' + endDate;

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        isFiltered ? appConfig.apigUriAudit + 'audit_trails?name=ManualTextService&page=1&is_csv_requested=true' :
                          appConfig.apigUriAudit + 'audit_trails?name=ManualTextService&page=1&is_csv_requested=true&filter_by=' + businessArray.toString() + this.auditFilterURI,
                        {
                            method: 'GET',
                            headers: {
                                'token': authService.getToken()
                            }
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then(async (fetchedData) => {
                          if (fetchedData.Error !== undefined) {
                              throw new CustomError(fetchedData.Error);
                          }

                          this.setState({ loading: false });

                          FileSaver.saveAs(new Blob([fetchedData], { type: 'text/csv;charset=utf-8' }), 'ManualTextService-' + new Date().toISOString().split('T')[0] +
                            '_' + new Date().toLocaleTimeString('en-GB') + '.csv');
                      });
                  } catch (error) {
                      errorHandling(error);
                      this.setState({ loading: false });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    sendSMS(SMSBody: string, PhoneNumber: string) {
        this.setLoadingValue();

        const body = {
            'SMSBody': SMSBody,
            'PhoneNumber': PhoneNumber
        };

        if (authService.isTokenPresent()) {
            this.promiseAwsFetchClient.then(async (awsClient) => {
                  this.awsFetchClient = awsClient;
                  try {
                      await this.awsFetchClient.fetch(
                        appConfig.apigUriSMS + 'sendSMS',
                        {
                            method: 'POST',
                            body: JSON.stringify(body)
                        }
                      ).then((promiseResponse) => {
                            return promiseResponse.json();
                        }
                      ).then((fetchedData) => {
                          if (fetchedData !== 'Message Delivery status : SUCCESSFUL') {
                              throw new CustomError(fetchedData);
                          }
                          this.setState({ loading: false, isPostSuccessSMS: true });
                      });
                  } catch (error: any) {
                      errorHandling(error);
                      this.setState({ loading: false, isPostErrorSMS: error.message });
                  }
              }
            ).catch(async (rejectedPromiseObject) => {
                  errorHandling(rejectedPromiseObject);
              }
            );
        } else {
            this.setState({ readyToRedirect: true });
        }
    }

    handleUpdate = (event: any) => {
        event.preventDefault();
        this.updateTableData(this.updatedTableData);
    };

    handleDelete = (event: any) => {
        event.preventDefault();
        this.deleteTableData(this.updatedTableData);
    };

    handleAdd = (event: any) => {
        event.preventDefault();
        this.addTableData(this.updatedTableData);
    };

    handleSubmitSMS = (event: any, SMSBody: string, PhoneNumber: string) => {
        event.preventDefault();
        this.sendSMS(SMSBody, PhoneNumber);
    };

    close = (clearFields: boolean) => {
        if (clearFields)
            this.updatedTableData.comment = '';
        this.setState({ isOpen: false });
    };

    closeAdd = (clearFields: boolean) => {
        if (clearFields) {
            this.updatedTableData = {
                BusinessGroup: '',
                TemplateName: '',
                Message: '',
                comment: ''
            };
        }
        this.setState({ isOpenAdd: false });
    };

    checkBlankTextForEdit = () => {
        if (this.updatedTableData.Message.trim() === '') {
            alert('The Message can not be empty.');
            return true;
        }

        if (this.updatedTableData.Message.trim() === this.tableData.Message) {
            alert('No Changes were made to the Message, please make edits.');
            return true;
        }

        if (this.updatedTableData.comment.trim() === '') {
            alert('Comments are mandatory, please leave a comment.');
            return true;
        }
    };

    checkBlankTextForDelete = () => {
        if (this.updatedTableData.comment.trim() === '') {
            alert('Comments are mandatory, please leave a comment.');
            return true;
        }
    };

    checkBlankTextForAdd = () => {
        if (this.updatedTableData.BusinessGroup === '') {
            alert('The Business Group can not be empty.');
            return true;
        }

        if (this.updatedTableData.TemplateName.trim() === '') {
            alert('The Template Name can not be empty.');
            return true;
        }

        if (this.updatedTableData.Message.trim() === '') {
            alert('The Message can not be empty.');
            return true;
        }

        if (this.updatedTableData.comment.trim() === '') {
            alert('Comments are mandatory, please leave a comment.');
            return true;
        }
    };

    getModalForAdd() {
        return (
          <Modal isScrollable={false} shouldCloseOnClickOutside={false} isOpen={this.state.isOpenAdd}
                 close={() => this.closeAdd(true)} aria-labelledby='Add'>
              <ModalContent
                aria-label='Add Message Details'
                maxWidth={680}
                buttons={[
                    <Button
                      variant={ButtonVariant.Primary}
                      onClick={(event) => {
                          if (!this.checkBlankTextForAdd()) {
                              this.setState({
                                  isPostSuccessSMS: false,
                                  isPostErrorSMS: '',
                                  phone: '',
                                  isPostSuccess: false
                              });
                              this.closeAdd(false);
                              this.handleAdd(event);
                          }
                      }}
                      type='submit'> Submit </Button>,
                    <Button
                      onClick={() => {
                          this.setState({ isPostSuccessSMS: false, isPostErrorSMS: '', phone: '' });
                          this.updatedTableData = {
                              BusinessGroup: '',
                              TemplateName: '',
                              Message: '',
                              comment: ''
                          };
                          this.closeAdd(true);
                      }}
                      key='Close'
                      icon={<IconCross aria-hidden={true} size={IconSize.ExtraSmall} />}
                      isDestructive
                      variant={ButtonVariant.Tertiary}>
                        Discard
                    </Button>
                ]}>
                  <H2 id='Edit' textAlign='center' fontSize='T400'> Add Entry </H2>
                  <br />
                  {modalInstructionsMS()}
                  {CustomWarningBanner()}
                  <br />
                  <FormWrapper width={600} height={500}>
                      <Link rel='noopener noreferrer' target='_blank' margin={[13, 20, 20]} textAlign='center'
                            fontSize='T200'
                            href='https://w.amazon.com/bin/view/CCCMS_Intake/'>
                          Create Intake Request to Add New Business Group <IconExternalLink size={IconSize.Small}
                                                                                            display='inline'
                                                                                            target='_blank'
                                                                                            title='Opens in a new tab'
                                                                                            aria-details='Opens in a new tab' />
                      </Link>
                      <InputWrapper id='business-selection'
                                    labelText='Business'
                                    footerText='Choose the Business Group here'>
                          {() => <Select
                            aria-label='Choose the Business Group here'
                            searchFieldAriaLabel='Choose the Business Group here'
                            aria-describedby='business-selection'
                            options={this.businessGroupListForAdd}
                            onChange={value => {
                                this.updatedTableData.BusinessGroup = value;
                                this.businessGroup = value;
                            }}
                          />}
                      </InputWrapper>

                      <InputWrapper id='text-template'
                                    labelText='Text Template'
                                    footerText='Enter the Text Template here'>
                          {(textAreaProps) => <TextArea
                            aria-label='Enter the Text Template here'
                            height={45}
                            onChange={event => {
                                this.updatedTableData.TemplateName = event.target.value;
                                this.templateName = event.target.value;
                            }}
                            {...textAreaProps}
                          />}
                      </InputWrapper>

                      <InputWrapper id='text-message'
                                    labelText='Text Message'
                                    footerText='Enter the Text Message here'>
                          {(textAreaProps) => <TextAreaWithRecommendedLength
                            recommendedCharacterCount={160}
                            renderRecommendedCharactersText={({ characterCount }) => `Character count: ${characterCount}`}
                            renderExceededCharactersText={({ characterCount }) => `Character count: ${characterCount} - Possible Number of Messages: ${Math.ceil(characterCount / 153)}`}
                            height={110}
                            resize='vertical'
                            onChange={(event) => {
                                this.updatedTableData.Message = event.target.value;
                            }}
                            {...textAreaProps}
                          />}
                      </InputWrapper>
                      <Row padding='S200' gridGap='S400' justifyContent='center'>
                          <IconWithTooltip
                            title='Show tooltip'
                            tooltipText='Click to open the Preview SMS Pop-Up to "Send" and "Test" the above Message text to your cellular devices.'
                            color='primary60'
                          />
                          <Popover triggerText='Preview SMS'
                                   shouldFocusOnOpen={true}
                                   maxWidth='32vw'
                                   shouldCloseOnFocusLeave={false}
                                   position={PopoverPosition.BottomCenter}>
                              {({ close }) => (
                                <Col gridGap='S200' padding='S300' flexWrap='wrap'>
                                    <P>
                                        Enter the phone number below in the area provided
                                        and click 'Send SMS' to send the SMS Text.
                                    </P>
                                    {
                                        this.state.loading &&
                                        <StencilProvider theme={{
                                            ...HR2021Theme
                                        }}>
                                          <Col gridGap={30} aria-live='assertive' aria-busy={this.state.loading}>
                                            <Spinner loadingText='Dispatching SMS...' size={40} strokeWidth={5}
                                                     showText textColor='neutral70' aria-live='assertive'
                                                     aria-busy={this.state.loading} />
                                          </Col>
                                          <br />
                                        </StencilProvider>
                                    }
                                    <FormWrapper padding='S300' maxWidth={450} width={450}>
                                        {
                                            this.state.isPostSuccessSMS &&
                                            <MessageBanner iconAltText='Successful'
                                                           type={MessageBannerType.Success}
                                                           isDismissible
                                                           onDismissed={() => {
                                                               this.setState({ isPostSuccessSMS: false });
                                                           }}>
                                              The SMS is sent successfully.
                                            </MessageBanner>
                                        }
                                        {
                                            this.state.isPostErrorSMS !== '' &&
                                            <MessageBanner iconAltText='Failure'
                                                           type={MessageBannerType.Error}
                                                           isDismissible
                                                           onDismissed={() => this.setState({ isPostErrorSMS: '' })}>
                                              There seems to be an issue - {this.state.isPostErrorSMS}
                                            </MessageBanner>
                                        }
                                        <PhoneInput
                                          inputProps={{
                                              name: 'phone',
                                              required: true,
                                              autoFocus: true
                                          }}
                                          preferredCountries={['us']}
                                          country={'us'}
                                          placeholder='Enter phone number'
                                          value={this.state.phone}
                                          onChange={(value: any) => this.setState({ phone: value })}
                                        />
                                    </FormWrapper>
                                    <Row gridGap='S300' justifyContent='flex-end'>
                                        <Button
                                          size={ButtonSize.Small}
                                          type='submit'
                                          onClick={(event) => {
                                              this.handleSubmitSMS(event, this.updatedTableData.Message, this.state.phone);
                                          }}>
                                            Send SMS
                                        </Button>
                                        <Button icon={<IconCross aria-hidden={true} size={IconSize.ExtraSmall} />}
                                                variant={ButtonVariant.Tertiary} size={ButtonSize.Small} isDestructive
                                                onClick={close}>
                                            Close Popup
                                        </Button>
                                    </Row>
                                </Col>
                              )}
                          </Popover>
                      </Row>
                      <InputWrapper id='text-preview-2'
                                    labelText='Comments: Why the changes were made?'
                                    footerText='Ex: Changed Class M option 1 SMS to reflect new time-off regulations that went into place on 4/5.'>
                          {(textAreaProps) => <TextAreaWithRecommendedLength
                            resize='vertical'
                            recommendedWordCount={30}
                            defaultValue={this.tableData.comment}
                            onChange={(event) => {
                                this.updatedTableData.comment = event.target.value;
                            }}
                            {...textAreaProps} />}
                      </InputWrapper>
                  </FormWrapper>
              </ModalContent>
          </Modal>
        );
    };

    getModalForEditDelete() {
        return (
          <>
              <div style={cssPropertiesTable}>
                  <Table isStriped={true} getRowAttributes={this.getRowAttributes}
                         aria-labelledby='heading'
                         renderCardForRow={renderCardForRowManual}
                         columns={getColumnsManual}
                         data={this.state.fetchedDataForTable} actionHeader={'Edit'}
                         spacing={TableSpacing.Reduced}
                         shouldAutoCollapseOnExpand={true} shouldScrollHorizontally={true} />
              </div>
              <Modal isScrollable={false} shouldCloseOnClickOutside={false} isOpen={this.state.isOpen}
                     close={() => this.close(true)} aria-labelledby='Edit-or-Delete'>
                  <ModalContent
                    aria-label='Edit or Delete Message Details'
                    maxWidth={830}
                    buttons={[
                        this.isDeleteEnabledForBG &&
                        <Button
                          variant={ButtonVariant.Primary}
                          isDestructive
                          onClick={(event) => {
                              // eslint-disable-next-line no-restricted-globals
                              if (!this.checkBlankTextForDelete() && confirm('The ' + this.tableData.BusinessGroup + ' [ ' + this.tableData.TemplateName + ' ] record will be deleted forever')) {
                                  this.setState({ isPostSuccessSMS: false, isPostErrorSMS: '', phone: '' });
                                  this.close(false);
                                  this.businessGroup = this.tableData.BusinessGroup;
                                  this.templateName = this.tableData.TemplateName;
                                  this.handleDelete(event);
                              }
                          }}
                          type='submit'
                          iconPosition={ButtonIconPosition.Trailing}
                          icon={<IconBin aria-hidden={true} />}>
                          Delete Entry
                          &nbsp;
                        </Button>,
                        <Button
                          variant={ButtonVariant.Primary}
                          onClick={(event) => {
                              if (!this.checkBlankTextForEdit()) {
                                  this.setState({ isPostSuccessSMS: false, isPostErrorSMS: '', phone: '' });
                                  this.close(false);
                                  this.businessGroup = this.tableData.BusinessGroup;
                                  this.templateName = this.tableData.TemplateName;
                                  this.handleUpdate(event);
                              }
                          }}
                          type='submit'> Submit Updates </Button>,
                        <Button
                          onClick={() => {
                              this.setState({ isPostSuccessSMS: false, isPostErrorSMS: '', phone: '' });
                              this.updatedTableData.comment = '';
                              this.close(true);
                          }}
                          key='Close'
                          isDestructive
                          variant={ButtonVariant.Tertiary}
                          icon={<IconCross aria-hidden={true} size={IconSize.ExtraSmall} />}>
                            Discard
                        </Button>
                    ]}>
                      {
                          (this.isDeleteEnabledForBG) ? (
                            <H2 id='Edit' textAlign='center' fontSize='T400'> Edit or Delete Entry </H2>
                          ) : (
                            <H2 id='Edit' textAlign='center' fontSize='T400'> Edit Entry </H2>
                          )
                      }
                      <br />
                      <H2 id='Edit' fontSize='T300'> Business Group : {this.tableData.BusinessGroup} </H2>
                      <H2 id='Edit' fontSize='T300'> Text Template : {this.tableData.TemplateName} </H2>
                      {modalInstructionsMS()}
                      {
                          this.isDeleteEnabledForBG && CustomErrorBanner()
                      }
                      <FormWrapper
                        padding='S500' maxWidth={750} width={750}>

                          <InputWrapper id='text-preview-1'
                                        labelText='Message'
                                        footerText='Enter the updated Message here'>
                              {(textAreaProps) => <TextAreaWithRecommendedLength
                                recommendedCharacterCount={160}
                                renderRecommendedCharactersText={({ characterCount }) => `Character count: ${characterCount}`}
                                renderExceededCharactersText={({ characterCount }) => `Character count: ${characterCount} - Possible Number of Messages: ${Math.ceil(characterCount / 153)}`}
                                height={110}
                                resize='vertical'
                                defaultValue={this.tableData.Message}
                                onChange={(event) => {
                                    this.updatedTableData.Message = event.target.value;
                                }}
                                {...textAreaProps}
                              />}
                          </InputWrapper>
                          <Row padding='S100' gridGap='S400' justifyContent='center'>
                              <IconWithTooltip
                                title='Show tooltip'
                                tooltipText='Click to open the Preview SMS Pop-Up to "Send" and "Test" the above Message text to your cellular devices.'
                                color='primary60'
                              />
                              <Popover triggerText='Preview SMS'
                                       shouldFocusOnOpen={true}
                                       maxWidth='32vw'
                                       shouldCloseOnFocusLeave={false}
                                       position={PopoverPosition.BottomCenter}>
                                  {({ close }) => (
                                    <Col gridGap='S200' padding='S300' flexWrap='wrap'>
                                        <P>
                                            Enter the phone number below in the area provided
                                            and click 'Send SMS' to send the SMS Text.
                                        </P>
                                        {
                                            this.state.loading &&
                                            <StencilProvider theme={{
                                                ...HR2021Theme
                                            }}>
                                              <Col gridGap={30} aria-live='assertive' aria-busy={this.state.loading}>
                                                <Spinner loadingText='Dispatching SMS...' size={40} strokeWidth={5}
                                                         showText textColor='neutral70' aria-live='assertive'
                                                         aria-busy={this.state.loading} />
                                              </Col>
                                              <br />
                                            </StencilProvider>
                                        }
                                        <FormWrapper padding='S300' maxWidth={450} width={450}>
                                            {
                                                this.state.isPostSuccessSMS &&
                                                <MessageBanner iconAltText='Successful'
                                                               type={MessageBannerType.Success}
                                                               isDismissible
                                                               onDismissed={() => {
                                                                   this.setState({ isPostSuccessSMS: false });
                                                               }}>
                                                  The SMS is sent successfully.
                                                </MessageBanner>
                                            }
                                            {
                                                this.state.isPostErrorSMS !== '' &&
                                                <MessageBanner iconAltText='Failure'
                                                               type={MessageBannerType.Error}
                                                               isDismissible
                                                               onDismissed={() => this.setState({ isPostErrorSMS: '' })}>
                                                  There seems to be an issue - {this.state.isPostErrorSMS}
                                                </MessageBanner>
                                            }
                                            <PhoneInput
                                              inputProps={{
                                                  name: 'phone',
                                                  required: true,
                                                  autoFocus: true
                                              }}
                                              preferredCountries={['us']}
                                              country={'us'}
                                              placeholder='Enter phone number'
                                              value={this.state.phone}
                                              onChange={(value: any) => this.setState({ phone: value })}
                                            />
                                        </FormWrapper>
                                        <Row gridGap='S300' justifyContent='flex-end'>
                                            <Button
                                              size={ButtonSize.Small}
                                              type='submit'
                                              onClick={(event) => {
                                                  this.handleSubmitSMS(event, this.updatedTableData.Message, this.state.phone);
                                              }}>
                                                Send SMS
                                            </Button>
                                            <Button variant={ButtonVariant.Tertiary} size={ButtonSize.Small}
                                                    isDestructive onClick={close}
                                                    icon={<IconCross aria-hidden={true} size={IconSize.ExtraSmall} />}>
                                                Close Popup
                                            </Button>
                                        </Row>
                                    </Col>
                                  )}
                              </Popover>
                          </Row>

                          <InputWrapper id='text-preview-2'
                                        labelText='Comments: Why the changes were made?'
                                        footerText='Ex: Changed Class M option 1 SMS to reflect new time-off regulations that went into place on 4/5.'>
                              {(textAreaProps) => <TextAreaWithRecommendedLength
                                resize='vertical'
                                recommendedWordCount={30}
                                defaultValue={this.tableData.comment}
                                onChange={(event) => {
                                    this.updatedTableData.comment = event.target.value;
                                }}
                                {...textAreaProps} />}
                          </InputWrapper>

                      </FormWrapper>
                  </ModalContent>
              </Modal>
          </>
        );
    }

    downloadText = (length: number) => `Click here Export the ${length} rows to a CSV file.`;

    getRowAttributes = () => (
      {
          onClick: ({ index }: any) => {
              this.setState({ isOpen: true });

              this.tableData.BusinessGroup = this.state.fetchedDataForTable[index].BusinessGroup;
              this.updatedTableData.BusinessGroup = this.tableData.BusinessGroup;
              this.isDeleteEnabledForBG = isDeleteEnabledForBG(this.state.fetchedDataForDropdown, this.tableData.BusinessGroup);

              this.tableData.Message = this.state.fetchedDataForTable[index].Message;
              this.updatedTableData.Message = this.tableData.Message;

              this.tableData.TemplateName = this.state.fetchedDataForTable[index].TemplateName;
              this.updatedTableData.TemplateName = this.tableData.TemplateName;
          }
      }
    );

    render() {

        if (!authService.isUserAuthenticated() && this.federateToken === null)
            return <SignedOut allowance={true} />;

        if (this.state.readyToRedirect) {
            return <SignedOut allowance={true} />;
        }

        if (!containsLdapElement(this.federateToken.LDAPGROUP, LDAP[5].toString())) {
            return <Forbidden federateToken={this.federateToken} allowance={true} />;
        } else {
            return <StencilProvider theme={{
                ...HR2021Theme
            }}>
                <View height={150}>
                    <CustomHeader federateToken={this.federateToken} />
                    <Col margin={{ left: 155 }}>
                        {
                            authService.isTokenPresent() &&
                            <div className='link-underline'>
                              <Avatar
                                imageAltText='badge image'
                                fullName={this.federateToken.FAMILY_NAME + ', ' + this.federateToken.GIVEN_NAME}
                                username={this.federateToken.sub}
                                usernameClickable={true}
                                margin={25}>
                              </Avatar>
                            </div>
                        }
                    </Col>
                </View>

                <PageContainer
                  backgroundColor='primary05'
                  alignItems='center'
                  centered={true}
                  paddingBottom='S700'
                  paddingTop='S600'>
                    {
                        this.state.isPostSuccess &&
                        <MessageBanner iconAltText='Successful' type={MessageBannerType.Success}
                                       title='Operation successful'
                                       isDismissible onDismissed={() => {
                            this.setState({ isPostSuccess: false });
                        }}>
                          The operation on {this.businessGroup} [ {this.templateName} ] entry is successful.
                        </MessageBanner>
                    }
                    {
                        this.state.isPostError !== '' &&
                        <MessageBanner iconAltText='Failure' type={MessageBannerType.Error}
                                       title='Operation failed' isDismissible
                                       onDismissed={() => this.setState({ isPostError: '' })}>
                          There seems to be an issue with the Operation - {this.state.isPostError}
                        </MessageBanner>
                    }
                    <br />
                    <MainWithSkipLink>
                        <Col gridGap='S500' padding='S300'>
                            <H1 id='heading' textAlign='center'> Manual Text Self-Service </H1>
                            <Col alignItems='center'>
                                <TabBar
                                  aria-label='Tabs here for Main Content and Change log'
                                  layout={TabsLayout.Default}
                                  onTabSelect={(tabValue) => {
                                      this.setState({ tabValue: tabValue });
                                      if (tabValue === tabs[1]) {
                                          this.onResetFilterAuditTrails();
                                          this.setState({
                                              selectedPage2: 1,
                                              isExpanded: false,
                                              startDate: '',
                                              endDate: ''
                                          });
                                          this.getTableDataForChangelog(true, false, 1, [], this.state.startDate, this.state.endDate);

                                      }
                                  }}
                                  selectedTab={this.state.tabValue}
                                  tabGroup='TabGroup-1'
                                  tabs={tabs}
                                />
                            </Col>

                            <TabSwitcher
                              selectedTab={this.state.tabValue}
                              tabGroup='TabGroup-1'>

                                <TabPanel value='Main Content'>
                                    <Col
                                      backgroundColor='primary05'
                                      alignItems='center'
                                      justifyContent='center'>
                                        {
                                            this.isAddVisible &&
                                            <View alignItems='center' backgroundColor='neutral0' padding='S300'
                                                  width='60vw'>
                                              <Col gridGap='S500' alignItems='center' padding='S400'>
                                                <Text fontSize='T400' lineHeight={4} textAlign='center'
                                                      id='smsType-a-selector-label'>
                                                  Add Messages
                                                  <span hidden
                                                        id='sroDialog'> The button has a pop up dialog </span>
                                                </Text>
                                                <Button
                                                  aria-describedby='smsType-a-selector-label'
                                                  type='button'
                                                  variant={ButtonVariant.Primary}
                                                  onClick={() => this.setState({ isOpenAdd: true })}
                                                  icon={<IconChevronRight aria-hidden={true} />}
                                                  iconPosition={ButtonIconPosition.Trailing}
                                                  key='Open'>
                                                  Add Entry
                                                  &nbsp;
                                                </Button>
                                              </Col>
                                                {this.getModalForAdd()}
                                              <br />
                                            </View>
                                        }
                                        {
                                            this.state.loading &&
                                            <StencilProvider theme={{
                                                ...HR2021Theme
                                            }}>
                                              <br />
                                              <Col gridGap={35} aria-live='assertive' aria-busy={this.state.loading}>
                                                <Spinner size={70} strokeWidth={9} showText textColor='neutral100'
                                                         aria-live='assertive' aria-busy={this.state.loading} />
                                              </Col>
                                              <br />
                                            </StencilProvider>
                                        }
                                    </Col>
                                    <br />
                                    {
                                        (this.isDeleteVisible) ? (
                                          <Text fontSize='T400' lineHeight={4} textAlign='center'
                                                id='smsType-ud-selector-label'>
                                              Update or Delete Messages
                                          </Text>
                                        ) : (
                                          <Text fontSize='T400' lineHeight={4} textAlign='center'
                                                id='smsType-ud-selector-label'>
                                              Edit Messages
                                          </Text>
                                        )
                                    }

                                    <Row gridGap='S500' justifyContent='flex-start' flexWrap='wrap'
                                         aria-describedby='smsType-ud-selector-label'>
                                        <Text id='smsType-cl-selector-label'>
                                            Choose Business group to filter:
                                        </Text>

                                        <Select placeholder='Choose Business group from Dropdown'
                                                isSearchable={true}
                                                aria-label='Choose Business group from Dropdown'
                                                searchFieldAriaLabel='Choose Business group from Dropdown'
                                                aria-describedby='smsType-cl-selector-label'
                                                isMulti={true}
                                                width={'50vw'}
                                                options={this.businessGroupList}
                                                value={this.state.filteredValue}
                                                onChange={filteredValue => {
                                                    this.setState({ filteredValue: filteredValue });
                                                }} />
                                        <Row gridGap='S500' justifyContent='flex-start' flexWrap='wrap'>
                                            <Button
                                              variant={ButtonVariant.Tertiary}
                                              aria-describedby='smsType-cl-selector-label'
                                              size={ButtonSize.Small}
                                              onClick={() => {
                                                  this.filters1 = this.state.filteredValue;
                                                  this.getTableData(true, true, 1, this.filters1);
                                              }}>
                                                Apply Filter
                                            </Button>
                                        </Row>
                                    </Row>
                                    <br />
                                    <br />
                                    <Row gridGap='S500' display='flex' alignItems='flex-end' justifyContent='flex-end'
                                         padding='S300'>
                                        <Text fontWeight='bold' textAlign='right' fontSize='T200'>Total no. of
                                            Rows: {this.totalCount1}</Text>
                                        {
                                            this.state.fetchedDataForTable.length !== 0 &&
                                            <Pagination
                                              aria-label='pagination'
                                              numberOfPages={Math.ceil(this.totalCount1 / 10)}
                                              selectedPage={this.state.selectedPage1}
                                              onPageSelect={(value) => {
                                                  this.setState({ selectedPage1: value });
                                                  this.getTableData(true, true, value, this.filters1);
                                              }}
                                            />
                                        }
                                    </Row>
                                    <br />
                                    <Col
                                      backgroundColor='primary05'
                                      alignItems='center'
                                      justifyContent='center'>
                                        {this.getModalForEditDelete()}
                                        <br />
                                    </Col>
                                    <br />

                                </TabPanel>

                                <TabPanel value='Audit Trails'>
                                    <Expander
                                      isExpanded={this.state.isExpanded}
                                      onToggle={() => this.setState({ isExpanded: !this.state.isExpanded })}
                                      titleText='Click to open Filters'>
                                        <View backgroundColor='neutral0' alignItems='flex-start'
                                              padding='S300' flexWrap='wrap'>
                                            <Row gridGap='S700' justifyContent='flex-start' flexWrap='wrap'
                                                 padding='S300'>
                                                <Col gridGap='S300' alignItems='flex-start' padding='S300'>
                                                    <Text id='smsType-cl1-selector-label'>
                                                        Choose Business Group to filter:
                                                    </Text>

                                                    <Select placeholder='Choose Business group for change log'
                                                            isSearchable={true}
                                                            aria-label='Choose Business group from Dropdown'
                                                            searchFieldAriaLabel='Choose Business group from Dropdown'
                                                            aria-describedby='smsType-cl-selector-label'
                                                            isMulti={true}
                                                            width='55vw'
                                                            options={this.businessGroupListForAuditTrails}
                                                            value={this.state.filteredValueChangelog}
                                                            onChange={value => {
                                                                this.valueOfAT = value;
                                                                this.setState({ filteredValueChangelog: value });
                                                            }} />
                                                </Col>
                                                <Col gridGap='S300' alignItems='flex-start' padding='S300'>
                                                    <Text id='smsType-cl3-selector-label'>
                                                        Choose Date Range to filter:
                                                    </Text>

                                                    <View width='55vw' justifyContent='flex-start'>
                                                        <PopupDateRangePicker
                                                          ariaLabelsForSelectedDate={{
                                                              endDate: 'You have selected an end date',
                                                              startDate: 'You have selected a start date'
                                                          }}
                                                          id='filter-date-picker'
                                                          name='choose-dates'
                                                          shouldFocusOnStartDate={true}
                                                          locale='iso8601'
                                                          startDateLabelText='Start date (YYYY-MM-DD)'
                                                          endDateLabelText='End date (YYYY-MM-DD)'
                                                          startValue={this.state.startDate as unknown as string}
                                                          endValue={this.state.endDate as unknown as string}
                                                          onChange={(startDate, endDate) => {
                                                              if (startDate)
                                                                  this.setState({ startDate: startDate + ' 00:00:00' });
                                                              if (endDate)
                                                                  this.setState({ endDate: endDate + ' 23:59:59' });
                                                          }}
                                                        />
                                                    </View>
                                                </Col>
                                            </Row>

                                            <Row gridGap='S700' padding='S600' justifyContent='center' flexWrap='wrap'>
                                                <Button
                                                  variant={ButtonVariant.Primary}
                                                  type='button'
                                                  aria-describedby='smsType-cl1-selector-label'
                                                  icon={<IconArrowRight title='Apply Filter' aria-hidden={true} />}
                                                  iconPosition={ButtonIconPosition.Trailing}
                                                  onClick={() => {
                                                      this.filters2 = this.valueOfAT;
                                                      this.filters2 = this.state.filteredValueChangelog;
                                                      this.getTableDataForChangelog(true, true, 1, this.filters2, this.state.startDate, this.state.endDate);
                                                      this.setState({ selectedPage2: 1 });
                                                  }}>
                                                    Apply Filter
                                                    &nbsp;
                                                </Button>
                                                <Button
                                                  variant={ButtonVariant.Primary}
                                                  type='button'
                                                  icon={<IconReset title='Reset Filter' aria-hidden={true} />}
                                                  iconPosition={ButtonIconPosition.Trailing}
                                                  onClick={() => {
                                                      this.onResetFilterAuditTrails();
                                                      this.setState({
                                                          selectedPage2: 1,
                                                          startDate: '',
                                                          endDate: ''
                                                      });
                                                      this.getTableDataForChangelog(true, false, 1, [], this.state.startDate, this.state.endDate);
                                                  }}>
                                                    Reset Filters
                                                    &nbsp;
                                                </Button>
                                            </Row>
                                        </View>
                                    </Expander>
                                    <br />
                                    <Col
                                      backgroundColor='primary05'
                                      alignItems='center'
                                      justifyContent='center'>
                                        {
                                            this.state.loading &&
                                            <StencilProvider theme={{
                                                ...HR2021Theme
                                            }}>
                                              <br />
                                              <Col gridGap={35} aria-live='assertive' aria-busy={this.state.loading}>
                                                <Spinner size={70} strokeWidth={9} showText textColor='neutral100'
                                                         aria-live='assertive' aria-busy={this.state.loading} />
                                              </Col>
                                              <br />
                                            </StencilProvider>
                                        }
                                        <br />
                                    </Col>
                                    <Row gridGap='S500' display='flex' alignItems='flex-end' justifyContent='flex-end'
                                         padding='S300'>
                                        <Text margin={{ bottom: 7 }} fontWeight='bold' textAlign='right' fontSize='T200'>Total no. of
                                            Rows: {this.totalCount2}</Text>
                                        {
                                            this.state.fetchedChangelogData.length !== 0 &&
                                            <ButtonWithTooltip
                                              role='tooltip'
                                              aria-describedby='Click here to Export the changelogs to CSV.'
                                              icon={<IconDownload title='Export to CSV' />}
                                              title={this.downloadText(this.totalCount2)}
                                              variant={ButtonVariant.Secondary}
                                              onClick={() => {
                                                  this.getChangelogCSV(true, this.filters2, this.state.startDate, this.state.endDate);
                                              }}
                                              tooltipAlignment={TooltipAlignment.Left}
                                              tooltipPosition={TOOLTIP_POSITION.BOTTOM}
                                              size={ButtonSize.Small}
                                              tooltipText={this.downloadText(this.totalCount2)}
                                            >
                                              Download Changelog
                                            </ButtonWithTooltip>
                                        }
                                        <Spacer flex={0.20} />
                                        {
                                            this.state.fetchedChangelogData.length !== 0 &&
                                            <Pagination
                                              aria-label='pagination'
                                              numberOfPages={Math.ceil(this.totalCount2 / 10)}
                                              selectedPage={this.state.selectedPage2}
                                              onPageSelect={(value) => {
                                                  this.setState({ selectedPage2: value });
                                                  this.getTableDataForChangelog(true, true, value, this.filters2, this.state.startDate, this.state.endDate);
                                              }}
                                            />
                                        }
                                    </Row>
                                    <br />
                                    <div style={cssPropertiesTable}>
                                        <Table
                                          aria-labelledby='smsType-cl1-selector-label'
                                          actionHeader={'Expand'}
                                          getRowAttributes={getRowAttributesCL}
                                          columns={getColumnsChangelogMSS}
                                          data={this.state.fetchedChangelogData}
                                          spacing={TableSpacing.Default}
                                          shouldAutoCollapseOnExpand={true}
                                          shouldScrollHorizontally={true} />
                                    </div>
                                    <br />
                                </TabPanel>
                            </TabSwitcher>
                        </Col>
                    </MainWithSkipLink>
                    <br />
                    <CustomBackToTop />
                </PageContainer>
                <CustomFooter />
            </StencilProvider>;
        }
    }
}
