import React from "react";
import { StencilProvider } from "@amzn/stencil-react-components/context";
import { HR2021Theme } from "@amzn/stencil-react-theme-default";
import { Col } from "@amzn/stencil-react-components/layout";
import { Spinner } from "@amzn/stencil-react-components/spinner";

interface Props {
  loadingState: boolean;
}

const LoadingSpinner: React.FC<Props> = (props) => {
  return (
    <Col gridGap={32} alignItems="center">
      {
        <StencilProvider
          theme={{
            ...HR2021Theme,
          }}
        >
          <Col
            gridGap={35}
            aria-live="assertive"
            aria-busy={props.loadingState}
          >
            <Spinner
              size={70}
              strokeWidth={9}
              showText
              textColor="neutral100"
              aria-live="assertive"
              aria-busy={props.loadingState}
            />
          </Col>
        </StencilProvider>
      }
    </Col>
  );
};

export default LoadingSpinner;
